import React, { Component } from 'react';
import oblax from '../../../../assets/oblax';

import { DropDown, ButtonDropDown } from "../../../../parts";
import { ProductTags } from "../Parts";
import { mapUnits, mapAlergens, mapPackaging, mapTimeMeasurements } from "../../../../assets/helper";

class ProductPersonalHygieneMeta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            personal_hygiene_allergens: [],
            personal_hygiene_ingredients: [],
            personal_hygiene_expiration: {},
            personal_hygiene_measurement: {},
            cosmetics_gross_weight_measurement: {},
            personal_hygiene_packaging: '',

            personal_hygiene_unit: [],
            personal_hygiene_allergens_list: [],
            personal_hygiene_packaging_list: [],
            personal_hygiene_time_list: [],

            deleteAlert: false,
            removeType: ''
        }

        // Get data from api 
        this.getMeasurements = this.getMeasurements.bind(this);

        // Personal Hygiene Allergens
        this.handleAllergenClose = this.handleAllergenClose.bind(this);
        this.handleAllergenInputChange = this.handleAllergenInputChange.bind(this);

        // Personal Hygiene Expiration
        this.setExpiration = this.setExpiration.bind(this);
        this.setExpirationTime = this.setExpirationTime.bind(this);

        // Personal Hygiene Ingredients
        this.editIngredients = this.editIngredients.bind(this);

        // Personal Hygiene Measurement
        this.setMeasurementUnit = this.setMeasurementUnit.bind(this);
        this.setMeasurementValue = this.setMeasurementValue.bind(this);
        this.setGrossWeightMeasurementUnit = this.setGrossWeightMeasurementUnit.bind(this);
		this.setGrossWeightMeasurementValue = this.setGrossWeightMeasurementValue.bind(this);

        // Personal Hygiene Packaging
        this.setPackaging = this.setPackaging.bind(this);
    }

    componentDidMount() {
        this.getMeasurements();
        this.getProductAlergens();
        this.getProductPackaging();
        this.getTimeMeasurements();

        if (!this.isEmpty(this.props.meta)) {
            this.setState({
                personal_hygiene_allergens: this.props.meta.personal_hygiene_allergens !== undefined ? this.props.meta.personal_hygiene_allergens : [],
                personal_hygiene_expiration: this.props.meta.personal_hygiene_expiration !== undefined ? this.props.meta.personal_hygiene_expiration : {},
                personal_hygiene_ingredients: this.props.meta.personal_hygiene_ingredients !== undefined ? this.props.meta.personal_hygiene_ingredients : [],
                personal_hygiene_measurement: this.props.meta.personal_hygiene_measurement !== undefined ? this.props.meta.personal_hygiene_measurement : {},
                personal_hygiene_gross_weight_measurement: this.props.meta.personal_hygiene_gross_weight_measurement !== undefined ? this.props.meta.personal_hygiene_gross_weight_measurement : {},
                personal_hygiene_packaging: this.props.meta.personal_hygiene_packaging,
            })
        } else {
            this.props.setMetaData('personal_hygiene_allergens', []);
            this.props.setMetaData('personal_hygiene_expiration', {});
            this.props.setMetaData('personal_hygiene_ingredients', []);
            this.props.setMetaData('personal_hygiene_measurement', {});
            this.props.setMetaData('personal_hygiene_gross_weight_measurement', {});
            this.props.setMetaData('personal_hygiene_packaging', "");
        }
    }


    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    // Get data from api 
    // ==========================
    getMeasurements() {
        return oblax.lists.getMeasurements()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        personal_hygiene_unit: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("MEASUREMENTS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("MEASUREMENTS ERROR", err);
                }
            });
    }

    getProductAlergens() {
        return oblax.lists.getProductAlergens()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        personal_hygiene_allergens_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("ALERGENS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("ALERGENS ERROR", err);
                }
            });
    }

    getProductPackaging() {
        return oblax.lists.getProductPackaging()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        personal_hygiene_packaging_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("PACKAGING ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("PACKAGING ERROR", err);
                }
            });
    }

    getTimeMeasurements() {
        return oblax.lists.getTimeMeasurements()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        personal_hygiene_time_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("TIME MEASUREMENTS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("TIME MEASUREMENTS ERROR", err);
                }
            });
    }

    // Personal Hygiene Allergens
    // ==========================
    handleAllergenClose(removedAllergen) {
        const personal_hygiene_allergens = this.state.personal_hygiene_allergens.filter(item => item !== removedAllergen);
        this.setState({
            personal_hygiene_allergens
        }, () => {
            this.props.setMetaData('personal_hygiene_allergens', personal_hygiene_allergens);
        });
    }

    handleAllergenInputChange(value) {
        let personal_hygiene_allergens = this.state.personal_hygiene_allergens;
        if (value && personal_hygiene_allergens.indexOf(value) === -1) {
            personal_hygiene_allergens = [...personal_hygiene_allergens, value];
        }
        this.setState({
            personal_hygiene_allergens
        }, () => {
            this.props.setMetaData('personal_hygiene_allergens', personal_hygiene_allergens);
        });
    }
    // ==========================

    // Personal Hygiene Expiration
    // ==========================
    setExpiration(value) {
        this.setState(prevState => ({
            personal_hygiene_expiration: {
                ...prevState.personal_hygiene_expiration,
                expiration_measurement: value
            }
        }), () => {
            this.props.setMetaData('personal_hygiene_expiration', this.state.personal_hygiene_expiration);
        })
    }

    setExpirationTime({ target }) {
        this.setState(prevState => ({
            personal_hygiene_expiration: {
                ...prevState.personal_hygiene_expiration,
                expiration_time: parseInt(target.value)
            }
        }), () => {
            this.props.setMetaData('personal_hygiene_expiration', this.state.personal_hygiene_expiration);
        })
    }
    // ==========================

    // Personal Hygiene Ingredients
    // ==========================
    editIngredients(personal_hygiene_ingredients) {
        this.setState({
            personal_hygiene_ingredients
        }, () => {
            this.props.setMetaData('personal_hygiene_ingredients', this.state.personal_hygiene_ingredients);
        });
    }
    // ==========================

    // Personal Hygiene Measurement
    // ==========================
    setMeasurementUnit(value) {
        this.setState(prevState => ({
            personal_hygiene_measurement: {
                ...prevState.personal_hygiene_measurement,
                unit: value
            }
        }), () => {
            this.props.setMetaData('personal_hygiene_measurement', this.state.personal_hygiene_measurement);
        })
    }

    setGrossWeightMeasurementUnit(value) {
		this.setState(prevState => ({
			personal_hygiene_gross_weight_measurement: {
				...prevState.personal_hygiene_gross_weight_measurement,
				unit: value
			}
		}), () => {
			this.props.setMetaData('personal_hygiene_gross_weight_measurement', this.state.personal_hygiene_gross_weight_measurement);
		})
	}

    setMeasurementValue({ target }) {
        this.setState(prevState => ({
            personal_hygiene_measurement: {
                ...prevState.personal_hygiene_measurement,
                value: parseFloat(target.value)
            }
        }), () => {
            this.props.setMetaData('personal_hygiene_measurement', this.state.personal_hygiene_measurement);
        })
    }

    setGrossWeightMeasurementValue({ target }) {
		this.setState(prevState => ({
			personal_hygiene_gross_weight_measurement: {
				...prevState.personal_hygiene_gross_weight_measurement,
				value: target.value === "" ? "" : parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('personal_hygiene_gross_weight_measurement', this.state.personal_hygiene_gross_weight_measurement);
		})
	}
    // ==========================

    // Personal Hygiene Packaging
    // ==========================
    setPackaging(value) {
        this.setState({
            personal_hygiene_packaging: value
        }, () => {
            this.props.setMetaData('personal_hygiene_packaging', this.state.personal_hygiene_packaging);
        })
    }
    // ==========================

    render() {
        const {
            personal_hygiene_allergens,
            personal_hygiene_ingredients,
            personal_hygiene_packaging_list,
            personal_hygiene_unit,
            personal_hygiene_time_list
        } = this.state;

        return (
            <div className="content-block-main" id="product-food-metadata">
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Personal Hygiene Expiration</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Time Measurment"
                            mapFunction={mapTimeMeasurements}
                            list={personal_hygiene_time_list}
                            handleSelectChange={this.setExpiration}
                            value={mapTimeMeasurements(this.props.meta !== null ? !this.isEmpty(this.props.meta.personal_hygiene_expiration) ? this.props.meta.personal_hygiene_expiration.expiration_measurement : "" : "")}
                            errorMessage="Time measurment is required"
                            error={this.props.errors.includes("personal_hygiene_expiration")}
                            className=""
                        />
                        <p className={"input-holder half " + (this.props.errors.includes("personal_hygiene_expiration") ? "error-active" : "")}>
                            <input
                                className="input-field--labeled"
                                id="food-expiration-time"
                                min="1"
                                type="number"
                                name="expiration_time"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.personal_hygiene_expiration.expiration_time : 0}
                                onChange={this.setExpirationTime}
                                required
                            />
                            <label className="field-label" htmlFor="food-expiration-time">Time Value</label>
                            <span className="input-error">Time value is required</span>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Personal Hygiene Measurment</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Unit"
                            mapFunction={mapUnits}
                            list={personal_hygiene_unit}
                            handleSelectChange={this.setMeasurementUnit}
                            value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.personal_hygiene_measurement) ? this.props.meta.personal_hygiene_measurement.unit : "" : "")}
                            className=""
                        />
                        <p className="input-holder half">
                            <input
                                className="input-field--labeled"
                                id="food-measurement"
                                min="1"
                                type="number"
                                name="food_measurement"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.personal_hygiene_measurement.value : 0}
                                onChange={this.setMeasurementValue}
                                required
                            />
                            <label className="field-label" htmlFor="food-measurement">Value</label>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Personal Hygiene Gross Weight Measurment</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Unit"
                            mapFunction={mapUnits}
                            list={personal_hygiene_unit}
                            handleSelectChange={this.setGrossWeightMeasurementUnit}
                            value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.personal_hygiene_gross_weight_measurement) ? this.props.meta.personal_hygiene_gross_weight_measurement.unit : "" : "")}
                            className=""
                        />
                        <p className="input-holder half">
                            <input
                                className="input-field--labeled"
                                id="personal_hygiene_gross_weight_measurement"
                                min="1"
                                type="number"
                                name="personal_hygiene_gross_weight_measurement"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.personal_hygiene_gross_weight_measurement.value : 0}
                                onChange={this.setGrossWeightMeasurementValue}
                                required
                            />
                            <label className="field-label" htmlFor="personal_hygiene_gross_weight_measurement">Value</label>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled one select">
                    <h2 className="input-block-title">Personal Hygiene Alergens</h2>

                    <div className="input-grid">
                        <p className="rounded-data-holder tags color orange">
                            <span className="data-title">Alergens</span>
                            <span className="data-line">
                                {personal_hygiene_allergens.map(allergen =>
                                    <span className="single-data-item" key={allergen}>{mapAlergens(allergen.toUpperCase())}<button className="remove-button" onClick={() => this.handleAllergenClose(allergen)}></button></span>
                                )}
                                <ButtonDropDown
                                    handler=""
                                    value=""
                                    mapFunction={mapAlergens}
                                    title="Add new alergen"
                                    list={this.state.personal_hygiene_allergens_list}
                                    handleSelectChange={(allergen) => this.handleAllergenInputChange(allergen)}
                                    disabled={true}
                                />
                            </span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled one select">
                    <h2 className="input-block-title">Personal Hygiene Ingredients</h2>

                    <div className="input-grid">
                        <p className="rounded-data-holder tags color orange">
                            <span className="data-title">Ingredients</span>
                            <ProductTags
                                tags={personal_hygiene_ingredients}
                                editTags={this.editIngredients}
                                title="Add new ingredient"
                            />
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Personal Hygiene Packaging</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Packaging"
                            mapFunction={mapPackaging}
                            list={personal_hygiene_packaging_list}
                            handleSelectChange={this.setPackaging}
                            value={mapPackaging(!this.isEmpty(this.props.meta) ? this.props.meta.personal_hygiene_packaging : "select packaging")}
                            className=""
                        />
                    </div>
                </div>

            </div>
        );
    }
}

export default ProductPersonalHygieneMeta;