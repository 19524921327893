import React, { Component } from 'react';
import { connect } from "react-redux";
import { Tree } from 'antd';
import "antd/dist/antd.css";

import oblax from "../../../assets/oblax";
import {
	Category
} from "@oblax/sdk-js";
import "../css/Categories.css"
import { ArrayIsEmpty, isEmpty } from "../../../assets/helper";
import { Toast, EmptyStateDisclaimer } from "../../../parts";
import { UTILITIES } from "../../../assets/constants";
import { setCategories } from "../../Home/actions"
import { Tabs, TabContent } from '../../Tabs/index';

import { setProductList, setProductsMap } from "../../Products/actions/index";
import placeholder from "../../../assets/images/person-placeholder.png";

const TreeNode = Tree.TreeNode;

class Categories extends Component {
	constructor(props) {
		super(props);

		this.state = {
			categories: [],
			selectedCategory: {
				name: "",
				order: 0,
				parent_id: "",
				permalink: "",
				products: []
			},
			alert: {
				type: '',
				text: '',
				triger: false
			},
			showMetadata: false,
			searchItem: "",
			dropTarget: "",
			dropTargetActivate: false
		}

		this.selectedCategory = this.selectedCategory.bind(this);
		this.getAllCategories = this.getAllCategories.bind(this);
		this.getAllProducts = this.getAllProducts.bind(this);
		this.removeCategory = this.removeCategory.bind(this);
		this.editCategory = this.editCategory.bind(this);
		this.editOnChange = this.editOnChange.bind(this);
		this.CreateBlankRootCategory = this.CreateBlankRootCategory.bind(this);
		this.CreateBlankChildCategory = this.CreateBlankChildCategory.bind(this);
		this.CancelMetadataActions = this.CancelMetadataActions.bind(this);
		this.DisableDrop = this.DisableDrop.bind(this);
		this.checkForChange = this.checkForChange.bind(this);
		this.HandleSearch = this.HandleSearch.bind(this);

		this.onDrop = this.onDrop.bind(this);

		this.saveCategory = this.saveCategory.bind(this);

		// ALERTS
		this.activateAlert = this.activateAlert.bind(this);

		// Drag & Drop
		this.UpdateCategoryProducts = this.UpdateCategoryProducts.bind(this);
		this.AllowDrop = this.AllowDrop.bind(this);
		this.DragProduct = this.DragProduct.bind(this);
		this.DropProduct = this.DropProduct.bind(this);
		this.ArrayMove = this.ArrayMove.bind(this);
		this.StyleDragOver = this.StyleDragOver.bind(this);
		this.StyleDragExit = this.StyleDragExit.bind(this);
		this.FrontBackReorder = this.FrontBackReorder.bind(this);
	}

	componentDidMount() {
		this.getAllCategories();
		this.getAllProducts();
	}

	selectedCategory(data, e) {
		e.stopPropagation();
		e.preventDefault();

		this.setState({
			selectedCategory: data,
			showMetadata: true
		}, () => { console.log("STATE => ", this.state) });
	}

	getAllCategories() {
		return oblax.categories.getAll(true)
			.then((result) => {
				if (result.ok) {
					this.setState({
						categories: result.data,
					}, () => {
						this.props.setCategories(this.state.categories);
					})
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("CATEGORIES ERROR: ", result.status)
					}
					this.activateAlert("error", "There was an error in your attempt. Try again.");
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("CATEGORIES ERROR: ", err)
				}
				this.activateAlert("error", "There was an error in your attempt. Try again.");
			})
	}

	getAllProducts() {
		return oblax.products.getAll(true)
			.then((params) => {
				if (params.ok) {
					let productsMap = {};

					for (let i in params.data) {
						productsMap[params.data[i].id] = parseInt(i);
					}

					this.props.setProductList(params.data);
					this.props.setProductsMap(productsMap);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PRODUCTS_ERROR: ", params.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PRODUCTS_ERROR: ", err);
				}
			});
	}

	// Add Children Categories 
	// =======================

	// removeCategory(type, item, e) {
	removeCategory(item, e) {
		e.preventDefault();
		e.stopPropagation();
		e.persist();

		if (!item.hasOwnProperty('children')) {
			this.deleteCategoryInDB(item.id);
		}
	}

	deleteCategoryInDB(id) {
		return oblax.categories.deleteCategory(id)
			.then((result) => {
				if (result.ok) {
					this.activateAlert("success", "You have successfully deleted a category.");
					this.CancelMetadataActions();
					this.getAllCategories();
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("DELETE CATEGORY ERROR: ", result.status)
					}
					this.activateAlert("error", "There was an error in your attempt. Try again.");
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("DELETE CATEGORY ERROR: ", err)
				}
				this.activateAlert("error", "There was an error in your attempt. Try again.");
			});
	}

	editCategory(item) {
		// updateCategory
		var category = new Category();
		category.loadFromJSON({
			id: item.id,
			parent_id: item.parent_id,
			name: item.name,
			permalink: item.permalink,
			order: item.order
		});

		oblax.categories.updateCategory(item.id, category)
			.then((result) => {
				if (result.ok) {
					this.getAllCategories();
					this.activateAlert("success", "You have successfully edited a category.");
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("UPDATE CATEGORY ERROR: ", result.status);
					}
					// this.activateAlert("error", "There was an error in your attempt. Try again.");
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("UPDATE CATEGORY ERROR: ", err);
				}
				this.activateAlert("error", "There was an error in your attempt. Try again.");
			})
	}


	checkForChange(item) {
		if (item.parent_id === "") {
			for (var i = 0; i < this.state.categories.length; i++) {
				if (this.state.categories[i].id === item.id) {
					console.log("ITEM", item);
					if (JSON.stringify(this.state.categories[i]) !== JSON.stringify(item)) {
						this.editCategory(item);
					}
				}
			}
		} else {
			// for (var i = 0; i < this.state.categories.length; i++) {
			// 	for (var j = 0; j < this.state.categories[i].children.length; j++) {
			// 		if (this.state.categories[i].children[j].id === item.id) {
			// 			if (this.state.categories[i].children[j].name !== item.name || this.state.categories[i].children[j].permalink !== item.permalink) {
			// 				this.editCategory(item);
			// 			}
			// 		}
			// 	}
			// }
			this.editCategory(item);
		}

	}


	// =======================

	editOnChange({ target }) {
		this.setState(prevState => ({
			selectedCategory: {
				...prevState.selectedCategory,
				[target.name]: target.value
			}
		}))
	}

	// DRAGABLE
	// =======================
	onDrop = (info) => {
		console.log("TCL: onDrop -> info", info)

		// info.dragNode.isDisabled();

		const dropKey = info.node.props.eventKey;
		const dragKey = info.dragNode.props.eventKey;
		const dropPos = info.node.props.pos.split('-');
		const dragPos = info.dragNode.props.pos.split('-');
		const dropPosition = info.dropPosition - Number(dropPos[dropPos.length - 1]);

		const loop = (data, key, callback) => {
			data.forEach((item, index, arr) => {
				if (item.id === key) {
					return callback(item, index, arr);
				}
				if (item.children) {
					return loop(item.children, key, callback);
				}
			});
		};
		const data = [...this.state.categories];

		// Find dragObject
		let dragObj;
		if (dropPosition !== 0) {
			if (dragPos.length === dropPos.length) {
				console.log("TCL: onDrop -> dragPos.length", dragPos.length)
				if (dragPos.length === 3 && dropPos.length === 3 && dropKey !== "new") {
					if (dragPos[1] === dropPos[1]) {
						loop(data, dragKey, (item, index, arr) => {
							arr.splice(index, 1);
							dragObj = item;
						});

						let ar;
						let i;
						loop(data, dropKey, (item, index, arr) => {
							ar = arr;
							i = index;
						});
						if (dropPosition === -1) {
							ar.splice(i, 0, dragObj);
							this.updateOnDropCategory("child", this.state.categories.filter(item => item.id === dragObj.parent_id));
						} else if (dropPosition === 1) {
							ar.splice(i + 1, 0, dragObj);
							this.updateOnDropCategory("child", this.state.categories.filter(item => item.id === dragObj.parent_id));
						}
					}
				}

				if (dragPos.length === 2 && dropPos.length === 2 && dropKey !== "new") {
					loop(data, dragKey, (item, index, arr) => {
						arr.splice(index, 1);
						dragObj = item;
					});

					let ar;
					let i;
					loop(data, dropKey, (item, index, arr) => {
						ar = arr;
						i = index;
					});
					if (dropPosition === -1) {
						ar.splice(i, 0, dragObj);
						this.updateOnDropCategory("parent", data);
					} else if (dropPosition === 1) {
						ar.splice(i + 1, 0, dragObj);
						this.updateOnDropCategory("parent", data);
					}
				}

			}

			this.setState({
				categories: data,
			});
		}
	}

	updateOnDropCategory(type, data) {
		console.log(data);
		if (type === "child") {
			var d = data[0];
			for (var i = 0; i < d.children.length; i++) {
				var item = {
					id: d.children[i].id,
					name: d.children[i].name,
					order: i + 1,
					parent_id: d.children[i].parent_id,
					permalink: d.children[i].permalink,
				}
				// console.log(item);
				this.editCategory(item);
			}
		} else {
			for (var j = 0; j < data.length; j++) {
				var item2 = {
					id: data[j].id,
					name: data[j].name,
					order: j + 1,
					parent_id: data[j].parent_id,
					permalink: data[j].permalink,
				}
				// console.log(item);
				this.editCategory(item2);
			}
		}
	}

	// =============================

	CancelMetadataActions = () => {
		this.setState({
			showMetadata: false
		})
	}

	newChildCategory = (id, e) => {

		if (e !== undefined) {
			e.stopPropagation();
		}

		this.setState({
			selectedCategory: {
				name: "",
				order: 0,
				parent_id: id,
				permalink: ""
			},
			showMetadata: true
		}, () => {
			console.log(this.state.selectedCategory);
		})
	}

	saveCategory() {
		const state = this.state;

		if (state.selectedCategory.parent_id !== '') {

			const category_main = state.categories.filter(item => item.id === state.selectedCategory.parent_id);
			if (category_main.length !== 0) {
				if (category_main[0].children !== undefined) {
					var category_order = category_main[0].children.length + 1;
				} else {
					var category_order = 1;
				}
			} else {
				var category_order = 1;
			}
			var parent_id = state.selectedCategory.parent_id;
		} else {
			var category_order = state.categories.length + 1;
			var parent_id = null;
		}

		var category = new Category();
		category.loadFromJSON({
			parent_id: parent_id,
			name: state.selectedCategory.name,
			permalink: state.selectedCategory.permalink,
			order: category_order
		});

		oblax.categories.insertCategory(category)
			.then((result) => {
				if (result.ok) {
					this.setState({
						selectedCategory: result.data,
						showMetadata: true
					})
					this.getAllCategories();
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("INSERT CATEGORY ERROR: ", result.status)
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("INSERT CATEGORY ERROR: ", err)
				}
			});
	}

	activateAlert(type, text) {
		this.setState({
			alert: {
				type: type,
				text: text,
				triger: true
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					alert: {
						type: "",
						text: "",
						triger: false
					}
				})
			}, UTILITIES.alertTime);
		});
	}

	CreateBlankRootCategory() {
		this.setState({
			selectedCategory: {
				name: "New Root Category " + (this.state.categories.length + 1),
				order: this.state.categories.length + 1,
				parent_id: "",
				permalink: "new-root-category " + (this.state.categories.length + 1)
			},
			showMetadata: false
		}, () => {
			this.saveCategory();
		})
	}

	CreateBlankChildCategory(id, e) {
		e.stopPropagation();

		const a = this.state.categories.filter(c => c.id === id).length
		if (a !== 0) {
			var d = this.state.categories.filter(c => c.id === id)[0].children
			if (d !== undefined) {
				var order = this.state.categories.filter(c => c.id === id)[0].children.length + 1;
			} else {
				var order = 1;
			}
		} else {
			var order = 1;
		}
		this.setState({
			selectedCategory: {
				name: "New Child Category " + (order),
				order: order,
				parent_id: id,
				permalink: "new-child-category " + (order)
			},
			showMetadata: true
		}, () => {
			console.log("SELECTED CATEGORY", this.state.selectedCategory);
			this.saveCategory();
		})
	}

	DisableDrop(e) {
		const node = e.node;
		// console.log("DROP EVENT", e.event);
		// console.log("DROP NODE", e.node);
		// e.node.onExpand(node.props.eventKey, {expanded: false, node});
	}

	HandleSearch = e => {
		this.setState({
			searchItem: e.target.value
		})
	}

	// DRAG AND DROP REORDER FUNCTIONALITY

	UpdateCategoryProducts(categoryId, products) {
		oblax.categories.updateCategoryProducts(categoryId, products)
			.then((res) => {
				if (res.ok) {
					this.setState(state => {
						return {
							dropTarget: "",
							...state.selectedCategory,
							products: this.state.selectedCategory.products
						}
					});
					this.activateAlert("success", "You successfully changed the order of your Item.");
				}
			})
			.catch((err) => {
				this.setState({
					dropTarget: ""
				});
				if (err.statusText !== "Not Found") {
					this.activateAlert("error", "There was an error in changing the order of your Item. Try again.");
				}
				console.log("TCL: Categories -> DropProduct -> err", err);
			})
	}

	// [On Drag Over]
	AllowDrop(e) {
		e.preventDefault();
	}

	DragProduct(e, product) {
		e.dataTransfer.setData("text", product);
	}

	DropProduct(e) {
		e.preventDefault();
		var data = e.dataTransfer.getData("text");
		let from = this.state.selectedCategory.products.indexOf(data);
		let to = this.state.selectedCategory.products.indexOf(e.target.id);

		this.ArrayMove(this.state.selectedCategory.products, from, to);
		this.UpdateCategoryProducts(this.state.selectedCategory.id, this.state.selectedCategory.products);
	}

	StyleDragOver(e) {
		this.setState({
			dropTarget: e.target.id
		});
	}

	StyleDragExit() {
		this.setState({
			dropTarget: ""
		});
	}

	ArrayMove(arr, fromIndex, toIndex) {
		let el = arr[fromIndex];
		arr.splice(fromIndex, 1);
		arr.splice(toIndex, 0, el);

		return arr;
	}

	FrontBackReorder(dir, product) {
		let from = this.state.selectedCategory.products.indexOf(product);

		if (dir === "front") {
			this.ArrayMove(this.state.selectedCategory.products, from, 0)
			this.UpdateCategoryProducts(this.state.selectedCategory.id, this.state.selectedCategory.products);
		} else if (dir === "back") {
			this.ArrayMove(this.state.selectedCategory.products, from, this.state.selectedCategory.products.length - 1)
			this.UpdateCategoryProducts(this.state.selectedCategory.id, this.state.selectedCategory.products);
		}
	}

	render() {
		const CreateNewCategory = () => {
			return (
				<div className="create-new-category">
					<button
						className="rounded-button primary primary-icon gradient orange new-product-button"
						onClick={this.CreateBlankRootCategory}
					>
						<span className="button-text">Add root category</span>
					</button>
				</div>
			)
		}

		const loop = data => data.sort((a, b) => { return a.order - b.order }).map((item) => {
			if (item.children && item.children.length) {
				return <TreeNode key={item.key} title={
					<span onClick={(event) => this.selectedCategory(item, event)} className="main-category">
						<h2>{item.name}</h2>
						<span className="category-options">
							<button onClick={(event) => this.CreateBlankChildCategory(item.id, event)} className="icon-button new"></button>
							<button onClick={(event) => this.selectedCategory(item, event)} className="icon-button edit"></button>
							{/* <button onClick={(event) => this.removeCategory('parent', item, event)} className="icon-button delete"></button> */}
							{!item.hasOwnProperty("children") &&
								<button onClick={(event) => this.removeCategory(item, event)} className="icon-button delete"></button>
							}
						</span>
					</span>

				} key={item.id}>{loop(item.children)}</TreeNode>;
			}
			return <TreeNode className="categories-sub-list" title={
				<span onClick={(event) => this.selectedCategory(item, event)} className="main-category">
					<h2>{item.name}</h2>
					<span className="category-options">
						<button onClick={(event) => this.CreateBlankChildCategory(item.id, event)} className="icon-button new"></button>
						<button onClick={(event) => this.selectedCategory(item, event)} className="icon-button edit"></button>
						{/* <button onClick={(event) => this.removeCategory('parent', item, event)} className="icon-button delete"></button> */}
						<button onClick={(event) => this.removeCategory(item, event)} className="icon-button delete"></button>
					</span>
				</span>

			} key={item.id} />;
		});

		return (
			<div id="product-categories">
				{/* ALERTS */}
				{this.state.alert.triger &&
					<Toast type={this.state.alert.type} description={this.state.alert.text} />
				}
				<div className="product-categories-container">
					<div className="content-blocks">
						<div className="content-block light">
							<div className="content-block-intro">
								<h2 className="content-block-title">
									<span className="content-block-icon structure"></span>
									Categories Structure
								</h2>
							</div>
							<div className="content-block-main">
								{!ArrayIsEmpty(this.state.categories) ?
									<div className="category-tree">
										<Tree
											draggable
											onDrop={this.onDrop}
											onDragOver={this.DisableDrop}
											autoExpandParent={false}
										>
											{/* {this.state.categories.sort((a, b) => { return a.order - b.order }).map((item, i) =>
												<TreeNode onClick={(event) => console.log("YOO", event)} className="categories-main-list" title={
													<span onClick={(event) => this.selectedCategory(item, event)} className="main-category">
														<h2>{item.name}</h2>
														<span className="category-options">
															<button onClick={(event) => this.CreateBlankChildCategory(item.id, event)} className="icon-button new"></button>
															<button onClick={(event) => this.selectedCategory(item, event)} className="icon-button edit"></button>
															<button onClick={(event) => this.removeCategory('parent', item, event)} className="icon-button delete"></button>
														</span>
													</span>
												} key={item.id}>
													{item.children !== undefined && item.children.sort((a, b) => { return a.order - b.order }).map((children, y) =>
														<TreeNode onDragOver={(event) => console.log("YOO", event)} className="categories-sub-list" title={
															<span onClick={(event) => this.selectedCategory(children, event)} className="main-category">
																<h2>{children.name}</h2>
																<span className="category-options">
																	<button onClick={(event) => this.newChildCategory(children.id, event)} className="icon-button new"></button>
																	<button onClick={(event) => this.selectedCategory(children, event)} className="icon-button edit"></button>
																	<button onClick={(event) => this.removeCategory('child', children, event)} className="icon-button delete"></button>
																</span>
															</span>

														} key={children.id} />
													)}
												</TreeNode>
											)} */}
											{loop(this.state.categories)}
										</Tree>
										<CreateNewCategory />
									</div>
									:
									<div className="root-category-disclaimer">
										<div className="if-empty">
											<div className="empty-categories">
												<h2>You have no categories created. Use the button below to create a new Category</h2>
											</div>
											<CreateNewCategory />
										</div>
									</div>
								}
							</div>
						</div>
						<div className="content-block dark">
							<div className="content-block-intro">
								<h2 className="content-block-title">
									<span className="content-block-icon metadata"></span>
									Category Settings
								</h2>
							</div>

							{this.state.showMetadata ?
								<Tabs selected={0}>
									<TabContent title="Metadata">
										<div className="content-block-main">
											<p className="input-holder">
												<input
													value={this.state.selectedCategory.name}
													onChange={this.editOnChange}
													type="text"
													name="name"
													className="input-field--labeled"
													id="category-name"
													required
												/>
												<label className="field-label" htmlFor="category-name">Category Name</label>
												<span className="input-border"></span>
											</p>
											<p className="input-holder">
												<input
													value={this.state.selectedCategory.permalink}
													onChange={this.editOnChange}
													type="text"
													name="permalink"
													className="input-field--labeled"
													id="category-permalink"
													required
												/>
												<label className="field-label" htmlFor="category-permalink">Permalink</label>
												<span className="input-border"></span>
											</p>
											<p className="button-holder">
												<button className="rounded-button primary primary-icon gradient orange save-button" onClick={() => this.checkForChange(this.state.selectedCategory)}><span className="button-text">Update</span></button>
												<button onClick={this.CancelMetadataActions} className="rounded-button primary ghost cancel-button"><span className="button-text">Cancel</span></button>
											</p>
										</div>
									</TabContent>
									<TabContent title="Items">
										<div className="content-block-main items-block">
											<h2 className="autocomplete-search-intro">Reorder items in Category: <u>{this.state.selectedCategory.name}</u></h2>
											<div className="items-group" onDragOver={this.AllowDrop}>
												{this.state.selectedCategory.hasOwnProperty("products") && (!isEmpty(this.props.productsMap) || !ArrayIsEmpty(this.props.products)) ?
													this.state.selectedCategory.products.map((product, i) => {
														return (
															this.props.products[this.props.productsMap[product]] !== undefined ?
																<div className={"item-master"} key={product} draggable="true" onDragEnter={(event) => this.StyleDragOver(event)} onDragStart={(event) => this.DragProduct(event, product)} onDrop={(event) => this.DropProduct(event)} id={product}>
																	<div className={"item-scale-on-drag " + (this.state.dropTarget === product ? "drop-target" : "")}>
																		<figure className="image">
																			{
																				this.props.products[this.props.productsMap[product]].hasOwnProperty("images") ?
																				<img
																					src={`${process.env.REACT_APP_MINIO_GATEWAY}products/${this.props.products[this.props.productsMap[product]].images[0].path}`}
																					alt="Product image"
																					onError={(e) => { e.target.src = placeholder }}
																				/> : 
																				<img
																					src={placeholder}
																					alt="Product image"
																					onError={(e) => { e.target.src = placeholder }}
																				/>
																			}
																		</figure>

																		<div className="item-info">
																			<h2 className="item-name">{this.props.products[this.props.productsMap[product]].name}</h2>
																			<h4 className="item-description">{this.props.products[this.props.productsMap[product]].description}</h4>
																		</div>

																		<div className="count">
																			<div className="front-back-buttons">
																				<button className={"icon-button to-front " + (this.state.dropTarget === product ? "deactivate" : "")} onClick={() => this.FrontBackReorder("front", product)}></button>
																				<button className={"icon-button to-back " + (this.state.dropTarget === product ? "deactivate" : "")} onClick={() => this.FrontBackReorder("back", product)} ></button>
																			</div>
																			<div className="drag-src"></div>
																		</div>
																	</div>
																</div> : null
														)
													}) : null}
											</div>
										</div>
									</TabContent>
								</Tabs> :
								<div className="content-block-main">
									<EmptyStateDisclaimer
										message="Click on a category to start editiing its information"
										icon="cursor"
									/>
								</div>
							}

							{/* {this.state.showMetadata ?
								<div className="content-block-main">
									<p className="input-holder">
										<input
											value={this.state.selectedCategory.name}
											onChange={this.editOnChange}
											// type="text"
											name="name"
											className="input-field--labeled"
											id="category-name"
											required
										/>
										<label className="field-label" htmlFor="category-name">Category Name</label>
										<span className="input-border"></span>
									</p>
									<p className="input-holder">
										<input
											value={this.state.selectedCategory.permalink}
											onChange={this.editOnChange}
											// type="text"
											name="permalink"
											className="input-field--labeled"
											id="category-permalink"
											required
										/>
										<label className="field-label" htmlFor="category-permalink">Permalink</label>
										<span className="input-border"></span>
									</p>
									<p className="button-holder">
										<button className="rounded-button primary primary-icon gradient orange save-button" onClick={() => this.checkForChange(this.state.selectedCategory)}><span className="button-text">Update</span></button>
										<button onClick={this.CancelMetadataActions} className="rounded-button primary ghost cancel-button"><span className="button-text">Cancel</span></button>
									</p>
								</div>

								:

								<div className="content-block-main">
									<EmptyStateDisclaimer
										message="Click on a category to start editiing its information"
										icon="cursor"
									/>
								</div>
							} */}
						</div>
					</div>
					{/* {objectHasProperty(this.state.selectedCategory, "id") ?
						<div className="action-buttons">
							<button className="rounded-button primary primary-icon gradient pink save-button" onClick={() => this.editCategory(this.state.selectedCategory)}><span className="button-text">Update</span></button>
							<button className="rounded-button primary primary-icon gradient blue cancel-button"><span className="button-text">Cancel</span></button>
						</div>
						:
						<div className="action-buttons">
							<button className="rounded-button primary primary-icon gradient pink save-button" onClick={this.saveCategory}><span className="button-text">Save</span></button>
							<button className="rounded-button primary primary-icon gradient blue cancel-button"><span className="button-text">Cancel</span></button>
						</div>
					} */}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		productsMap: state.product.productsMap,
		products: state.product.allProducts
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setCategories: (data) => {
			dispatch(setCategories(data))
		},
		setProductList: (data) => {
			dispatch(setProductList(data))
		},
		setProductsMap: (data) => {
			dispatch(setProductsMap(data))
		}
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(Categories);