import React, { Component } from "react";
import "../css/ConfirmAlert.css";

class Alert extends Component {
	render() {
		return (
			<div id="alert" className="confirm-alert">
				<div className="alert-overlay"></div>

				<div className="alert-container">
					<div className="alert-header">
						<h2 className="alert-header-title">Item Exists</h2>
					</div>

					<div className="alert-data">
						<p className="alert-data-description">An item with this description already exists in your products. To continue update the necesery changes.</p>
					</div>

					<div className="alert-actions">
						<button className="rounded-button ghost save-button"><span className="button-text">Got it</span></button>
					</div>
				</div>
			</div>
		)
	}
}

export default Alert;