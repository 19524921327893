import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import oblax from "../../../assets/oblax";
import { Page } from "@oblax/sdk-js";
import { ProductTags } from "../../Products/components/Parts";

import { isEmpty } from "../../../assets/helper";

import { Toast, ConfirmAlert } from "../../../parts";

import "../css/PageDetails.css";
import 'react-quill/dist/quill.snow.css';

import { UTILITIES } from "../../../assets/constants";

// import TinyMCE from "react-tinymce";

class PageDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			pageId: "",
			title: "",
			image: "",
			content: "",
			permalink: "",
			tags: [],
			tagInputVisible: false,
			tagInputValue: '',
			_errors: [],
			addNewPage: false,
			edit: true,

			alert: {
				type: '',
				text: '',
				triger: false
			},

			deleteAlert: false,
			deletePageId: ''
		}

		// TAGS
		this.handleTagClose = this.handleTagClose.bind(this);
		this.showTagInput = this.showTagInput.bind(this);
		this.handleTagInputChange = this.handleTagInputChange.bind(this);
		this.handleTagInputConfirm = this.handleTagInputConfirm.bind(this);
		this.inputEditorChangeTextarea = this.inputEditorChangeTextarea.bind(this);
		this.editTags = this.editTags.bind(this);

		// EDIT
		this.enableEdit = this.enableEdit.bind(this);
		this.inputChange = this.inputChange.bind(this);

		// SAVE AND UPDATE
		this.savePage = this.savePage.bind(this);
		this.updatePage = this.updatePage.bind(this);

		this.activateAlert = this.activateAlert.bind(this);
		this.deleteAlert = this.deleteAlert.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);
		this.deletePage = this.deletePage.bind(this);
	}

	componentWillMount() {
		var url = window.location.href.split('pages/');
		if (url[1] === "new") {
			this.setState({
				addNewPage: true,
				edit: false
			})
		} else {
			var id = url[1];
			oblax.pages.getPageById(id)
				.then((result) => {
					if (result.ok) {
						console.log(result)
						this.setState({
							title: result.data.title,
							image: result.data.image,
							tags: result.data.tags,
							content: result.data.content,
							permalink: result.data.permalink,
							id: result.data.id,
							pageId: result.data.id
						});
					} else {
						console.log("GET PRODUCT BY ID ERROR");
						this.props.history.push('/pages');
					}
				})
				.catch(err => {
					console.log("GET PRODUCT BY ID ERROR: ", err);
					this.props.history.push('/pages');
				});
		}
	}


	// Tags
	// ==========================
	handleTagClose(removedAllergen) {
		const tags = this.state.tags.filter(item => item !== removedAllergen);
		this.setState({
			tags
		});
	}

	showTagInput() {
		this.setState({ tagInputVisible: true }, () => this.input.focus());
	}

	handleTagInputChange(e) {
		this.setState({
			tagInputValue: e.target.value
		});
	}

	handleTagInputConfirm() {
		const tagInputValue = this.state.tagInputValue;
		let tags = this.state.tags;
		if (tagInputValue && tags.indexOf(tagInputValue) === -1) {
			tags = [...tags, tagInputValue];
		}
		this.setState({
			tags,
			tagInputVisible: false,
			tagInputValue: '',
		});
	}

	saveTagInputRef = input => this.input = input;
	// ==========================

	// EDIT
	// =========================
	enableEdit() {
		console.log("STATE", this.state);
		this.setState(state => {
			return {
				edit: !state.edit
			}
		})
	}

	inputChange({ target }) {
		this.setState({
			[target.name]: target.value
		})
	}

	inputEditorChangeTextarea(value) {
		this.setState({
			content: value
		})
	}
	// =========================

	// VALIDATE DATA
	// =========================
	validateInputData() {
		if (this.state.title === '') {
			return false;
		}

		if (this.state.content === '') {
			return false;
		}

		if (this.state.permalink === '') {
			return false;
		}

		return true;
	}
	// =========================

	// SAVE AND UPDATE
	// =========================
	savePage() {
		console.log(this.state);
		if (this.validateInputData()) {
			var page = new Page();
			page.loadFromJSON({
				title: this.state.title,
				content: this.state.content,
				permalink: this.state.permalink,
				tags: this.state.tags,
				image: this.state.image
			});

			console.log("TCL: savePage -> page", page);
			oblax.pages.insertPage(page)
				.then((result) => {
					if (result.ok) {
						this.setState({
							edit: true
						});
						this.props.history.push('/pages');
					} else {
						console.log("PAGE ERROR: ", result.status);
					}
				})
				.catch(err => {
					console.log("PAGE ERROR: ", err);
				});
		} else {
			console.log("VALIDATION ERROR");
		}
	}

	updatePage() {
		if (this.validateInputData()) {
			var page = new Page();
			page.loadFromJSON({
				title: this.state.title,
				content: this.state.content,
				permalink: this.state.permalink,
				tags: this.state.tags,
				image: this.state.image
			});

			oblax.pages.updatePage(this.state.id, page)
				.then((result) => {
					if (result.ok) {
						this.setState({
							edit: true
						});
						this.activateAlert("success", "You have successfully edited a page.");
					} else {
						console.log("PAGE ERROR: ", result.status);
					}
				})
				.catch(err => {
					console.log("PAGE ERROR: ", err);
				});
		} else {
			console.log("VALIDATION ERROR");
		}
	}
	// =========================

	// TAGS 
	//==========================
	editTags(tags) {
		this.setState({
			tags
		}, () => {
			if (isEmpty(tags)) {
				this.setState({
					tags: []
				})
			}
		});
	}
	//==========================

	// Activation of alert box on user action
	activateAlert(type, text) {
		this.setState({
			alert: {
				type: type,
				text: text,
				triger: true
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					alert: {
						type: "",
						text: "",
						triger: false
					}
				})
			}, UTILITIES.alertTime);
		});
	}

	// Show alert after user wants to delete Company
	deleteAlert(id) {
		this.setState({
			deleteAlert: true,
			deletePageId: id
		});
	}

	// If user decides to cancel removal of company, bring him back
	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			deletePageId: ''
		})
	}

	// Deleting a company
	deletePage() {
		oblax.pages.deletePage(this.state.deletePageId)
			.then((result) => {
				if (result.ok) {
					this.setState({
						deleteAlert: false,
						deletePageId: ''
					}, () => {
						this.props.history.push("/pages");
					});
				} else {
					this.setState({
						deleteAlert: false,
						deletePageId: ''
					});
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("DELETE PAGE ERROR", result.state);
					}
				}
			})
			.catch(err => {
				this.setState({
					deleteAlert: false,
					deletePageId: ''
				});
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("companies ERROR: ", err);
				}
			});
	}

	render() {
		const modules = {
			toolbar: [
				[{ 'header': [1, 2, false] }],
				['bold', 'italic', 'underline', 'strike', 'blockquote'],
				[{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
				['link', 'image'],
				['clean']
			]
		}

		const formats = [
			'header',
			'bold', 'italic', 'underline', 'strike', 'blockquote',
			'list', 'bullet', 'indent',
			'link', 'image'
		]

		return (
			<div id="page-details">
				{this.state.alert.triger &&
					<Toast type={this.state.alert.type} description={this.state.alert.text} />
				}
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Page"
						message="You are attempting to delete a page from the database. Are you sure you want to proceed?"
						handleMainFunction={this.deletePage}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}

				<div className="bc-details-container">
					<div className="full-block long">
						<div className="content-block light">
							<div className="content-block-intro">
								<h2 className="content-block-title"> <span className="content-block-icon page-details"></span>
									{!this.state.addNewPage ? "Page Details" : "Create New Page"}</h2>
							</div>

							<div className="content-block-main">
								<p className={"input-holder full " + (this.state._errors.includes("title") ? "error-active" : "")}>
									<input
										className="input-field--labeled"
										id="page-detail-title"
										name="title"
										value={this.state.title}
										onChange={this.inputChange}
										disabled={this.state.edit}
										required
									/>
									<label className="field-label" htmlFor="page-detail-title">Title</label>
									<span className="input-error">Title is required</span>
									<span className="input-border"></span>
								</p>
								<p className={"input-holder full " + (this.state._errors.includes("permalink") ? "error-active" : "")}>
									<input
										className="input-field--labeled"
										id="page-detail-permalink"
										name="permalink"
										value={this.state.permalink}
										onChange={this.inputChange}
										disabled={this.state.edit}
										required
									/>
									<label className="field-label" htmlFor="page-detail-permalink">Permalink</label>
									<span className="input-error">Title is required</span>
									<span className="input-border"></span>
								</p>

								<div className={"bc-rte" + (this.state.edit ? " quill-disabled" : "")}>
									<h2 className="field-label-inputless">Content</h2>
									<ReactQuill theme="snow"
										modules={modules}
										formats={formats}
										onChange={this.inputEditorChangeTextarea}
										value={this.state.content}
										bounds={'.bc-details-container'}
										readOnly={this.state.edit}>
									</ReactQuill>
								</div>

								<p className={"rounded-data-holder tags light " + (this.state._errors.includes("tags") && isEmpty(this.state.tags) ? "error-active" : "" || this.state.edit ? "disabled-tags" : "")}>
									<span className="data-title">Tags</span>
									{this.state._errors.includes("tags") && this.isEmpty(this.state.tags) ? <span className="input-error">Tags are required</span> : null}
									<ProductTags tags={this.state.tags} editTags={this.editTags} edit={false} />
								</p>
							</div>
						</div>
					</div>
				</div>

				{/* <div>
					<span>
						<h4>Title</h4>
						<Input
							name="title"
							onChange={this.inputChange}
							value={this.state.title}
							disabled={false}
						/>
					</span>
					<span>
						<h4>Perma Link</h4>
						<Input
							name="permalink"
							onChange={this.inputChange}
							value={this.state.permalink}
							disabled={false}
						/>
					</span>
					<span>
                        <h4>Content</h4>
                        {this.state.content !== '' && 
                            // <TinyMCE
                            //     content={this.state.content}
                            //     config={{
                            //         plugins: 'link image code',
                            //         toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code'
                            //     }}
                            //     onChange={this.inputEditorChange}
                            //     disabled={false}
                            // /> 
                            <textarea onChange={this.inputEditorChange}>{this.state.content}</textarea>
                        } 
                    </span>
					<span>
						<h4>Content</h4>
						<span>{this.state.content}</span>
						<textarea class="page-textarea" onChange={this.inputEditorChangeTextarea} value={this.state.content} name="content"></textarea>
					</span>
					<span>
						<h4>Tags</h4>
						{this.state.tags.map((tags, index) => {
							const isLongIngredients = tags.length > 20;
							const ingredientElem = (
								<Tag key={tags} closable={this.state.edit ? '' : index >= 0} afterClose={() => this.handleBadgesClose(tags)}>
									{isLongIngredients ? `${tags.slice(0, 20)}...` : tags}
								</Tag>
							);
							return isLongIngredients ? <Tooltip title={tags} key={tags}>{ingredientElem}</Tooltip> : ingredientElem;
						})}
						{tagInputVisible && (
							<Input
								ref={this.saveTagInputRef}
								type="text"
								size="small"
								style={{ width: 78 }}
								value={tagInputValue}
								onChange={this.handleTagInputChange}
								onBlur={this.handleTagInputConfirm}
								onPressEnter={this.handleTagInputConfirm}
								disabled={false}
							/>
						)}
						{(!tagInputVisible && this.state.edit === false) && (
							<Tag
								onClick={this.showTagInput}
								style={{ background: '#fff', borderStyle: 'dashed' }}
							>
								<Icon type="plus" /> New Tag
                            </Tag>
						)}
					</span>
				</div> */}

				{!this.state.addNewPage ?
					<div className="action-buttons">
						{this.state.edit ? <button onClick={this.enableEdit} className="rounded-button primary primary-icon gradient blue save-button" >
							<span className="button-text">Edit</span></button> : null}

						{!this.state.edit ? <button onClick={this.updatePage} className="rounded-button primary primary-icon gradient pink save-button" >
							<span className="button-text">Update</span> </button> : null}

						{!this.state.edit && <button onClick={() => this.deleteAlert(this.state.pageId)} className="rounded-button primary primary-icon gradient darkblue delete-button">
							<span className="button-text">Delete</span> </button>}
					</div>
					:
					<div className="action-buttons">
						<button onClick={this.savePage} className="rounded-button primary primary-icon gradient blue create-button">
							<span className="button-text">Create</span></button>
					</div>
				}
			</div>
		);
	}
}

export default PageDetails;