import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import oblax from "../../../assets/oblax";
import '../css/Login.css';

// IMAGES
import Logo from "../../../assets/images/logo_dark.svg"

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userPassword: '',
            error: '',
            remember: false
        };

        this.onChangeUserName = this.onChangeUserName.bind(this);
        this.submitLoginData = this.submitLoginData.bind(this);
        this.validateLogin = this.validateLogin.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.onRemeberMe = this.onRemeberMe.bind(this);
    }

    componentDidMount() {
        const remember = window.localStorage.getItem('rememberMe');
        if (remember != null && JSON.parse(remember) != false) {
            this.setState({
                userName: window.localStorage.getItem('userName'),
                userPassword: window.localStorage.getItem('userPassword'),
                remember: JSON.parse(window.localStorage.getItem("rememberMe"))
            });
        } else {
            this.setState({
                userName: '',
                userPassword: '',
                remember: false
            }, () => {
                window.localStorage.setItem('rememberMe', this.state.remember);
                window.localStorage.setItem('userName', this.state.userName);
                window.localStorage.setItem('userPassword', this.state.userPassword);
            })
        }
    }

    emitEmpty() {
        this.userNameInput.focus();
        this.setState({ userName: '' });
    }

    onRemeberMe() {
        this.setState({
            remember: !this.state.remember
        },() => {
            window.localStorage.setItem('rememberMe', this.state.remember);
        });
    }

    onChangeUserName({ target }) {
        this.setState({
            [target.name]: target.value,
            error: ''
		});
		this.MaterialLabelStyle(target);
	}

    validateLogin() {
        if (this.state.userName === '' || this.state.userPassword === '') {
            return false;
        } else {
            return true;
        }
	}

    submitLoginData() {
        if (this.validateLogin()) {
            oblax.auth.adminLogin(this.state.userName, this.state.userPassword)
                .then((result) => {
                    if (result.ok) {
                        if (this.state.remember) {
                            window.localStorage.setItem('userName', this.state.userName);
                            window.localStorage.setItem('userPassword', this.state.userPassword);
                        } else {
                            window.localStorage.setItem('userName', '');
                            window.localStorage.setItem('userPassword', '');
                        }
                        this.props.history.push("/dashboard/general")
                    } else {
                        this.setState({
                            userName: '',
                            userPassword: '',
                            error: "Wrong credentials"
                        })
                    }
                })
                .catch(err => {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("LOFIN_ERROR: ", err);
                    }
                });
        }
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.submitLoginData();
        }
	}

	MaterialLabelStyle(target) {
		let classData = [];

		if(target.value !== "") {
			classData.push("dirty-label");
		}

		return classData;
	}

    render() {
        const { userName, userPassword } = this.state;
        if (this.props.auth.isAuthenticated && (this.props.auth.authUser !== null)) {
            // this.props.history.push("/products");
            return null;
        }
        return (
            <div id="sign-in" onKeyPress={this.handleKeyPress}>
                <div className="sign-in-container">
                    <div className="sign-in-block form-data">
                        <div className="form-data-section">
                            <div className="logo">
                                <div className="logo-icon">
                                    <img src={Logo} alt="Booster Cloud, Dark" />
                                </div>
                                <div className="logo-text">
                                    <h2><span className="b-orange">Booster</span><span className="c-dark">Cloud</span></h2>
                                    <span className="b-desc">control panel</span>
                                </div>
                            </div>
                        </div>

                        <div className="form-data-section fields">
                            <div className="form-data-intro">
                                <h2 className="form-data-title">Sign in</h2>
                                <p className="form-data-description">
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s
                                </p>
                            </div>

                            <div className="form-data-fields">
                                <p className="input-holder full">
                                    <input 
                                        value={userName}
                                        type='text'
                                        name='userName'
                                        onChange={this.onChangeUserName}
                                        ref={node => this.userNameInput = node}
                                        className={"input-field--labeled"}
                                        id="form-data-username" 
                                        required 
                                    />
                                    <label className={"field-label"} htmlFor="form-data-username">Username</label>
									<span className="input-border"></span>
                                </p>
                                <p className="input-holder full">
                                    <input 
                                        value={userPassword}
                                        type='password'
                                        name='userPassword'
                                        onChange={this.onChangeUserName}
                                        ref={node => this.userPassword = node}
                                        className={"input-field--labeled"}
										id="form-data-password"
                                        required 
                                    />
                                    <label className="field-label" htmlFor="form-data-password">Password</label>
									<span className="input-border"></span>
                                </p>
                            </div>

                            <div className="form-data-additions">
                                <p className="input-holder checkbox-holder">
                                    <input 
                                        type="checkbox" 
                                        name="remember" 
                                        className="checkbox--labeled" 
                                        id="form-data-remember"
                                        checked={this.state.remember}
                                        onChange={this.onRemeberMe} 
                                    />
                                    <label className="checkbox-label" htmlFor="form-data-remember">Remember me</label>
                                </p>

                                <a className="form-data-link" href="#">Forgot your password?</a>
                            </div>
                            <div className="form-data-action">
                                <button className="rounded-button primary gradient orange login-button" onClick={this.submitLoginData} ><span
								className="button-text">Login</span></button>
                            </div>

                        </div>

                        <div className="form-data-section footer">
                            <p className="form-data-paragraph">
                                Lorem Ipsum is simply dummy text of the printing and typesetting
                                industry. Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s
                            </p>
                            <span className="form-data-copyright">© 2018 Beyond Basics</span>
                        </div>
                    </div>
                    <div className="sign-in-block disclaimer-data">
                        <div className="disclaimer-data-centered">
                            <div className="disclaimer-section intro">
                                <h2>Fun fact...</h2>
                            </div>

                            <div className="disclaimer-section description">
                                <h2><span className="title-white">A cloud </span><span className="title-orange">unlike any other</span>
                                </h2>
                                <p className="description-paragraph">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                                    rutrum aliquam dolor, in vulputate nisl mattis vitae. Praesent accumsan laoreet orci ac
                                    maximus. Donec ut mi velit. Mauris bibendum, nulla vitae lobortis vestibulum, ipsum libero
                                    gravida sapien
                                </p>
                            </div>

                            <div className="disclaimer-section outro">
                                <p className="outro-paragraph">
                                    Donec ut mi velit. Mauris bibendum, nulla vitae lobortis vestibulum,
                                    ipsum libero gravida sapien. 
                                    <a href="#">Learn more</a>
                                </p>
                            </div>
                        </div>
                        <div className="rocket"></div>
                    </div>
                </div>
            </div>
        );
    }
}


// Connecting all data from the store as props
const mapStateToProps = (state) => {
    return {
        auth: state.app.auth
    };
};

export default withRouter(connect(mapStateToProps, null)(Login));