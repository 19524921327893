import React, { Component } from "react";
import placeholder from "../../../../assets/images/avatar.jpg";
import "../../css/Parts/User.css";

class User extends Component {
    constructor() {
        super();
        this.state = {
            settings: false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.userSettings = this.userSettings.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    userSettings() {
        this.setState({
            settings: !this.state.settings
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
           this.setState({
            settings: false
           })
        }
    }

    handleSelect() {
        this.setState({
            settings: false
        })
    }

    render() {
        return (
            <div className="corner-item user" ref={this.setWrapperRef}>
                <div className="user-container">
                    <div className="user-data">
                        <h2>{this.props.name}</h2>
                        <span>{this.props.role}</span>
                    </div>
                    <div className="user-avatar" onClick={this.userSettings}>
                        <figure>
                            <img 
                                src={`${process.env.REACT_APP_MINIO_GATEWAY}avatars/${this.props.picture}`} 
                                onError={(e) => {e.target.src = placeholder}} 
                            />
                        </figure>
                    </div>
                </div>
                {this.state.settings &&
                    <div className="corner-item-dropdown">
                        <ul className="dropdown-list">
                            <li>
                                <a href="#" onClick={this.handleSelect}>
                                    <span className="dropdown-icon preferences"></span>
                                    <span className="dropdown-data">
                                        <h2 className="dropdown-title">Preferences</h2>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={this.props.logout}>
                                    <span className="dropdown-icon signout"></span>
                                    <span className="dropdown-data">
                                        <h2 className="dropdown-title">Sign out</h2>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

export default User;