import React, { Component } from "react";
import "../../css/Parts/Notifications.css";

class Notifications extends Component {
    constructor() {
        super();
        this.state = {
            notifications: false
        }

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.notificationList = this.notificationList.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    notificationList() {
        this.setState({
            notifications: !this.state.notifications
        })
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
           this.setState({
               notifications: false
           })
        }
    }

    handleSelect() {
        this.setState({
            notifications: false
        })
    }

    render() {
        return (
            <div className="corner-item notifications" ref={this.setWrapperRef}>
                <div className="corner-item-icon-container" onClick={this.notificationList}>
                    <h2 className="corner-item-count">3</h2>
                    <span className="corner-item-icon"></span>
                </div>
                {this.state.notifications &&
                    <div className="corner-item-dropdown">
                        <ul className="dropdown-list">
                            <li>
                                <a href="#" onClick={this.handleSelect}>
                                    <span className="dropdown-icon upgrade"></span>
                                    <span className="dropdown-data">
                                        <h2 className="dropdown-title">Scheduled upgrade</h2>
                                        <h4 className="dropdown-date">11.12.2018</h4>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={this.handleSelect}>
                                    <span className="dropdown-icon upgrade"></span>
                                    <span className="dropdown-data">
                                        <h2 className="dropdown-title">New features</h2>
                                        <h4 className="dropdown-date">11.12.2018</h4>
                                    </span>
                                </a>
                            </li>
                            <li>
                                <a href="#" onClick={this.handleSelect}>
                                    <span className="dropdown-icon payment"></span>
                                    <span className="dropdown-data">
                                        <h2 className="dropdown-title">Monthly Payment</h2>
                                        <h4 className="dropdown-date">11.12.2018</h4>
                                    </span>
                                </a>
                            </li>
                            <li className="last-item">
                                <a href="#" onClick={this.handleSelect}>
                                    <span className="dropdown-data">
                                        <h4 className="dropdown-date">All Notifications</h4>
                                    </span>
                                </a>
                            </li>
                        </ul>
                    </div>
                }
            </div>
        )
    }
}

export default Notifications;