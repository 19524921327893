import React, { Component } from 'react';
import { DropDown } from "../../../../parts";
import { formatDateByLocaleString } from "../../../../assets/helper";

class ProductPrices extends Component {
    render() {
        return (
            <div className="full-block one price">
                <div className="content-block">
                    <div className="content-block-intro">
                        <h2 className="content-block-title"> <span className="content-block-icon price"></span> Price</h2>
                    </div>

                    <div className="content-block-main">
                        <div className="content-block-header">
                            <div className="select-holders">
                                <p className="input-holder half">
                                    <input
                                        className="input-field--labeled"
                                        id="product-price"
                                        min="1"
                                        type="number"
                                        name="price"
                                        step="any"
                                        value={this.props.newPrice.price}
                                        onChange={this.props.handlePriceInputNumberChange}
                                        required
                                    />
                                    <label className="field-label" htmlFor="product-price">Price</label>
                                    <span className="input-border"></span>
                                </p>
                                <p className="input-holder half">
                                    <input
                                        className="input-field--labeled"
                                        id="product-purchase-price"
                                        min="1"
                                        type="number"
                                        name="purchase_price"
                                        step="any"
                                        value={this.props.newPrice.purchase_price}
                                        onChange={this.props.handlePriceInputNumberChange}
                                        required
                                    />
                                    <label className="field-label" htmlFor="product-purchase-price">Purchase Price</label>
                                    <span className="input-border"></span>
                                </p>
                                <p className="input-holder half">
                                    <input
                                        className="input-field--labeled"
                                        id="product-tax"
                                        min="1"
                                        type="number"
                                        name="tax"
                                        step="any"
                                        value={this.props.newPrice.tax}
                                        onChange={this.props.handlePriceInputNumberChange}
                                        required
                                    />
                                    <label className="field-label" htmlFor="product-tax">Tax (%)</label>
                                    <span className="input-border"></span>
                                </p>
                                <DropDown
                                    disabled={false}
                                    title="Price"
                                    list={this.props.currencies}
                                    handleSelectChange={this.props.handlePriceCurrencyChange}
                                    value={this.props.newPrice.currency}
                                    className=""
                                />
                            </div>
                            <button className="circle-button icon dark add-new-nutirition-value-button" onClick={this.props.addNewPrice}></button>
                        </div>

                        <div className="content-block-table">
                            <table className="minimal-table">
                                <thead className="minimal-table-head">
                                    <tr>
                                        <th>Price</th>
                                        <th>Purchase Price</th>
                                        <th>Tax</th>
                                        <th>Currency</th>
                                        <th>Created</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="minimal-table-body">
                                    {this.props.price.map((item, i) =>
                                        <tr key={i} className={`${!item.hasOwnProperty('id') ? 'new-entry-animate' : ''}`}>
                                            <td>{item.price.toFixed(2)}</td>
                                            <td>{item.purchase_price.toFixed(2)}</td>
                                            <td>{item.tax}</td>
                                            <td>{item.currency}</td>
                                            <td>{formatDateByLocaleString(item._created, 'en-GB')}</td>
                                            {!item.hasOwnProperty('id')
                                                ?
                                                <td><button onClick={() => this.props.deleteUnsavedPrice(i)} className="ghost-icon-button delete-button"></button></td>
                                                :
                                                <td></td>
                                            }
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductPrices;