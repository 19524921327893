import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";

import thunk from "redux-thunk";
import combine from "./reducers";

var middleware;
if (process.env.REACT_APP_ENV !== 'prod') {
    middleware = applyMiddleware(thunk, createLogger());
} else {
    middleware = applyMiddleware(thunk);
}

export default createStore(combine, middleware);