import React, { Component } from "react";
import "../css/Tabs.css";

class TabContent extends Component {

	render() {
		return this.props.children;
	}
}

export default TabContent;