import React, { Component } from 'react';
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import placeholder from "../../../assets/images/person-placeholder.png";
import { Input, Menu, Dropdown, Button, Icon } from 'antd';
import { User, Location } from "@oblax/sdk-js";
import oblax from "../../../assets/oblax";
import { navName } from "../../Home/actions";
import { DropDown } from "../../../parts";
import "../css/UserDetails.css";

import { USER_TYPES } from "../../../assets/constants";

class UserDetails extends Component {
	constructor() {
		super();
		this.state = {
			role: '',
			first_name: '',
			last_name: '',
			email: '',
			gender: '',
			edit: true,
			location: [],
			phone: '',
			collapse: null,
			password: '',
			passwordVerify: ''
		}

		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.editChange = this.editChange.bind(this);
		this.inputChange = this.inputChange.bind(this);
		this.updateUserData = this.updateUserData.bind(this);
		this.inputLocationChange = this.inputLocationChange.bind(this);
		this.expandLocation = this.expandLocation.bind(this);
		this.display = this.display.bind(this);
		this.displayEdit = this.displayEdit.bind(this);
		this.saveUserData = this.saveUserData.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.changeRole = this.changeRole.bind(this);
		this.OnGenderChange = this.OnGenderChange.bind(this);
	}
	componentWillMount() {
		var url = window.location.href.split('allUsers/');
		if (url[1] === "new") {
			this.setState({
				addNewUser: true,
				edit: false
			}, () => {
				this.props.navName('Create User');
			})
		} else {
			var id = url[1];
			oblax.users.getUserById(id)
				.then((result) => {
					if (result.ok) {
                        console.log("TCL: UserDetails -> componentWillMount -> result", result);
						this.setState({
							id: result.data.id,
							role: result.data.role,
							active: result.data.active,
							confirmed: result.data.confirmed,
							email: result.data.email,
							first_name: result.data.first_name,
							last_name: result.data.last_name,
							gender: result.data.gender,
							picture: result.data.picture,
							phone: result.data.phone
						}, () => {
							this.props.navName(`${this.state.first_name} ${this.state.last_name}`);
							this.getUserLocation(this.state.id);
						});
					} else {
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("GET USER BY ID ERROR");
						}
						this.props.history.push('/users/allUsers');
					}
				})
				.catch(err => {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("GET USER BY ID ERROR", err);
					}
					this.props.history.push('/users/allUsers');
				});
		}

	}

	getUserLocation(id) {
		oblax.users.getUserLocations(id)
			.then((result) => {
				if (result.ok) {
					this.setState({
						location: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("LOCATION ERROR: ", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("LOCATION ERROR: ", err);
				}
			});
	}

	inputChange({ target }) {
		this.setState({
			[target.name]: target.value
		})
	}

	inputLocationChange(id, itemAttributes) {
		var index = this.state.location.findIndex(x => x.id === id);
		if (index === -1) {
			if (process.env.REACT_APP_ENV !== 'prod') {
				console.log("ERRROR");
			}
		} else {
			this.setState({
				location: [
					...this.state.location.slice(0, index),
					Object.assign({}, this.state.location[index], itemAttributes),
					...this.state.location.slice(index + 1)
				]
			});
		}
	}

	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

	editChange() {
		this.setState({
			edit: !this.state.edit
		})
	}

	handleMenuClick(e) {
		this.setState({
			role: e.key
		})
	}

	validateData() {
		if (this.state.first_name === '') {
			return false;
		}

		if (this.state.last_name === '') {
			return false;
		}

		// if (this.state.role === '') {
		// 	return false;
		// }

		if (this.state.email === '') {
			return false;
		}

		if (this.state.password !== this.state.passwordVerify) {
			return false;
		}

		return true;
	}

	OnGenderChange(e) {
		console.log("TCL: OnGenderChange -> e", e.target.id);
		this.setState({
			gender: e.target.id === "user-details-gender-female" ? "female" : "male"
		})
	} 

	saveUserData() {
		if (this.validateData()) {
			let user = new User();
			user.loadFromJSON({
				role: 'User',
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				email: this.state.email,
				phone: this.state.phone,
				profile: {
					password: this.state.password
				}
			});

			oblax.auth.createAccount(user)
				.then((result) => {
					if (result.ok) {
						this.setState({
							edit: true
						}, () => {
							this.props.history.push('/users/allUsers');
						})
					} else {
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("USER DATA ERROR: ", result.status);
						}
					}
				})
				.catch(err => {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("USER DATA ERROR: ", err);
					}
					this.setState({
						edit: true
					})
				});
		}
	}

	updateUserData() {

		if (this.validateData()) {
			let user = new User();
			user.loadFromJSON({
				id: this.state.id,
				first_name: this.state.first_name,
				last_name: this.state.last_name,
				email: this.state.email,
				phone: this.state.phone,
				gender: this.state.gender,
				picture: this.state.picture
			});

			oblax.users.updateUserData(this.state.id, user)
				.then((result) => {
					if (result.ok) {
						this.updateUserLocation();
						this.setState({
							edit: true
						})
					} else {
						this.updateUserLocation();
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("USER DATA ERROR: ", result.status);
						}
					}
				})
				.catch(err => {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("USER DATA ERROR: ", err);
					}
					this.updateUserLocation();
					this.setState({
						edit: true
					})
				});
		}


	}

	updateUserLocation() {
		this.state.location.forEach((item) => {
			let location = new Location();
			location.loadFromJSON({
				id: item.id,
				account_id: item.account_id,
				label: item.label,
				continent: item.continent,
				country: item.country,
				state: item.state,
				zipcode: item.zipcode,
				city: item.city,
				reqgion: item.region,
				address: item.address,
				address_number: item.address_number,
				longitude: item.longitude,
				latitude: item.latitude
			});

			oblax.users.updateUserLocation(this.state.id, item.id, location)
				.then((result) => {
					if (result.ok) {
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("UPDATE USER LOCATION: ", result.status)
						}
					} else {
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("UPDATE USER LOCATION ERROR: ", result.status)
						}
					}
				})
				.catch(err => {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("UPDATE USER LOCATION ERROR: ", err)
					}
				});
		})
	}

	changeRole(id, r) {
		oblax.users.changeUserRole(id, r.toLowerCase())
			.then((result) => {
				if (result.ok) {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("UPDATE USER LOCATION: ", result.status)
					}
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("UPDATE USER LOCATION ERROR: ", result.status)
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("UPDATE USER LOCATION ERROR: ", err)
				}
			});
	}

	expandLocation(i) {
		let active = this.state.colapse === i ? null : i
		this.setState({
			colapse: active
		});
	}

	display(i) {
		return this.state.colapse === i ? 'expand' : '';
	}

	displayEdit(i) {
		return (this.state.colapse === i && !this.state.edit) ? 'edit-mode' : '';
	}

	handleSelectChange(role) {
		this.setState({
			role
		}, () => {
			this.changeRole(this.state.id, this.state.role);
		});
	}

	render() {
		const menu = (
			<Menu onClick={this.handleMenuClick}>
				<Menu.Item key="admin">Admin</Menu.Item>
				<Menu.Item key="editor">Editor</Menu.Item>
				<Menu.Item key="user">User</Menu.Item>
			</Menu>
		);

		return (
			<div id="user-details" className="user-details">
				<div className="bc-details-container">
					<div className="full-block long">
						<div className="content-block light">
							<div className="content-block-intro">
								<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
									General Information</h2>
								{/* <div className="user-details-header">
									<div className="user-header-left">
										<span className="bc-image-holder rounded large">
											<img
												src={`${process.env.REACT_APP_MINIO_GATEWAY}avatars/${this.state.picture}`}
												onError={(e) => { e.target.src = placeholder }}
											/>
										</span>
										<h2 className="content-block-title">{this.state.first_name} {this.state.last_name}</h2>
									</div>
									<div className="user-header-right">
										<DropDown
											disabled={this.state.edit}
											handler=""
											value={this.state.role}
											title="Role"
											list={USER_TYPES}
											handleSelectChange={(value) => this.handleSelectChange(value)}
											className=""
										/>
									</div>
								</div> */}
							</div>

							<div className="content-block-main">
								<div className="input-grid basics">
									<p className="input-holder half">
										<input
											value={this.state.first_name}
											// type='text'
											name='first_name'
											onChange={this.inputChange}
											// ref={node => this.userNameInput = node}
											className={"input-field--labeled"}
											id="udetails-firstname"
											disabled={this.state.edit}
											required
										/>
										<label className={"field-label"} htmlFor="udetails-firstname">First Name</label>
										<span className="input-border"></span>
									</p>
									<p className="input-holder half">
										<input
											value={this.state.last_name}
											// type='text'
											name='last_name'
											onChange={this.inputChange}
											// ref={node => this.userNameInput = node}
											className={"input-field--labeled"}
											id="udetails-lastname"
											disabled={this.state.edit}
											required
										/>
										<label className={"field-label"} htmlFor="udetails-lastname">Last Name</label>
										<span className="input-border"></span>
									</p>
									<p className="input-holder half">
										<input
											value={this.state.email}
											type='text'
											name='email'
											onChange={this.inputChange}
											// ref={node => this.userNameInput = node}
											className={"input-field--labeled"}
											id="udetails-email"
											disabled={this.state.edit}
											required
										/>
										<label className={"field-label"} htmlFor="udetails-email">E-mail</label>
										<span className="input-border"></span>
									</p>
									<div className="radio-buttons">
										<h2 className="label-title">Gender</h2>

										<p className="input-holder radio-holder">
											<input
												type="radio"
												name="gender"
												className="radio--labeled"
												checked={this.state.gender === "male" ? true : false}
												id="user-details-gender-male"
												value={this.state.gender}
												onChange={this.OnGenderChange}
												disabled={this.state.edit}
											/>
											<label className="checkbox-label" htmlFor="user-details-gender-male">Male</label>
										</p>
										<p className="input-holder radio-holder">
											<input
												type="radio"
												name="gender"
												className="radio--labeled"
												checked={this.state.gender === "female" ? true : false}
												id="user-details-gender-female"
												value={this.state.gender}
												onChange={this.OnGenderChange}
												disabled={this.state.edit}
											/>
											<label className="checkbox-label" htmlFor="user-details-gender-female">Female</label>
										</p>

										{/* <p className="input-holder half">
											<input
												value={this.state.gender}
												type='text'
												name='gender'
												onChange={this.inputChange}
												// ref={node => this.userNameInput = node}
												className={"input-field--labeled"}
												id="udetails-gender"
												disabled={this.state.edit}
												required
											/>
											<label className={"field-label"} htmlFor="udetails-gender">Gender</label>
											<span className="input-border"></span>
										</p> */}
									</div>
									<p className="input-holder half">
										<input
											value={this.state.phone}
											type='text'
											name='phone'
											onChange={this.inputChange}
											// ref={node => this.userNameInput = node}
											className={"input-field--labeled"}
											id="udetails-phone"
											disabled={this.state.edit}
											required
										/>
										<label className={"field-label"} htmlFor="udetails-phone">Phone</label>
										<span className="input-border"></span>
									</p>
									{this.state.addNewUser &&
										<p className="input-holder half">
											<input
												value={this.state.password}
												type='password'
												name='password'
												onChange={this.inputChange}
												id="udetails-password"
												className={"input-field--labeled"}
												disabled={this.state.edit}
												required
											/>
											<label className={"field-label"} htmlFor="udetails-password">Password</label>
											<span className="input-border"></span>
										</p>
									}
									{this.state.addNewUser &&
										<p className="input-holder half">
											<input
												value={this.state.passwordVerify}
												type='password'
												name='passwordVerify'
												onChange={this.inputChange}
												id="udetails-verify-password"
												className={"input-field--labeled"}
												disabled={this.state.edit}
												required
											/>
											<label className={"field-label"} htmlFor="udetails-verify-password">Verify Password</label>
											<span className="input-border"></span>
										</p>
									}
								</div>
								{!this.state.addNewUser &&
									<div className="content-block-intro general-information">
										<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
											Location</h2>
									</div>
								}
								{!this.state.addNewUser &&
									<div className="input-grid mapped">
										{this.state.location.map((item, i) =>
											<div className={"input-grid-mapped " + this.display(i)} key={item.id}>

												<div onClick={() => this.expandLocation(i)} className={"collapsible-trigger " + this.displayEdit(i)}>
													<h2>{item.label !== "DEFAULT_LOCATION" ? item.address : "Default Location"}</h2>
												</div>
												<div className={"collapsible-content "}>
													<p className="input-holder half">
														<input
															value={item.address}
															type='text'
															name='address'
															onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
															ref={node => this.userNameInput = node}
															className={"input-field--labeled"}
															id="udetails-address"
															disabled={this.state.edit}
															required
														/>
														<label className={"field-label"} htmlFor="udetails-address">Address</label>
														<span className="input-border"></span>
													</p>
													<p className="input-holder half">
														<input
															value={item.city}
															type='text'
															name='city'
															onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
															ref={node => this.userNameInput = node}
															className={"input-field--labeled"}
															id="udetails-city"
															disabled={this.state.edit}
															required
														/>
														<label className={"field-label"} htmlFor="udetails-city">City</label>
														<span className="input-border"></span>
													</p>
													<p className="input-holder half">
														<input
															value={item.continent}
															type='text'
															name='city'
															onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
															ref={node => this.userNameInput = node}
															className={"input-field--labeled"}
															id="udetails-continent"
															disabled={this.state.edit}
															required
														/>
														<label className={"field-label"} htmlFor="udetails-continent">Continent</label>
														<span className="input-border"></span>
													</p>
													<p className="input-holder half">
														<input
															value={item.country}
															type='text'
															name='country'
															onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
															ref={node => this.userNameInput = node}
															className={"input-field--labeled"}
															id="udetails-continent"
															disabled={this.state.edit}
															required
														/>
														<label className={"field-label"} htmlFor="udetails-continent">Country</label>
														<span className="input-border"></span>
													</p>
													<p className="input-holder half">
														<input
															value={item.label}
															type='text'
															name='label'
															onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
															ref={node => this.userNameInput = node}
															className={"input-field--labeled"}
															id="udetails-label"
															disabled={this.state.edit}
															required
														/>
														<label className={"field-label"} htmlFor="udetails-label">Label</label>
														<span className="input-border"></span>
													</p>
													<div className="input-holder-inside">
														<p className="input-holder half">
															<input
																value={item.latitude}
																type='text'
																name='latitude'
																onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
																ref={node => this.userNameInput = node}
																className={"input-field--labeled"}
																id="udetails-latitude"
																disabled={this.state.edit}
																required
															/>
															<label className={"field-label"} htmlFor="udetails-latitude">Latitude</label>
															<span className="input-border"></span>
														</p>
														<p className="input-holder half">
															<input
																value={item.longitude}
																type='text'
																name='longitude'
																onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
																ref={node => this.userNameInput = node}
																className={"input-field--labeled"}
																id="udetails-longitude"
																disabled={this.state.edit}
																required
															/>
															<label className={"field-label"} htmlFor="udetails-longitude">Longitude</label>
															<span className="input-border"></span>
														</p>
													</div>
													<p className="input-holder half">
														<input
															value={item.region}
															type='text'
															name='region'
															onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
															ref={node => this.userNameInput = node}
															className={"input-field--labeled"}
															id="udetails-region"
															disabled={this.state.edit}
															required
														/>
														<label className={"field-label"} htmlFor="udetails-region">Region</label>
														<span className="input-border"></span>
													</p>
													<p className="input-holder half">
														<input
															value={item.zipcode}
															type='text'
															name='zipcode'
															onChange={(e) => this.inputLocationChange(item.id, { [e.target.name]: e.target.value })}
															ref={node => this.userNameInput = node}
															className={"input-field--labeled"}
															id="udetails-zipcode"
															disabled={this.state.edit}
															required
														/>
														<label className={"field-label"} htmlFor="udetails-zipcode">Zipcode</label>
														<span className="input-border"></span>
													</p>
												</div>
											</div>

										)}
									</div>
								}
							</div>
						</div>
					</div>
				</div>

				<div className="action-buttons">
					{this.state.addNewUser ?
						<div className="action-buttons">
							<button className="rounded-button primary primary-icon gradient blue create-button" onClick={this.saveUserData}><span className="button-text">Create</span></button>
						</div>
						:
						<div className="action-buttons">
							<button className="rounded-button primary primary-icon gradient blue save-button" onClick={this.editChange}><span className="button-text">Edit</span></button>
							<button className="rounded-button primary primary-icon gradient orange cancel-button" onClick={this.editChange}><span className="button-text">Cancel</span></button>
						</div>
					}
					{/* {this.state.edit ?
						<button className="rounded-button primary primary-icon gradient pink save-button" onClick={this.editChange}><span className="button-text">Edit</span></button>
						:
						<button className="rounded-button primary primary-icon gradient pink cancel-button" onClick={this.editChange}><span className="button-text">Cancel</span></button>
					}
					{!this.state.edit &&
						<button className="rounded-button primary primary-icon gradient blue save-button" onClick={this.updateUserData}><span className="button-text">Save</span></button>
					} */}
				</div>
			</div>
		);
	}
}

// const mapStateToProps = (state) => {
//     return {
//         user: state.user.user
//     };
// }

const mapDispatchToProps = (dispatch) => {
	return {
		navName: (data) => {
			dispatch(navName(data));
		},
	};
}

// export default Main;
export default withRouter(connect(null, mapDispatchToProps)(UserDetails));