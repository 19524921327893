import React, { Component } from 'react';
import { connect } from "react-redux";
import { Input, Button } from 'antd';
import oblax from "../../../assets/oblax";
import {
	Company
} from "@oblax/sdk-js";
import { navName } from "../../Home/actions";
import { COMPANY_TYPES } from "../../../assets/constants";
import { ButtonDropDown, Toast, ConfirmAlert, DropDown } from "../../../parts";
import { validateData, getCountryName } from "../../../assets/helper";
import { setSelectedCompany } from "../actions";
import { UTILITIES } from "../../../assets/constants";

import "../css/CompanyDetails.css";

class CompanyDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			_errors: [],
			address: "",
			city: "",
			country: "",
			email: "",
			name: "",
			short_name: "",
			zipcode: "",
			tags: [],
			phone: "",

			alert: {
				type: '',
				text: '',
				triger: false
			},

			addNewCompanie: false,
			edit: true,

			deleteAlert: false,
			deleteCompanyId: ''
		}

		// EDIT
		this.enableEdit = this.enableEdit.bind(this);
		this.inputChange = this.inputChange.bind(this);
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleTagClose = this.handleTagClose.bind(this);
		this.activateAlert = this.activateAlert.bind(this);
		this.handleCountryChange = this.handleCountryChange.bind(this);

		// SAVE AND UPDATE
		this.saveCompany = this.saveCompany.bind(this);
		this.updateCompany = this.updateCompany.bind(this);

		// DELETE COMPANY
		this.deleteAlert = this.deleteAlert.bind(this);
		this.deleteCompany = this.deleteCompany.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);

	}

	componentWillMount() {
		var url = window.location.href.split('companies/');
		if (url[1] === "new") {
			this.setState({
				addNewCompanie: true,
				edit: false
			})
		} else {
			var id = url[1];
			oblax.companies.getCompanyById(id)
				.then((result) => {
					if (result.ok) {
						this.setState({
							address: result.data.address,
							city: result.data.city,
							country: result.data.country,
							email: result.data.email,
							id: result.data.id,
							name: result.data.name,
							short_name: result.data.short_name,
							zipcode: result.data.zipcode,
							phone: result.data.phone,
							tags: result.data.tags
						}, () => {
							let company = {
								address: result.data.address,
								city: result.data.city,
								country: result.data.country,
								email: result.data.email,
								id: result.data.id,
								name: result.data.name,
								short_name: result.data.short_name,
								zipcode: result.data.zipcode,
								phone: result.data.phone,
								tags: result.data.tags
							}
							this.props.setSelectedCompany(company);
							this.props.navName(this.state.name);
						});
					} else {
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("GET COMPANY BY ID ERROR");
						}
						this.props.history.push('/e-shop/companies');
					}
				})
				.catch(err => {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("GET COMPANY BY ID ERROR: ", err);
					}
					this.props.history.push('/e-shop/companies');
				});
		}
	}

	// EDIT
	// =========================
	enableEdit() {
		this.setState({
			edit: !this.state.edit
		})
	}

	inputChange({ target }) {
		this.setState({
			[target.name]: target.value
		})
	}
	// =========================

	// VALIDATE DATA
	// =========================
	validateInputData(flag) {
		var data = {
			address: this.state.address,
			city: this.state.city,
			country: this.state.country,
			email: this.state.email,
			id: this.state.id,
			name: this.state.name,
			short_name: this.state.short_name,
			zipcode: this.state.zipcode,
			phone: this.state.phone,
			tags: this.state.tags
		}
		const _errors = []
		const _exeption = ["email", "phone"];
		validateData(data, _errors, _exeption)
		this.setState({
			_errors: _errors
		}, () => {
			if (process.env.REACT_APP_ENV !== 'prod') {
				console.log("VALIDATION HELPER: ", this.state._errors);
			}
		});

		if (flag === "update") {
			if ((JSON.stringify(this.props.selectedCompany) !== JSON.stringify(data)) && _errors.length === 0) {
				this.updateCompany();
			}
		}

		if (flag === "save") {
			if (_errors.length === 0) {
				this.saveCompany();
			}
		}
	}
	// =========================

	// SAVE AND UPDATE
	// =========================
	saveCompany() {
		var company = new Company();
		company.loadFromJSON({
			name: this.state.name,
			short_name: this.state.short_name,
			country: this.state.country,
			city: this.state.city,
			zipcode: this.state.zipcode,
			address: this.state.address,
			email: this.state.email,
			phone: this.state.phone,
			tags: this.state.tags
		});

		oblax.companies.insertCompany(company)
			.then((result) => {
				if (result.ok) {
					this.setState({
						edit: true
					}, () => {
						this.props.navName(this.state.name);
						this.props.history.push('/e-shop/companies');
						this.activateAlert("success", "You have successfully created a company.");
					})
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("COMPANY ERROR: ", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("COMPANY ERROR: ", err);
				}
			});
	}

	updateCompany() {
		var company = new Company();
		company.loadFromJSON({
			name: this.state.name,
			short_name: this.state.short_name,
			country: this.state.country,
			city: this.state.city,
			zipcode: this.state.zipcode,
			address: this.state.address,
			email: this.state.email,
			phone: this.state.phone,
			tags: this.state.tags,
		});

		oblax.companies.updateCompany(this.state.id, company)
			.then((result) => {
				if (result.ok) {
					this.setState({
						edit: true,
					}, () => {
						let company = {
							address: result.data.address,
							city: result.data.city,
							country: result.data.country,
							email: result.data.email,
							id: result.data.id,
							name: result.data.name,
							short_name: result.data.short_name,
							zipcode: result.data.zipcode,
							phone: result.data.phone,
							tags: result.data.tags
						}
						this.props.navName(this.state.name);
						this.props.setSelectedCompany(company);
						this.props.navName(this.state.name);
						this.activateAlert("success", "You have successfully edited a company.");
					})
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("COMPANY ERROR: ", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("COMPANY ERROR: ", err);
				}
			});
	}
	// =========================

	handleSelectChange(value) {
		let tags = this.state.tags;
		if (value && tags.indexOf(value) === -1) {
			tags = [...tags, value];
		}
		this.setState({
			tags
		});
	}


	handleTagClose(removeTags) {
		const tags = this.state.tags.filter(item => item !== removeTags);
		this.setState({
			tags
		});
	}

	// Activation of alert box on user action
	activateAlert(type, text) {
		this.setState({
			alert: {
				type: type,
				text: text,
				triger: true
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					alert: {
						type: "",
						text: "",
						triger: false
					}
				})
			}, UTILITIES.alertTime);
		});
	}

	// Show alert after user wants to delete Company
	deleteAlert(id) {
		this.setState({
			deleteAlert: true,
			deleteCompanyId: id
		});
	}

	// Deleting a company
	deleteCompany() {
		oblax.companies.deleteCompany(this.state.deleteCompanyId)
			.then((result) => {
				if (result.ok) {
					this.setState({
						deleteAlert: false,
						deleteCompanyId: ''
					}, () => {
						this.props.history.push("/e-shop/companies");
					});
				} else {
					this.setState({
						deleteAlert: false,
						deleteCompanyId: ''
					});
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("companies ERROR", result.state);
					}
				}
			})
			.catch(err => {
				this.setState({
					deleteAlert: false,
					deleteCompanyId: ''
				});
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("companies ERROR: ", err);
				}
			});
	}

	// If user decides to cancel removal of company, bring him back
	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			deleteCompanyId: ''
		})
	}

	handleCountryChange(value) {
		this.setState({
			country: value
		})
	}

	render() {
		return (
			<div id="company-details">
				{this.state.alert.triger &&
					<Toast type={this.state.alert.type} description={this.state.alert.text} />
				}
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Company"
						message="You are attempting to delete a company from the database. Are you sure you want to proceed?"
						handleMainFunction={this.deleteCompany}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}
				<div className="bc-details-container">
					<div className="full-block long">
						<div className="content-block light">
							<div className="content-block-intro">
								<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
									Company Details</h2>
							</div>

							<div className="content-block-main">
								<p className="input-holder full company-name">
									<input
										className="input-field--labeled"
										id="company-name"
										name="name"
										onChange={this.inputChange}
										value={this.state.name}
										disabled={this.state.edit}
										required
									/>
									<label className="field-label" htmlFor="company-name">Name</label>
									<span className="input-border"></span>
								</p>
								<div className="input-grid">
									<p className="input-holder half company-shortname">
										<input
											className="input-field--labeled"
											id="company-shortname"
											name="short_name"
											onChange={this.inputChange}
											value={this.state.short_name}
											disabled={this.state.edit}
											required
										/>
										<label className="field-label" htmlFor="company-shortname">Short Name</label>
										<span className="input-border"></span>
									</p>
									<p className="input-holder half company-email">
										<input
											className="input-field--labeled"
											id="company-email"
											name="email"
											onChange={this.inputChange}
											value={this.state.email}
											disabled={this.state.edit}
											required
										/>
										<label className="field-label" htmlFor="company-email">E-mail</label>
										<span className="input-border"></span>
									</p>
									{/* <p className="input-holder half company-email">
										<input
											className="input-field--labeled"
											id="company-country"
											name="country"
											onChange={this.inputChange}
											value={this.state.country}
											disabled={this.state.edit}
											required
										/>
										<label className="field-label" htmlFor="company-country">Country</label>
										<span className="input-border"></span>
									</p> */}
									<DropDown
										disabled={this.state.edit}
										title="Country"
										handler="name"
										list={this.props.countries}
										handleSelectChange={(value) => this.handleCountryChange(value)}
										value={getCountryName(this.props.countries, this.state.country)}
										errorMessage="Country is required"
										error={this.state._errors.includes("country_of_origin")}
										className=""
									/>
									<p className="input-holder half company-city">
										<input
											className="input-field--labeled"
											id="company-city"
											name="city"
											onChange={this.inputChange}
											value={this.state.city}
											disabled={this.state.edit}
											required
										/>
										<label className="field-label" htmlFor="company-city">City</label>
										<span className="input-border"></span>
									</p>
									<p className="input-holder half company-address">
										<input
											className="input-field--labeled"
											id="company-address"
											name="address"
											onChange={this.inputChange}
											value={this.state.address}
											disabled={this.state.edit}
											required
										/>
										<label className="field-label" htmlFor="company-address">Address</label>
										<span className="input-border"></span>
									</p>
									<p className="input-holder half company-zipcode">
										<input
											className="input-field--labeled"
											id="company-zipcode"
											name="zipcode"
											onChange={this.inputChange}
											value={this.state.zipcode}
											disabled={this.state.edit}
											required
										/>
										<label className="field-label" htmlFor="company-zipcode">Zipcode</label>
										<span className="input-border"></span>
									</p>
									<p className="input-holder half company-phone">
										<input
											className="input-field--labeled"
											id="company-phone"
											name="phone"
											onChange={this.inputChange}
											value={this.state.phone}
											disabled={this.state.edit}
											required
										/>
										<label className="field-label" htmlFor="company-phone">Phone</label>
										<span className="input-border"></span>
									</p>
								</div>

								<p className="rounded-data-holder badges light">
									<span className="data-title">Tags</span>
									<span className="data-line">
										{this.state.tags.map(tag =>
											<span className={"single-data-item " + (this.state.edit ? "disabled-tags" : "")} key={tag}>{tag}<button disabled={this.state.edit} className="remove-button" onClick={() => this.handleTagClose(tag)}></button></span>
										)}

										<ButtonDropDown
											handler=""
											value=""
											title="Add new tag"
											list={COMPANY_TYPES}
											handleSelectChange={(tag) => this.handleSelectChange(tag)}
											disabled={!this.state.edit}
										/>
									</span>
								</p>
							</div>
						</div>
					</div>
				</div>
				{/* <div>
					{!this.state.edit &&
						<div>
							{this.state.addNewCompanie ?
								<Button onClick={() => this.validateInputData("save")}>Save</Button>
								:
								<Button onClick={() => this.validateInputData("update")}>Update</Button>
							}
						</div>
					}
				</div> */}

				{!this.state.addNewCompanie ?
					<div className="action-buttons">
						{this.state.edit ? <button className="rounded-button primary primary-icon gradient blue save-button" onClick={this.enableEdit}>
							<span className="button-text" >Edit</span></button> : null}

						{!this.state.edit ? <button className="rounded-button primary primary-icon gradient pink save-button" onClick={() => this.validateInputData("update")}>
							<span className="button-text" >Update</span> </button> : null}

						{!this.state.edit && <button className="rounded-button primary primary-icon gradient darkblue delete-button" onClick={() => this.deleteAlert(this.props.selectedCompany.id)}>
							<span className="button-text">Delete</span> </button>}
					</div>
					:
					<div className="action-buttons">
						<button className="rounded-button primary primary-icon gradient blue create-button" onClick={this.saveCompany}>
							<span className="button-text" >Create</span></button>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		selectedCompany: state.company.selectedCompany,
		alert: state.product.alert,
		countries: state.home.countries
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		navName: (data) => {
			dispatch(navName(data));
		},
		setSelectedCompany: (data) => {
			dispatch(setSelectedCompany(data));
		}
	};
}


export default connect(mapStateToProps, mapDispatchToProps)(CompanyDetails);