export function setSelectedUser(data) {
    return {
        type: 'SET_SELECTED_USER',
        payload: data
    }
}

export function setFilterData(data) {
    return {
        type: 'SET_FILTER_DATA_USER',
        payload: data
    }
}