export const EXP_MESUREMENTS = [
        "DAYS",
        "MONTHS",
        "YEARS"
    ];
    
export const FOOD_UNITS = [
        "L",
        "ML",
        "G",
        "KG"
    ];

export const UTILITIES = {
    "alertTime": 3000
};

export const COMPANY_TYPES = [
    'vendor',
    'manufacturer'
];

export const USER_TYPES = ["Admin", "Editor", "User"];

export const ORDER_STATUSES = [
	{
		title: "NEW",
		orderNumber: 1
	},
	{
		title: "CHARGEABLE",
		orderNumber: 2
	},
	{
		title: "QUEUED",
		orderNumber: 3
	},
	{
		title: "ACCEPTED",
		orderNumber: 4
	},
	{
		title: "DECLINED",
		orderNumber: 5
	},
	{
		title: "PROCESSING",
		orderNumber: 6
	},
	{
		title: "SHIPPED",
		orderNumber: 7
	},
	{
		title: "DELIVERED",
		orderNumber: 8
	},
	{
		title: "CANCELED",
		orderNumber: 9
	}
]

export const STATS_PERIOD_OPTIONS = [
	{ id: "day", name: "Today" },
	{ id: "month", name: "This Month" },
	{ id: "year", name: "This Year" }
];

export const MONTHS = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"];