import React, { Component } from 'react';
import {
	Input,
	Button,
	Switch,
	Checkbox,
	Row,
	Col
} from "antd";
import oblax from "../../../assets/oblax";
import {
	MessageForm
} from "@oblax/sdk-js";
import { ArrayIsEmpty } from '../../../assets/helper';
import "../css/FormDetails.css"
import { ButtonDropDown, Toast, ConfirmAlert, DropDown } from "../../../parts";
import { UTILITIES } from "../../../assets/constants";

class FormDetails extends Component {
	constructor(props) {
		super(props);
		this.state = {
			id: "",
			form_name: '',
			permalink: '',
			email_template: '',
			email_response: false,
			notification_email: false,
			notification_slack: false,
			fields: {
				title: false,
				content: false,
				email: false,
				files: false,
				topic: false,
				name: false,
			},
			messages: [],
			singleMessage: null,
			addNewForm: false,
			edit: true,

			alert: {
				type: '',
				text: '',
				triger: false
			},

			deleteAlert: false,
			deleteFormId: ''
		}

		this.PreviousState = null;

		// EDIT
		this.enableEdit = this.enableEdit.bind(this);
		this.inputChange = this.inputChange.bind(this);
		this.switchChange = this.switchChange.bind(this);
		this.checkboxChange = this.checkboxChange.bind(this);

		// UPLOAD
		this.addFile = this.addFile.bind(this);

		// UPDATE
		this.updateForm = this.updateForm.bind(this);

		this.getFormById = this.getFormById.bind(this);
		this.getMessages = this.getMessages.bind(this);
		this.DeleteForm = this.DeleteForm.bind(this);
		this.messageDataDetails = this.messageDataDetails.bind(this);
		this.CreateForm = this.CreateForm.bind(this);
		this.DeleteFormAlert = this.DeleteFormAlert.bind(this);
		this.CancelDeleteFormAlert = this.CancelDeleteFormAlert.bind(this);
	}

	componentWillMount() {
		var url = window.location.href.split('forms/');
		if (url[1] === "new") {
			this.setState({
				addNewForm: true,
				edit: false
			})
		} else {
			this.getFormById();
		}
	}

	getFormById() {
		var url = window.location.href.split('forms/');
		var id = url[1];
		return oblax.forms.getMessageForm(id)
			.then((result) => {
				if (result.ok) {
					console.log("TCL: FormDetails -> getFormById -> result", result);
					let fields = result.data.fields;
					let state_fields = Object.keys(this.state.fields)
					for (let i in fields) {
						for (let x in state_fields) {
							if (fields[i] === state_fields[x]) {
								this.setState(
									Object.assign(this.state.fields, {
										[state_fields[x]]: true
									})
								);
							}
						}
					}
					this.setState({
						id: result.data.id,
						form_name: result.data.name,
						permalink: result.data.permalink,
						email_template: result.data.email_template,
						email_response: result.data.email_response,
						notification_email: result.data.notification_email,
						notification_slack: result.data.notification_slack,

					}, () => {
						this.getMessages();
						this.PreviousState = JSON.stringify({
							form_name: result.data.name,
							permalink: result.data.permalink,
							email_template: result.data.email_template,
							email_response: result.data.email_response,
							notification_email: result.data.email_response,
							notification_slack: result.data.notification_slack,
							fields: {
								title: this.state.fields.title,
								content: this.state.fields.content,
								email: this.state.fields.email,
								files: this.state.fields.files,
								topic: this.state.fields.topic,
								name: this.state.fields.name,
							},
						})
					});
					return true;
				} else {
					console.log("GET PRODUCT BY ID ERROR");
					this.props.history.push('/forms');
					return true;
				}
			})
			.catch(err => {
				console.log("GET PRODUCT BY ID ERROR: ", err);
				this.props.history.push('/forms');
			});
	}

	getMessages() {
		return oblax.forms.getMessages(this.state.permalink)
			.then(result => {
				if (result.ok) {
					this.setState({
						messages: result.data
					}, () => {
						console.log("MESSAGES: ", this.state.messages);
					})
				}
			})
			.catch(err => {
				console.log("ERROR MESSAGES: ", err)
			})
	}

	messageDataDetails(item) {
		this.setState({
			singleMessage: item
		})
	}

	// EDIT
	// =========================
	enableEdit() {
		this.setState({
			edit: !this.state.edit
		})
	}

	inputChange({ target }) {
		this.setState({
			[target.name]: target.value
		})
	}

	switchChange(name) {
		switch (name) {
			case 'email_response':
				this.setState({
					email_response: !this.state.email_response
				})
				break;
			case 'notification_email':
				this.setState({
					notification_email: !this.state.notification_email
				})
				break;
			case 'notification_slack':
				this.setState({
					notification_slack: !this.state.notification_slack
				})
				break;

			default:
				break;
		}
	}

	checkboxChange(e) {
		this.setState(
			Object.assign(this.state.fields, {
				[e.target.name]: !this.state.fields[e.target.name]
			})
		);
	}

	// =========================

	// VALIDATE DATA
	// =========================
	validateInputData() {
		if (this.state.name === '') {
			return false;
		}
		if (this.state.permalink === '') {
			return false;
		}
		// if (this.state.fields.length === 0) {
		// 	return false;
		// }

		return true;
	}
	// =========================

	// UPDATE
	// =========================
	updateForm() {
		let state_fields = Object.keys(this.state.fields);
		let fields = state_fields.filter(key => {
			return this.state.fields[key];
		});

		let currentState = {
			form_name: this.state.form_name,
			permalink: this.state.permalink,
			email_template: this.state.email_template,
			email_response: this.state.email_response,
			notification_email: this.state.email_response,
			notification_slack: this.state.notification_slack,
			fields: {
				title: this.state.fields.title,
				content: this.state.fields.content,
				email: this.state.fields.email,
				files: this.state.fields.files,
				topic: this.state.fields.topic,
				name: this.state.fields.name,
			},
		}

		if (this.validateInputData()) {
			var form = new MessageForm();
			form.loadFromJSON({
				name: this.state.form_name,
				permalink: this.state.permalink,
				fields: fields,
				email_response: this.state.email_response,
				email_template: this.state.email_template,
				notification_email: this.state.notification_email,
				notification_slack: this.state.notification_slack
			});
			console.log(form);
			if (this.PreviousState !== JSON.stringify(currentState)) {
				oblax.forms.updateMessageForm(this.state.id, form)
					.then((result) => {
						if (result.ok) {
							this.setState({
								edit: true
							})
						} else {
							console.log("FORM ERROR: ", result.status);
						}
					})
					.catch(err => {
						console.log("FORM ERROR: ", err);
					});
			}
		} else {
			console.log("VALIDATION ERROR");
		}
	}

	CreateForm() {
		let state_fields = Object.keys(this.state.fields);
		let fields = state_fields.filter(key => {
			return this.state.fields[key];
		});

		var form = new MessageForm();
		form.loadFromJSON({
			name: this.state.form_name,
			permalink: this.state.permalink,
			fields: fields,
			email_response: this.state.email_response,
			email_template: this.state.email_template,
			notification_email: this.state.notification_email,
			notification_slack: this.state.notification_slack
		});
		console.log(form);
		
		return oblax.forms.createMessageForm(form)
			.then((res) => {
				if (res.ok) {
					console.log("TCL: CreateForm -> res", res);
					this.props.history.push("/forms");
				}
			}).catch(err => console.error(err));
	}

	// Activation of alert box on user action
	activateAlert(type, text) {
		this.setState({
			alert: {
				type: type,
				text: text,
				triger: true
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					alert: {
						type: "",
						text: "",
						triger: false
					}
				})
			}, UTILITIES.alertTime);
		});
	}

	// Show alert after user wants to delete Company
	DeleteFormAlert(id) {
		this.setState({
			deleteAlert: true,
			deleteFormId: id
		});
	}

	// If user decides to cancel removal of company, bring him back
	CancelDeleteFormAlert() {
		this.setState({
			deleteAlert: false,
			deleteFormId: ''
		})
	}

	DeleteForm() {
		oblax.forms.deleteMessageForm(this.state.id)
			.then((result) => {
				if (result.ok) {
					this.props.history.push('/forms');
				} else {
					console.log("DELETE ERROR: ", result.status);
				}
			})
			.catch(err => {
				console.log("DELETE ERROR: ", err);
			});
	}
	// =========================


	addFile(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];

		if (!file) {
			return;
		}

		reader.onloadend = () => {
			//TODO Change cover logic
			oblax.forms.uploadFile(file)
				.then((result) => {
					if (result.ok) {
						console.log("FILE: ", result.data);
						this.setState({
							email_template: result.data.filename
						})
					}
				})
				.catch(err => {
					console.log("UPLOADING IMAGE ERROR: ", err);
				});
		}
		reader.readAsDataURL(file);
	}

	render() {

		console.log(this.state);

		return (
			<div id="form-details">
				{this.state.alert.triger &&
					<Toast type={this.state.alert.type} description={this.state.alert.text} />
				}
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Form"
						message="You are attempting to delete a Form from the database. Are you sure you want to proceed?"
						handleMainFunction={this.DeleteForm}
						handleSeconderyFunction={this.CancelDeleteFormAlert}
					/>
				}
				<div className="bc-details-container">
					<div className="full-block long form-data">
						<div className="content-block light">
							<div className="content-block-intro">
								<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
									Form Details</h2>
							</div>

							<div className="content-block-main">
								<p className="input-holder full form-name">
									<input
										className="input-field--labeled"
										id="form-name"
										name="form_name"
										onChange={this.inputChange}
										value={this.state.form_name}
										disabled={this.state.edit}
										required
									/>
									<label className="field-label" htmlFor="form-name">Name</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder full form-permalink">
									<input
										className="input-field--labeled"
										id="form-permalink"
										name="permalink"
										onChange={this.inputChange}
										value={this.state.permalink}
										disabled={this.state.edit}
										required
									/>
									<label className="field-label" htmlFor="form-permalink">Permalink</label>
									<span className="input-border"></span>
								</p>
								<h2 className="input-block-title">Form Template</h2>
								<div className="switch-grid linear">
									<p className={`switch-holder form-email-response ${this.state.edit ? 'disabled' : ''}`}>
										<label className="field-label" htmlFor="form-email-response">E-mail Response</label>
										<Switch
											checked={this.state.email_response}
											onChange={() => this.switchChange('email_response')}
											disabled={this.state.edit}
										/>
									</p>
									<p className={`switch-holder form-email-notification ${this.state.edit ? 'disabled' : ''}`}>
										<label className="field-label" htmlFor="form-email-notification">E-mail Notification</label>
										<Switch
											checked={this.state.notification_email}
											onChange={() => this.switchChange('notification_email')}
											disabled={this.state.edit}
										/>
									</p>
									<p className={`switch-holder form-slack-notification ${this.state.edit ? 'disabled' : ''}`}>
										<label className="field-label" htmlFor="form-slack-notification">Slack Notification</label>
										<Switch
											checked={this.state.notification_slack}
											onChange={() => this.switchChange('notification_slack')}
											disabled={this.state.edit}
										/>
									</p>
								</div>

								<h2 className="input-block-title">Fields</h2>
								<div className="checkbox-grid">
									<p className={`input-holder checkbox-holder ${this.state.edit ? 'disabled' : ''}`}>
										<input
											type="checkbox"
											name="title"
											className="checkbox--labeled"
											id="form-data-title"
											checked={this.state.fields.title}
											onChange={this.checkboxChange}
											disabled={this.state.edit}
										/>
										<label className="checkbox-label" htmlFor="form-data-title">Title</label>
									</p>
									<p className={`input-holder checkbox-holder ${this.state.edit ? 'disabled' : ''}`}>
										<input
											type="checkbox"
											name="content"
											className="checkbox--labeled"
											id="form-data-content"
											checked={this.state.fields.content}
											onChange={this.checkboxChange}
											disabled={this.state.edit}
										/>
										<label className="checkbox-label" htmlFor="form-data-content">Content</label>
									</p>
									<p className={`input-holder checkbox-holder ${this.state.edit ? 'disabled' : ''}`}>
										<input
											type="checkbox"
											name="email"
											className="checkbox--labeled"
											id="form-data-email"
											checked={this.state.fields.email}
											onChange={this.checkboxChange}
											disabled={this.state.edit}
										/>
										<label className="checkbox-label" htmlFor="form-data-email">E-mail</label>
									</p>
									<p className={`input-holder checkbox-holder ${this.state.edit ? 'disabled' : ''}`}>
										<input
											type="checkbox"
											name="files"
											className="checkbox--labeled"
											id="form-data-files"
											checked={this.state.fields.files}
											onChange={this.checkboxChange}
											disabled={this.state.edit}
										/>
										<label className="checkbox-label" htmlFor="form-data-files">Files</label>
									</p>
									<p className={`input-holder checkbox-holder ${this.state.edit ? 'disabled' : ''}`}>
										<input
											type="checkbox"
											name="topic"
											className="checkbox--labeled"
											id="form-data-topic"
											checked={this.state.fields.topic}
											onChange={this.checkboxChange}
											disabled={this.state.edit}
										/>
										<label className="checkbox-label" htmlFor="form-data-topic">Topic</label>
									</p>
									<p className={`input-holder checkbox-holder ${this.state.edit ? 'disabled' : ''}`}>
										<input
											type="checkbox"
											name="name"
											className="checkbox--labeled"
											id="form-data-name"
											checked={this.state.fields.name}
											onChange={this.checkboxChange}
											disabled={this.state.edit}
										/>
										<label className="checkbox-label" htmlFor="form-data-name">Name</label>
									</p>
								</div>
							</div>
						</div>

						{!this.state.addNewForm ?
							<div className="action-buttons">
								{this.state.edit ? <button className="rounded-button primary primary-icon gradient blue save-button" onClick={this.enableEdit}>
									<span className="button-text" >Edit</span></button> : null}

								{!this.state.edit ? <button className="rounded-button primary primary-icon gradient pink save-button" onClick={this.updateForm}>
									<span className="button-text" >Update</span> </button> : null}

								{!this.state.edit && <button className="rounded-button primary primary-icon gradient darkblue delete-button" onClick={() => this.DeleteFormAlert(this.state.id)}>
									<span className="button-text">Delete</span> </button>}
							</div>
							:
							<div className="action-buttons">
								<button className="rounded-button primary primary-icon gradient blue create-button" onClick={this.CreateForm}>
									<span className="button-text" >Create</span></button>
							</div>
						}

					</div>
					{
						!this.state.addNewForm && !ArrayIsEmpty(this.state.messages) ?
							<div className="full-block one messages">
								<div className="content-block">
									<div className="content-block-intro">
										<h2 className="content-block-title"> <span className="content-block-icon price"></span> Messages</h2>
									</div>

									<div className="content-block-main">
										<div className="content-block-table">
											<table className="minimal-table">
												<thead className="minimal-table-head">
													<tr>
														<th className="bc-table-head-data">Name</th>
														<th className="bc-table-head-data">Email</th>
														<th className="bc-table-head-data">Title</th>
														<th className="bc-table-head-data">Content</th>
														<th className="bc-table-head-data">Files</th>
														<th className="bc-table-head-data">Topic</th>
														<th className="bc-table-head-data">Phone</th>
														<th className="bc-table-head-data">Address</th>
													</tr>
												</thead>
												<tbody className="minimal-table-body">
													{
														this.state.messages
															.map((item, i) =>
																<tr className="user-table-row" onClick={() => this.messageDataDetails(item)} key={item.id}>
																	<td>{item.name}</td>
																	<td>{item.email}</td>
																	<td>{item.title}</td>
																	<td>{item.content}</td>
																	<td>{item.files}</td>
																	<td>{item.topic}</td>
																	<td>{item.phone}</td>
																	<td>{item.address}</td>
																</tr>
															)
													}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div> : null
					}
				</div>

				<div>
					{/* <h4>Message Details</h4> */}
					{this.state.singleMessage !== null &&
						<div>
							<span>
								<h4>Name</h4>
								<Input
									name="name"
									value={this.state.singleMessage.name}
									disabled={true}
								/>
							</span>
							<span>
								<h4>Email</h4>
								<Input
									name="email"
									value={this.state.singleMessage.email}
									disabled={true}
								/>
							</span>
							<span>
								<h4>Title</h4>
								<Input
									name="title"
									value={this.state.singleMessage.title}
									disabled={true}
								/>
							</span>
							<span>
								<h4>Content</h4>
								<Input
									name="content"
									value={this.state.singleMessage.content}
									disabled={true}
								/>
							</span>
							<span>
								<h4>Topic</h4>
								<Input
									name="topic"
									value={this.state.singleMessage.topic}
									disabled={true}
								/>
							</span>
							<span>
								<h4>Files</h4>
								<span>{this.state.singleMessage.files}</span>
							</span>
							<span>
								<h4>Phone</h4>
								<Input
									name="phone"
									value={this.state.singleMessage.phone}
									disabled={true}
								/>
							</span>
							<span>
								<h4>Address</h4>
								<Input
									name="address"
									value={this.state.singleMessage.address}
									disabled={true}
								/>
							</span>
						</div>
					}
				</div>
			</div>
		);
	}
}

export default FormDetails;