import { combineReducers } from "redux";
import app from "../components/App/reducers";
import home from "../components/Home/reducers";
import order from "../components/Orders/reducers";
import form from "../components/Forms/reducers";
import page from "../components/Pages/reducers";
import user from "../components/Users/reducers";
import product from "../components/Products/reducers";
import company from "../components/Companies/reducers";
import warehouse from "../components/Warehouse/reducers";

export default combineReducers({
    app,
    home,
    order,
    form,
    page,
    user,
    product,
    company,
    warehouse
});