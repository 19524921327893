import React, { Component } from "react";
import { connect } from "react-redux";
import "../css/Warehouse.css";
import oblax from "../../../assets/oblax";
import { Receiving } from "@oblax/sdk-js";
import { DropDown, Loader, Toast, EmptyStateDisclaimer, ConfirmAlert } from "../../../parts";
import { setNewReceivings, setDfaultReceivers, setFilterData, setAlertType } from "../actions";
import { UTILITIES } from "../../../assets/constants";
import { formatDate, ArrayIsEmpty, isEmpty } from "../../../assets/helper";
import placeholder from "../../../assets/images/avatar.jpg";
import Datetime from "react-datetime";

class Warehouse extends Component {
	constructor() {
		super();
		this.state = {
			receivings: [],
			new_receivings: [],
			vendor_id: "",
			vendor_name: "",
			loader: true,
			alert: false,
			productSettings: '',

			users: [],
			usersMap: {},

			vendors: [],
			vendorsMap: {},

			supervisedBy: {
				avatar: "",
				name: "",
				location: ""
			},

			approvedBy: {
				avatar: "",
				name: "",
				location: ""
			}
		}

		this.getReceivings = this.getReceivings.bind(this);
		this.filterChange = this.filterChange.bind(this);
	}

	componentDidMount() {
		let filter = {
			vendor: '',
			vendor_name: '',
			supervised_by_id: '',
			supervised_by: '',
			approved_by_id: '',
			approved_by: '',
			global: 'active',
			active: false
		}

		this.setState({
			filter: filter
		}, () => {
			this.props.setFilterData(this.state.filter);
		});
		
		if (this.props.alert.text !== '') {
			this.setState({
				alert: true
			}, () => {
				setTimeout(() => {
					var alert = {
						type: '',
						text: ''
					}
					this.setState({
						alert: false
					})
					this.props.setAlertType(alert);

				}, UTILITIES.alertTime);
			})
		}

		if ((!ArrayIsEmpty(this.state.users) || Object.keys(this.state.usersMap).length)) {
			this.setState({
				loader: true
			})
		} else {
			this.setState({
				loader: false
			})
		}

		this.getReceivings();
		this.GetUsers();
		this.GenerateVendorsMap();
	}

	getReceivings() {
		return oblax.warehouse.getReceivings()
			.then((result) => {
				if (result.ok) {
					console.log("WAREHOUSE => ", result.data);
					this.setState({
						receivings: result.data,
						loader: false
					}, () => {
						console.log("GOT RECEIVINGS => ", this.state);
					})
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("WAREHOUSE DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("WAREHOUSE DATA ERROR 2: ", err)
				}
			});
	}

	GetReceivingDetails = (id) => {
		this.props.history.push(`/e-shop/warehouse/${id}`);
	}

	ifObjectExists = (id) => {
		for (var key in this.props.new_receivings) {
			if (this.props.new_receivings[key].vendor_id === id) {
				return false;
			}
		}
		return true
	}

	filterChange({ target }) {
		if (target.name === "active") {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: !prevState.filter.active
					}
				}), () => {
					if (this.state.filter.active) {
						this.props.setFilterData(this.state.filter);
					} else {
						this.setState({
							filter: {
								vendor: '',
								vendor_name: '',
								supervised_by_id: '',
								supervised_by: '',
								approved_by_id: '',
								approved_by: '',
								global: 'active',
								active: false
							}
						}, () => {
							this.props.setFilterData(this.state.filter);
						})

					}
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: target.value
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	filterChangeMain = (data) => {
		console.log("filterChangeMain -> data", data);
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					global: data
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	saveReceivings = () => {
		var d = new Receiving();
		for (var key in this.props.new_receivings) {
			d.loadFromJSON(this.props.new_receivings[key]);

			oblax.warehouse.insertReceiving(d)
				.then((result) => {
					if (result.ok) {
						console.log("OK")
						this.props.setDfaultReceivers()
					} else {
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("SAVE WAREHOUSE DATA ERROR", result.status);
						}
					}
				})
				.catch(err => {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("SAVE WAREHOUSE DATA ERROR 2: ", err)
					}
				});
		}
	}

	GetUsers = () => {
		oblax.users.getAll()
			.then((params) => {
				if (params.ok) {
					let usersMap = {};

					for (let i in params.data) {
						usersMap[params.data[i].id] = parseInt(i);
					}

					this.setState({
						users: params.data,
						usersMap: usersMap,
						loader: false
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log('ERROR: ', params.status)
					}
					this.setState({
						loader: true,
						users: [],
						usersMap: {}
					});
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ERROR: ", err);
				}
			});
	}

	GenerateVendorsMap = () => {
		return oblax.companies.getAll()
			.then((params) => {
				if (params.ok) {
					console.log("Warehouse -> GenerateVendorsMap -> params", params);
					let vendorsMap = {};

					for (let i in params.data) {
						vendorsMap[params.data[i].id] = parseInt(i);
					}

					this.setState({
						vendors: params.data,
						vendorsMap: vendorsMap,
						loader: false
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log('ERROR: ', params.status)
					}
					this.setState({
						loader: true,
						vendors: [],
						vendorsMap: {}
					});
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ERROR: ", err);
				}
			});
	}

	selectedSettings = (id) => {
		if (this.state.productSettings !== id) {
			this.setState({
				productSettings: id
			})
		} else {
			this.setState({
				productSettings: ''
			})
		}
	}

	closeSelectSettings = () => {
		this.setState({
			productSettings: ''
		})
	}

	DatepickerChangeHandler = (value) => {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					date: new Intl.DateTimeFormat("en-US", { year: 'numeric', day: '2-digit', month: "2-digit", hour12: false }).format(value._d)
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	HandleVendorsSelectChange = (value) => {
		if (value !== '') {
			var m = this.props.vendors.filter(item => item.id === value);
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						vendor: m[0].id,
						vendor_name: m[0].short_name
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						vendor: '',
						vendor_name: ''
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	HandleSupervisedBySelectChange = (value) => {
		if (value !== '') {
			var m = this.state.users.filter(item => item.id === value);
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						supervised_by_id: m[0].id,
						supervised_by: m[0].first_name + " " + m[0].last_name
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						supervised_by_id: '',
						supervised_by: ''
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	HandleApprovedBySelectChange = (value) => {
		if (value !== '') {
			var m = this.state.users.filter(item => item.id === value);
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						approved_by_id: m[0].id,
						approved_by: m[0].first_name + " " + m[0].last_name
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						approved_by_id: '',
						approved_by: ''
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	CreateNewWarehouseReceiving = () => {
		this.props.history.push("/e-shop/warehouse/new");
	}

	render() {
		console.log("RECIVINGS: =>", this.state.receivings);
		console.log("STATE: =>  ", this.state);

		const FilteredReceivingsList = this.state.receivings.filter(items => (
			this.props.filter.global.toLowerCase() === 'active' ?
				items.approved_by !== "" &&
				items.vendor_id && items.vendor_id.includes(this.props.filter.vendor) &&
				items.received_by && items.received_by.includes(this.props.filter.supervised_by_id) &&
				items.approved_by && items.approved_by.includes(this.props.filter.approved_by_id)
				:
				items.approved_by === "" &&
				items.vendor_id && items.vendor_id.includes(this.props.filter.vendor) &&
				items.received_by && items.received_by.includes(this.props.filter.supervised_by_id)
		));

		return (
			<div id="warehouse-table">
				{this.state.alert && <Toast type={this.props.alert.type} description={this.props.alert.text} />}
				<div className="products-table-container">
					<div className="table-filter">
						<div className="filter-row main">
							<div className="filter-item main">
								<button className="circle-button light icon filter-icon" onClick={this.filterChange} name="active"></button>
								<div className="filter-buttons">
									<ul>
										<li className={this.props.filter.global === "active" ? "active" : ""} onClick={() => this.filterChangeMain("active")}><span>Approved</span></li>
										<li className={this.props.filter.global === "pending" ? "active" : ""} onClick={() => this.filterChangeMain("pending")}><span>Pending</span></li>
									</ul>
								</div>
							</div>
							<div className="filter-item">
								<button className="rounded-button primary primary-icon gradient orange new-product-button" onClick={this.CreateNewWarehouseReceiving}><span className="button-text">Create New</span></button>
							</div>
						</div>
						{this.props.filter.active &&
							<div className="filter-row detail">
								<DropDown
									value={this.props.filter.vendor_name}
									handler="short_name"
									title="Vendor"
									list={this.props.vendors}
									handleSelectChange={(value) => this.HandleVendorsSelectChange(value)}
									className=""
								/>
								<DropDown
									value={this.props.filter.supervised_by}
									handler="first_name"
									title="Supervised By"
									list={this.state.users}
									handleSelectChange={(value) => this.HandleSupervisedBySelectChange(value)}
									className=""
								/>
								<DropDown
									value={this.props.filter.approved_by}
									handler="first_name"
									title="Approved By"
									list={this.state.users}
									handleSelectChange={(value) => this.HandleApprovedBySelectChange(value)}
									className=""
								/>
							</div>
						}
					</div>

					<div className="products-table-wrap">
						<table className="bc-table">
							<thead className="bc-table-head">
								<tr>
									<th>Import ID</th>
									<th>Vendor</th>
									<th>Import Date</th>
									<th>Status</th>
									<th>No. Products</th>
									<th>Price Total</th>
									<th>Supervised By</th>
									<th>Approved By</th>
									<th className="pusher">pusher</th>
								</tr>
							</thead>
							<tbody className="bc-table-body">
								{this.state.loader &&
									(!ArrayIsEmpty(this.state.users) || Object.keys(this.state.usersMap).length) &&
									(!ArrayIsEmpty(this.state.vendors) || Object.keys(this.state.vendorsMap).length) ?
									<tr>
										<td>
											<Loader />
										</td>
									</tr>
									: FilteredReceivingsList.length > 0 ?
										FilteredReceivingsList
											.sort((a, b) => { return new Date(a._created) > new Date(b._created) ? -1 : 1 })
											.map(item => {
												return (
													<tr className="bc-table-row" key={item.id}>
														<td className="bc-table-data" onClick={() => this.GetReceivingDetails(item.id)}>
															<h2 className="table-classic-data">{item.id}</h2>
														</td>
														<td className="bc-table-data" onClick={this.closeSelectSettings}>{
															this.state.vendors[this.state.vendorsMap[item.vendor_id]] !== undefined ?
																<h2 className="table-classic-data">{this.state.vendors[this.state.vendorsMap[item.vendor_id]].short_name}</h2>
																: <span className="empty-cell"></span>
														}
														</td>
														<td className="td-centered bc-table-data" onClick={this.closeSelectSettings}>
															<h2 className="table-classic-data">{formatDate(item._created).toString()}</h2>
														</td>
														<td className={`bc-table-data status ${item.status.toLowerCase()}`} onClick={this.closeSelectSettings}>
															<h2 className="table-classic-data">{item.status}</h2>
														</td>
														<td className="bc-table-data" onClick={this.closeSelectSettings}>
															<h2 className="table-classic-data">{item.products_count}</h2>
														</td>
														<td className="bc-table-data" onClick={this.closeSelectSettings}>
															<h2 className="table-classic-data">{Math.trunc(item.total_price)}</h2>
														</td>
														<td className="bc-table-data" onClick={this.closeSelectSettings}>
															{
																this.state.users[this.state.usersMap[item.received_by]] !== undefined ?
																	<span className="bc-table-avatar">
																		<span className="avatar-hodler">
																			<img src={`${process.env.REACT_APP_MINIO_GATEWAY}avatars/${this.state.users[this.state.usersMap[item.received_by]].picture}`} onError={(e) => { e.target.src = placeholder }} />
																		</span>
																		<span className="name-holder">
																			<h2>{this.state.users[this.state.usersMap[item.received_by]].first_name} {this.state.users[this.state.usersMap[item.received_by]].last_name}</h2>
																			<h4>Skopje</h4>
																		</span>
																	</span> : <span className="empty-cell"></span>
															}
														</td>
														<td className="bc-table-data" onClick={this.closeSelectSettings}>
															{
																this.state.users[this.state.usersMap[item.approved_by]] !== undefined ?
																	<span className="bc-table-avatar">
																		<span className="avatar-hodler">
																			<img src={`${process.env.REACT_APP_MINIO_GATEWAY}avatars/${this.state.users[this.state.usersMap[item.approved_by]].picture}`} onError={(e) => { e.target.src = placeholder }} />
																		</span>
																		<span className="name-holder">
																			<h2>{this.state.users[this.state.usersMap[item.approved_by]].first_name} {this.state.users[this.state.usersMap[item.approved_by]].last_name}</h2>
																			<h4>Skopje</h4>
																		</span>
																	</span> : <span className="empty-cell"></span>
															}
														</td>
														<td className="bc-table-data">
															<button className="square-button light icon settings-icon" onClick={() => this.selectedSettings(item.id)}></button>
															<div id="table-settings-dropdown" className={this.state.productSettings === item.id ? "opened" : ""}>
																<ul className="dropdown-list">
																	<li>
																		<a onClick={() => this.GetReceivingDetails(item.id)}>
																			<span className="dropdown-icon edit"></span>
																			<h2 className="dropdown-title">Edit</h2>
																		</a>
																	</li>
																</ul>
															</div>
														</td>
													</tr>
												)
											}
											) :
										<tr>
											<td colSpan="8">
												<EmptyStateDisclaimer
													message="No such info on our shuttle"
													icon="rocket"
												/>
											</td>
										</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		vendors: state.company.companies,
		vendors: state.company.companies.filter(item => item.tags.includes('vendor')),
		new_receivings: state.warehouse.newReceivings,
		filter: state.warehouse.filter,
		alert: state.product.alert
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAlertType: (data) => {
			dispatch(setAlertType(data))
		},
		setNewReceivings: (data) => {
			dispatch(setNewReceivings(data))
		},
		setDfaultReceivers: (data) => {
			dispatch(setDfaultReceivers(data))
		},
		setFilterData: (data) => {
			dispatch(setFilterData(data))
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Warehouse);