const initialState = {
    pages: []
};

const page = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PAGES':
            return Object.assign({}, state, {
                ...state,
                pages: action.payload
            });
        default:
            return state;
    }
}

export default page;