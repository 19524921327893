export function setOrders(data) {
    return {
        type: 'SET_ORDERS',
        payload: data
    }
}

export function setFilterData(data) {
    return {
        type: 'SET_ORDER_FILTER_DATA',
        payload: data
    }
}