import React, { Component } from "react";
import "../css/Toast.css";

class Toast extends Component {
    render () {
    	return (
        	<div id="toast" className={"toast-container " + this.props.type}>
				<span className="toast-icon"></span>
				<h2>{this.props.description}</h2>
			</div>
        )
    }
}

export default Toast;