import React, { Component } from "react";
import "../css/Loader.css";

class Loader extends Component {
    render () {
    	return (
        	<span id="loader">
				<span className="loader-centered">
					<span className="loader-animation">
						<span className="loader-rocket"></span>
						<hr className="divider" />
						<span className="loader-stars"></span>
					</span>
					<span className="loader-disclaimer">We're getting there...</span>
				</span>
			</span>
        )
    }
}

export default Loader;