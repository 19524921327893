import React, { Component } from 'react';
import oblax from '../../../../assets/oblax';

import { DropDown, ButtonDropDown } from "../../../../parts";
import { ProductTags } from "../Parts";
import { mapUnits, mapAlergens, mapPackaging, mapTimeMeasurements } from "../../../../assets/helper";

class ProductCosmeticsMeta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cosmetics_allergens: [],
            cosmetics_ingredients: [],
            cosmetics_expiration: {},
            cosmetics_measurement: {},
            cosmetics_gross_weight_measurement: {},
            cosmetics_packaging: '',

            cosmetics_unit: [],
            cosmetics_allergens_list: [],
            cosmetics_packaging_list: [],
            cosmetics_time_list: [],

            deleteAlert: false,
            removeType: ''
        }

        // Get data from api 
        this.getMeasurements = this.getMeasurements.bind(this);

        // Cosmetics Allergens
        this.handleAllergenClose = this.handleAllergenClose.bind(this);
        this.handleAllergenInputChange = this.handleAllergenInputChange.bind(this);

        // Cosmetics Expiration
        this.setExpiration = this.setExpiration.bind(this);
        this.setExpirationTime = this.setExpirationTime.bind(this);

        // Cosmetics Ingredients
        this.editIngredients = this.editIngredients.bind(this);

        // Cosmetics Measurement
        this.setMeasurementUnit = this.setMeasurementUnit.bind(this);
        this.setMeasurementValue = this.setMeasurementValue.bind(this);
        this.setGrossWeightMeasurementUnit = this.setGrossWeightMeasurementUnit.bind(this);
		this.setGrossWeightMeasurementValue = this.setGrossWeightMeasurementValue.bind(this);

        // Cosmetics Packaging
        this.setPackaging = this.setPackaging.bind(this);
    }

    componentDidMount() {
        this.getMeasurements();
        this.getProductAlergens();
        this.getProductPackaging();
        this.getTimeMeasurements();

        if (!this.isEmpty(this.props.meta)) {
            this.setState({
                cosmetics_allergens: this.props.meta.cosmetics_allergens !== undefined ? this.props.meta.cosmetics_allergens : [],
                cosmetics_expiration: this.props.meta.cosmetics_expiration !== undefined ? this.props.meta.cosmetics_expiration : {},
                cosmetics_ingredients: this.props.meta.cosmetics_ingredients !== undefined ? this.props.meta.cosmetics_ingredients : [],
                cosmetics_measurement: this.props.meta.cosmetics_measurement !== undefined ? this.props.meta.cosmetics_measurement : {},
                cosmetics_gross_weight_measurement: this.props.meta.cosmetics_gross_weight_measurement !== undefined ? this.props.meta.cosmetics_gross_weight_measurement : {},
                cosmetics_packaging: this.props.meta.cosmetics_packaging,
            })
        } else {
            this.props.setMetaData('cosmetics_allergens', []);
            this.props.setMetaData('cosmetics_expiration', {});
            this.props.setMetaData('cosmetics_ingredients', []);
            this.props.setMetaData('cosmetics_measurement', {});
            this.props.setMetaData('cosmetics_gross_weight_measurement', {});
            this.props.setMetaData('cosmetics_packaging', "");
        }
    }


    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    // Get data from api 
    // ==========================
    getMeasurements() {
        return oblax.lists.getMeasurements()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        cosmetics_unit: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("MEASUREMENTS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("MEASUREMENTS ERROR", err);
                }
            });
    }

    getProductAlergens() {
        return oblax.lists.getProductAlergens()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        cosmetics_allergens_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("ALERGENS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("ALERGENS ERROR", err);
                }
            });
    }

    getProductPackaging() {
        return oblax.lists.getProductPackaging()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        cosmetics_packaging_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("PACKAGING ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("PACKAGING ERROR", err);
                }
            });
    }

    getTimeMeasurements() {
        return oblax.lists.getTimeMeasurements()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        cosmetics_time_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("TIME MEASUREMENTS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("TIME MEASUREMENTS ERROR", err);
                }
            });
    }

    // Cosmetics Allergens
    // ==========================
    handleAllergenClose(removedAllergen) {
        const cosmetics_allergens = this.state.cosmetics_allergens.filter(item => item !== removedAllergen);
        this.setState({
            cosmetics_allergens
        }, () => {
            this.props.setMetaData('cosmetics_allergens', cosmetics_allergens);
        });
    }

    handleAllergenInputChange(value) {
        let cosmetics_allergens = this.state.cosmetics_allergens;
        if (value && cosmetics_allergens.indexOf(value) === -1) {
            cosmetics_allergens = [...cosmetics_allergens, value];
        }
        this.setState({
            cosmetics_allergens
        }, () => {
            this.props.setMetaData('cosmetics_allergens', cosmetics_allergens);
        });
    }
    // ==========================

    // Cosmetics Expiration
    // ==========================
    setExpiration(value) {
        this.setState(prevState => ({
            cosmetics_expiration: {
                ...prevState.cosmetics_expiration,
                expiration_measurement: value
            }
        }), () => {
            this.props.setMetaData('cosmetics_expiration', this.state.cosmetics_expiration);
        })
    }

    setExpirationTime({ target }) {
        this.setState(prevState => ({
            cosmetics_expiration: {
                ...prevState.cosmetics_expiration,
                expiration_time: parseInt(target.value)
            }
        }), () => {
            this.props.setMetaData('cosmetics_expiration', this.state.cosmetics_expiration);
        })
    }
    // ==========================

    // Cosmetics Ingredients
    // ==========================
    editIngredients(cosmetics_ingredients) {
        this.setState({
            cosmetics_ingredients
        }, () => {
            this.props.setMetaData('cosmetics_ingredients', this.state.cosmetics_ingredients);
        });
    }
    // ==========================

    // Cosmetics Measurement
    // ==========================
    setMeasurementUnit(value) {
        this.setState(prevState => ({
            cosmetics_measurement: {
                ...prevState.cosmetics_measurement,
                unit: value
            }
        }), () => {
            this.props.setMetaData('cosmetics_measurement', this.state.cosmetics_measurement);
        })
    }

    setGrossWeightMeasurementUnit(value) {
		this.setState(prevState => ({
			cosmetics_gross_weight_measurement: {
				...prevState.cosmetics_gross_weight_measurement,
				unit: value
			}
		}), () => {
			this.props.setMetaData('cosmetics_gross_weight_measurement', this.state.cosmetics_gross_weight_measurement);
		})
	}

    setMeasurementValue({ target }) {
        this.setState(prevState => ({
            cosmetics_measurement: {
                ...prevState.cosmetics_measurement,
                value: parseFloat(target.value)
            }
        }), () => {
            this.props.setMetaData('cosmetics_measurement', this.state.cosmetics_measurement);
        })
    }

    setGrossWeightMeasurementValue({ target }) {
		this.setState(prevState => ({
			cosmetics_gross_weight_measurement: {
				...prevState.cosmetics_gross_weight_measurement,
				value: target.value === "" ? "" : parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('cosmetics_gross_weight_measurement', this.state.cosmetics_gross_weight_measurement);
		})
	}
    // ==========================

    // Cosmetics Packaging
    // ==========================
    setPackaging(value) {
        this.setState({
            cosmetics_packaging: value
        }, () => {
            this.props.setMetaData('cosmetics_packaging', this.state.cosmetics_packaging);
        })
    }
    // ==========================

    render() {
        const {
            cosmetics_allergens,
            cosmetics_ingredients,
            cosmetics_packaging_list,
            cosmetics_unit,
            cosmetics_time_list
        } = this.state;

        return (
            <div className="content-block-main" id="product-food-metadata">
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cosmetics Expiration</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Time Measurment"
                            mapFunction={mapTimeMeasurements}
                            list={cosmetics_time_list}
                            handleSelectChange={this.setExpiration}
                            value={mapTimeMeasurements(this.props.meta !== null ? !this.isEmpty(this.props.meta.cosmetics_expiration) ? this.props.meta.cosmetics_expiration.expiration_measurement : "" : "")}
                            errorMessage="Time measurment is required"
                            error={this.props.errors.includes("cosmetics_expiration")}
                            className=""
                        />
                        <p className={"input-holder half " + (this.props.errors.includes("cosmetics_expiration") ? "error-active" : "")}>
                            <input
                                className="input-field--labeled"
                                id="food-expiration-time"
                                min="1"
                                type="number"
                                name="expiration_time"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.cosmetics_expiration.expiration_time : 0}
                                onChange={this.setExpirationTime}
                                required
                            />
                            <label className="field-label" htmlFor="food-expiration-time">Time Value</label>
                            <span className="input-error">Time value is required</span>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cosmetics Measurment</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Unit"
                            mapFunction={mapUnits}
                            list={cosmetics_unit}
                            handleSelectChange={this.setMeasurementUnit}
                            value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.cosmetics_measurement) ? this.props.meta.cosmetics_measurement.unit : "" : "")}
                            className=""
                        />
                        <p className="input-holder half">
                            <input
                                className="input-field--labeled"
                                id="food-measurement"
                                min="1"
                                type="number"
                                name="food_measurement"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.cosmetics_measurement.value : 0}
                                onChange={this.setMeasurementValue}
                                required
                            />
                            <label className="field-label" htmlFor="food-measurement">Value</label>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cosmetics Gross Weight Measurment</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Unit"
                            mapFunction={mapUnits}
                            list={cosmetics_unit}
                            handleSelectChange={this.setGrossWeightMeasurementUnit}
                            value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.cosmetics_gross_weight_measurement) ? this.props.meta.cosmetics_gross_weight_measurement.unit : "" : "")}
                            className=""
                        />
                        <p className="input-holder half">
                            <input
                                className="input-field--labeled"
                                id="cosmetics_gross_weight_measurement"
                                min="1"
                                type="number"
                                name="cosmetics_gross_weight_measurement"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.cosmetics_gross_weight_measurement.value : 0}
                                onChange={this.setGrossWeightMeasurementValue}
                                required
                            />
                            <label className="field-label" htmlFor="cosmetics_gross_weight_measurement">Value</label>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled one select">
                    <h2 className="input-block-title">Cosmetics Alergens</h2>

                    <div className="input-grid">
                        <p className="rounded-data-holder tags color orange">
                            <span className="data-title">Alergens</span>
                            <span className="data-line">
                                {cosmetics_allergens.map(allergen =>
                                    <span className="single-data-item" key={allergen}>{mapAlergens(allergen.toUpperCase())}<button className="remove-button" onClick={() => this.handleAllergenClose(allergen)}></button></span>
                                )}
                                <ButtonDropDown
                                    handler=""
                                    value=""
                                    mapFunction={mapAlergens}
                                    title="Add new alergen"
                                    list={this.state.cosmetics_allergens_list}
                                    handleSelectChange={(allergen) => this.handleAllergenInputChange(allergen)}
                                    disabled={true}
                                />
                            </span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled one select">
                    <h2 className="input-block-title">Cosmetics Ingredients</h2>

                    <div className="input-grid">
                        <p className="rounded-data-holder tags color orange">
                            <span className="data-title">Ingredients</span>
                            <ProductTags
                                tags={cosmetics_ingredients}
                                editTags={this.editIngredients}
                                title="Add new ingredient"
                            />
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cosmetics Packaging</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Packaging"
                            mapFunction={mapPackaging}
                            list={cosmetics_packaging_list}
                            handleSelectChange={this.setPackaging}
                            value={mapPackaging(!this.isEmpty(this.props.meta) ? this.props.meta.cosmetics_packaging : "select packaging")}
                            className=""
                        />
                    </div>
                </div>

            </div>
        );
    }
}

export default ProductCosmeticsMeta;