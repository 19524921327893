import React, { Component } from 'react';
import { connect } from "react-redux";
import { NavLink, Switch } from "react-router-dom";
import PrivateRoute from '../../../assets/private-route';
import oblax from "../../../assets/oblax";

/* ACTIONS */
import {
	setCurrencies,
	setBadges,
	setProductTypes,
	setCategories,
	setUserData,
	setProductCount,
	setOrderCount,
	setUsersCount,
	setWarehouseCount,
	setPagesCount,
	setFormsCount,
	setCountries
} from "../actions";
import { setProductList, setProductsMap } from '../../Products/actions'

import { setCompanies } from "../../Companies/actions";

/* COMPONENTS */
import { Header, Navigation } from "./Parts";
import { Products, ProductDetails } from "../../Products";
import { Categories } from "../../Categories";
import { Orders, OrderDetails } from "../../Orders";
import { Forms, FormDetails } from "../../Forms";
import { Warehouse, RecivingDetails } from "../../Warehouse";
import { Carts } from "../../Carts";
import { Pages, PageDetails } from "../../Pages";
import { Users, UserDetails } from "../../Users";
import { Companies, CompanyDetails } from "../../Companies";
import { Dashboard } from "../../Dashboard";
import { Delivery } from "../../Delivery";

class Home extends Component {

	constructor(props) {
		super(props);
		this.state = {
			collapsed: false
		}

		this.toggle = this.toggle.bind(this);
		this.logout = this.logout.bind(this);
	}

	componentDidMount() {
		this.getCurrencies()
			.then(this.getProductTypes())
			.then(this.getBadges())
			.then(this.getAllCategories())
			// .then(this.getVendors())
			// .then(this.getManufacturers())
			.then(this.getLoggedInUserData())
			.then(this.getProductsCount())
			.then(this.getOrdersCount())
			.then(this.getCompanies())
			.then(this.getUsersCount())
			.then(this.getWarehouseCount())
			.then(this.getPagesCount())
			.then(this.getFormsCount())
			.then(this.getCountries())
	}

	getCurrencies() {
		return oblax.lists.getCurrencies()
			.then((result) => {
				console.log(result);
				if (result.ok) {
					this.props.setCurrencies(Object.keys(result.data));
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("CURRENCIES ERROR: ", result.status)
					}
				}

			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("CURRENCIES ERROR: ", err)
				}
			});
	}

	getCountries() {
		return oblax.lists.getCountries()
			.then((result) => {
				console.log(result);
				if (result.ok) {
					this.props.setCountries(result.data);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("COUNTRIES ERROR: ", result.status)
					}
				}

			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("COUNTRIES ERROR: ", err)
				}
			});
	}

	getProductTypes() {
		return oblax.lists.getProductTypes()
			.then((result) => {
				if (result.ok) {
					this.props.setProductTypes(result.data)
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PRODUCT TYPE ERROR", result.status)
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PRODUCT TYPE ERROR: ", err)
				}
			});
	}

	getBadges() {
		return oblax.lists.getProductBadges()
			.then((result) => {
				if (result.ok) {
					this.props.setBadges(result.data);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("BADGES ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("BADGES ERROR: ", err)
				}
			});
	}

	getAllCategories() {
		return oblax.categories.getAll(true)
			.then((result) => {
				if (result.ok) {
					this.props.setCategories(result.data);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("CATEGORIES_ERROR: ", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("CATEGORIES_ERROR: ", err)
				}
			});
	}

	getCompanies() {
		return oblax.companies.getAll()
			.then((result) => {
				if (result.ok) {
					this.props.setCompanies(result.data);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("COMPANIES ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("COMPANIES ERROR: ", err)
				}
			});
	}

	getLoggedInUserData() {
		return oblax.users.getLoggedInUserData()
			.then((result) => {
				if (result.ok) {
					this.props.setUserData(result.data);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("USER DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("USER DATA ERROR 2: ", err)
				}
			});
	}

	getProductsCount() {
		return oblax.products.getAll(true)
			.then((result) => {
				if (result.ok) {
					let productsMap = {};
					for (let i in result.data) {
						productsMap[result.data[i].id] = parseInt(i);
					}
					this.props.setProductCount(result.data.length);
					this.props.setProductList(result.data);
					this.props.setProductsMap(productsMap);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PRODUCT HOME DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PRODUCT HOME DATA ERROR 2: ", err)
				}
			});
	}

	getOrdersCount() {
		return oblax.orders.getAll()
			.then((result) => {
				if (result.ok) {
					this.props.setOrderCount(result.data.length);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("ORDER HOME DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ORDER HOME DATA ERROR 2: ", err)
				}
			});
	}

	getUsersCount() {
		return oblax.users.getAll()
			.then((result) => {
				if (result.ok) {
					this.props.setUsersCount(result.data.length);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("USERS COUNT HOME DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("USERS COUNT HOME DATA ERROR 2: ", err)
				}
			});
	}

	getWarehouseCount() {
		return oblax.warehouse.getReceivings()
			.then((result) => {
				if (result.ok) {
					this.props.setWarehouseCount(result.data.length);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("USERS COUNT HOME DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("USERS COUNT HOME DATA ERROR 2: ", err)
				}
			});
	}

	getPagesCount() {
		return oblax.pages.getAll()
			.then((result) => {
				if (result.ok) {
					this.props.setPagesCount(result.data.length);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PAGES COUNT HOME DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PAGES COUNT HOME DATA ERROR 2: ", err)
				}
			});
	}

	getFormsCount() {
		return oblax.forms.getAllMessageForms()
			.then((result) => {
				if (result.ok) {
					this.props.setFormsCount(result.data.length);
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("FORM COUNT HOME DATA ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("FORM COUNT HOME DATA ERROR 2: ", err)
				}
			});
	}

	toggle() {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	}

	logout() {
		oblax.auth.adminLogout();
	}

	render() {
		if (this.props.location.pathname === "/dashboard") {
			this.props.history.push("/dashboard/general");
		}

		if (this.props.location.pathname === "/e-shop") {
			this.props.history.push("/e-shop/products");
		}

		if (this.props.location.pathname === "/e-shop/") {
			this.props.history.push("/e-shop/products");
		}

		if (this.props.location.pathname === "/users") {
			this.props.history.push("/users/allUsers")
		}
		var paths_list = [];
		var path_name = this.props.history.location.pathname.split('/').splice(1);
		let path = '';
		for (var i in path_name) {
			path = path + `/${path_name[i]}`;
			paths_list.push(path);
		}

		// const text = <span>Logout</span>;
		// const content = (
		// 	<div>
		// 		<Button onClick={this.logout}>Yes</Button>
		// 	</div>
		// );

		return (
			<div id="app">
				{/* HOME */}
				<Navigation
					history={this.props.history}
					productsCount={this.props.productsCount}
					categoriesCount={this.props.categories.length}
					companiesCount={this.props.companies.length}
					ordersCount={this.props.ordersCount}
					usersCount={this.props.usersCount}
					warehouseCount={this.props.warehouseCount}
					pagesCount={this.props.pagesCount}
					formsCount={this.props.formsCount}
				/>

				<div className="main-content">
					<Header paths={paths_list} user={this.props.user} logout={this.logout} navName={this.props.navName} />
					<div className="main-content-scrollable">
						<Switch>
							<PrivateRoute exact path="/dashboard/general" component={Dashboard} auth={this.props.auth} />
							<PrivateRoute exact path="/e-shop/products" component={Products} auth={this.props.auth} />
							<PrivateRoute path="/e-shop/products/:id" component={ProductDetails} auth={this.props.auth} />
							<PrivateRoute path="/e-shop/categories" component={Categories} auth={this.props.auth} />
							<PrivateRoute exact path="/e-shop/companies" component={Companies} auth={this.props.auth} />
							<PrivateRoute path="/e-shop/companies/:id" component={CompanyDetails} auth={this.props.auth} />
							<PrivateRoute exact path="/e-shop/orders" component={Orders} auth={this.props.auth} />
							<PrivateRoute path="/e-shop/orders/:id" component={OrderDetails} auth={this.props.auth} />
							<PrivateRoute exact path="/e-shop/carts" component={Carts} auth={this.props.auth} />
							<PrivateRoute exact path="/e-shop/warehouse" component={Warehouse} auth={this.props.auth} />
							<PrivateRoute exact path="/e-shop/warehouse/:id" component={RecivingDetails} auth={this.props.auth} />
							<PrivateRoute exact path="/forms" component={Forms} auth={this.props.auth} />
							<PrivateRoute path="/forms/:id" component={FormDetails} auth={this.props.auth} />
							<PrivateRoute exact path="/pages" component={Pages} auth={this.props.auth} />
							<PrivateRoute path="/pages/:id" component={PageDetails} auth={this.props.auth} />
							<PrivateRoute exact path="/users/allUsers" component={Users} auth={this.props.auth} />
							<PrivateRoute path="/users/allUsers/:id" component={UserDetails} auth={this.props.auth} />
							<PrivateRoute path="/e-shop/delivery" component={Delivery} auth={this.props.auth} />
						</Switch>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth: state.app.auth,
		user: state.home.user,
		productsCount: state.home.productsCount,
		ordersCount: state.home.ordersCount,
		categories: state.home.categories,
		navName: state.home.navigationName,
		companies: state.company.companies,
		usersCount: state.home.usersCount,
		warehouseCount: state.home.warehouseCount,
		pagesCount: state.home.pagesCount,
		formsCount: state.home.formsCount
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setCurrencies: (data) => {
			dispatch(setCurrencies(data));
		},
		setBadges: (data) => {
			dispatch(setBadges(data));
		},
		setProductTypes: (data) => {
			dispatch(setProductTypes(data));
		},
		setCategories: (data) => {
			dispatch(setCategories(data))
		},
		setUserData: (data) => {
			dispatch(setUserData(data))
		},
		setProductCount: (data) => {
			dispatch(setProductCount(data))
		},
		setProductList: (data) => {
			dispatch(setProductList(data));
		},
		setProductsMap: (data) => {
			dispatch(setProductsMap(data));
		},
		setOrderCount: (data) => {
			dispatch(setOrderCount(data))
		},
		setCompanies: (data) => {
			dispatch(setCompanies(data))
		},
		setUsersCount: (data) => {
			dispatch(setUsersCount(data))
		},
		setWarehouseCount: (data) => {
			dispatch(setWarehouseCount(data))
		},
		setPagesCount: (data) => {
			dispatch(setPagesCount(data))
		},
		setFormsCount: (data) => {
			dispatch(setFormsCount(data))
		},
		setCountries: (data) => {
			dispatch(setCountries(data))
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);