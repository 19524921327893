import React, { Component } from 'react';
import { connect } from "react-redux";
import Datetime from "react-datetime";
import { TreeSelect } from "antd";
import oblax from "../../../assets/oblax";
import { ProductFactory, Price, Promotion } from "@oblax/sdk-js";
import { Switch } from "antd";

// COMPONENTS
import { navName } from "../../Home/actions";
import { DropDown, ButtonDropDown, Toast } from "../../../parts";
import { convertText, getCountryName, validateData, nonrepeatableValidator, mapBadges, isString, mapProductTypes } from "../../../assets/helper";
import { ProductImages, ProductTags, ProductPrices, ProductPromotions } from "./Parts";
import { PrototypeMeta } from "./Meta";
import { UTILITIES } from "../../../assets/constants";
import { setSelectedProduct, setProductPrices, setProductPromotions, setAlertType, setProductList } from "../actions";

// STYLE
import '../../../assets/css/react-datetime.css';
import "../css/ProductDetails.css";
import 'antd/dist/antd.css';



const { TreeNode } = TreeSelect;

class ProductsDetails extends Component {
	constructor() {
		super();
		this.state = {
			_errors: [],
			addNewProduct: false,
			currency: [],
			edit: true,

			tags: [],
			images: [],
			category: [],
			manufacturer_name: "",
			manufacturer_id: "",
			vendor_name: "",
			vendor_id: "",
			price: [],
			newPrice: {
				_created: new Date(),
				currency: "MKD",
				price: 0,
				purchase_price: 0,
				tax: 0,
			},

			buy_max_limit: 0,
			promotion: [],

			name: '',
			description: '',
			detail_description: '',
			available_from: new Intl.DateTimeFormat("mk-MK", { year: 'numeric', day: '2-digit', month: "2-digit" }).format(new Date()),
			available_to: new Intl.DateTimeFormat("mk-MK", { year: 'numeric', day: '2-digit', month: "2-digit" }).format(new Date()),
			active: true,
			barcode: '',
			country_of_origin: '',
			ipid: '',
			type: '',
			meta: null,
			quantity: 1,
			warehouse_position: '',
			badges: [],

			newPromotion: {
				_created: new Date(),
				available_from: new Date(),
				available_to: new Date(),
				percentage: 0,
				price: 0
			},
			promotionValidateError: {
				message: '',
				isVisible: false
			},

			supplyDateShow: '',

			alert: {
				type: '',
				text: '',
				trigger: false
			},
			categories: []
		}

		this.getProductData = this.getProductData.bind(this);
		this.getAllProducts = this.getAllProducts.bind(this);
		this.activateAlert = this.activateAlert.bind(this);

		// TAGS
		this.editTags = this.editTags.bind(this);

		// EDIT
		this.enableEdit = this.enableEdit.bind(this);

		// IMAGES
		this.choosePrimaryImage = this.choosePrimaryImage.bind(this);
		this.addImage = this.addImage.bind(this);
		this.removeImg = this.removeImg.bind(this);

		// inputS
		this.inputChange = this.inputChange.bind(this);

		// META
		this.setMetaDataType = this.setMetaDataType.bind(this);
		this.setMetaData = this.setMetaData.bind(this);

		// QUANTITY
		// this.setQuantity = this.setQuantity.bind(this);

		// CATEGORIES, MANUFACTURER, VENDOR and BADGES
		this.handleSelectChange = this.handleSelectChange.bind(this);
		this.handleCategoriesTreeChange = this.handleCategoriesTreeChange.bind(this);

		// input NAME TAG
		this.inputProductName = this.inputProductName.bind(this);

		// SUPLY DATE
		this.selectSupplyDate = this.selectSupplyDate.bind(this);

		// SAVE, UPDATE AND DELETE
		this.saveProduct = this.saveProduct.bind(this);
		this.updateProduct = this.updateProduct.bind(this);
		this.deleteProduct = this.deleteProduct.bind(this);
		this.cancelProduct = this.cancelProduct.bind(this);

	}

	componentDidMount() {
		var url = window.location.href.split('products/');
		if (url[1] === "new") {
			this.setState({
				addNewProduct: true,
				edit: false
			}, () => {
				this.props.navName('Create Product');
			})
		} else {
			var id = url[1];
			this.getProductData(id);
		}
	}

	getPrice = () => {
		return oblax.prices.getAll(this.state.id)
			.then(result => {
				if (result.ok) {
					this.setState({
						price: result.data
					}, () => {
						this.props.setProductPrices(this.state.price)
					})
				}
			});
	}

	getPromotions = () => {
		return oblax.promotions.getAll(this.state.id)
			.then(result => {
				if (result.ok) {
					this.setState({
						promotion: result.data
					}, () => {
						this.props.setProductPromotions(this.state.promotion)
					})
				}
			})
	}

	getAllProducts() {
		return oblax.products.getAll(true)
			.then((result) => {
				if (result.ok) {
					this.props.setProductList(result.data)
				}
			})
			.catch(err => {
				console.log("TCL: ProductsDetails -> getAllProducts -> err", err)
			})
	}

	getProductData(id) {
		return oblax.products.getProductById(id, true)
			.then((result) => {
				console.log("ProductsDetails -> getProductData -> result", result);
				if (result.ok) {
					for (let i in result.data.category) {
						this.setState(state => {
							return {
								categories: [
									...state.categories,
									{
										title: result.data.category[i].name,
										value: result.data.category[i].id,
										key: result.data.category[i].id
									}
								]
							}
						}, () => {
							console.log("CATEGORIES ARR", this.state.categories);
						})
					}
					let product = {
						id: result.data.id,
						tags: result.data.tags,
						images: result.data.images !== undefined ? result.data.images : [],
						category: result.data.category,
						name: result.data.name,
						description: result.data.description,
						manufacturer_id: result.data.manufacturer_id,
						manufacturer_name: result.data.manufacturer_name,
						vendor_id: result.data.vendor_id,
						vendor_name: result.data.vendor_name,
						detail_description: result.data.detail_description,
						available_from: result.data.available_from,
						available_to: result.data.available_to,
						active: result.data.active,
						barcode: result.data.barcode,
						country_of_origin: result.data.country_of_origin,
						ipid: result.data.ipid,
						type: result.data.type,
						meta: result.data.meta,
						quantity: result.data.quantity,
						buy_max_limit: result.data.buy_max_limit,
						warehouse_position: result.data.warehouse_position,
						badges: result.data.badges !== undefined ? result.data.badges : []
					};
					this.setState({ ...product }, () => {
						this.props.setSelectedProduct(product)
						this.props.navName(this.state.name);
						this.inputProductName();
						this.getPromotions()
							.then(this.getPrice())
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("GET PRODUCT BY ID ERROR");
					}
					var alert = {
						type: "error",
						text: "There was an error in your attempt. Try again."
					}
					this.props.setAlertType(alert);
					this.props.history.push('/e-shop/products');
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("GET PRODUCT BY ID ERROR: ", err);
				}
				var alert = {
					type: "error",
					text: "There was an error in your attempt. Try again."
				}
				this.props.setAlertType(alert);
				this.props.history.push('/e-shop/products');
			});
	}

	shouldComponentUpdate(nextProps, nextState) {
		const Update = (this.state !== nextState) || (this.props !== nextProps);
		return Update;
	}

	inputChange({ target }) {
		this.setState({
			[target.name]: target.value
		})
	}

	inputProductName() {
		var t = this.state.tags;
		var d = convertText(this.state.name);
		d.forEach(item => {
			if (t.indexOf(item) === -1) t.push(item);
		})
		setTimeout(() => {
			this.setState({
				tags: t
			})
		}, 1000);
	}


	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

	// EDIT
	// =========================
	enableEdit() {
		this.setState({
			edit: !this.state.edit
		})
	}
	// =========================

	// Main Available From / Available to
	//==========================
	editChanges(flag, value) {
		this.setState({
			[flag]: value
		})
	}
	//==========================

	// META DATA CHANGE
	//==========================
	setMetaData(flag, data) {
		this.setState(prevState => ({
			meta: {
				...prevState.meta,
				[flag]: data
			}
		}))
	}

	setMetaDataType(value) {
		this.setState({
			type: value,
			meta: null
		})
	}
	//==========================

	// TAGS 
	//==========================
	editTags(tags) {

		this.setState({
			tags
		}, () => {
			if (this.isEmpty(tags)) {
				this.setState({
					tags: []
				})
			}
		});
	}
	//==========================

	// IMAGES 
	//==========================
	choosePrimaryImage(path) {
		this.setState(prevState => ({
			images: prevState.images.map(obj =>
				(obj.path === path ?
					Object.assign(obj, { cover: true })
					:
					Object.assign(obj, { cover: false })
				)
			)
		}), () => {
			if (process.env.REACT_APP_ENV !== 'prod') {
				console.log(this.state.images);
			}
		});
	}

	addImage(e) {
		e.preventDefault();
		let reader = new FileReader();
		let file = e.target.files[0];

		if (!file) {
			return;
		}

		reader.onloadend = () => {
			//TODO Change cover logic
			if (this.state.images.length > 1) {
				oblax.products.uploadImage(file)
					.then((result) => {
						if (result.ok) {
							this.setState({
								images: this.state.images.concat({
									cover: false,
									path: result.data.filename
								})
							})
						} else {
							this.activateAlert("error", "There was an error in your attempt. Try again.");
							if (process.env.REACT_APP_ENV !== 'prod') {
								console.log("UPLOADING IMAGE ERROR1: ");
							}
						}
					})
					.catch(err => {
						this.activateAlert("error", "There was an error in your attempt. Try again.");
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("UPLOADING IMAGE ERROR2: ", err);
						}
					});
			} else {
				oblax.products.uploadImage(file)
					.then((result) => {
						if (result.ok) {
							this.setState({
								images: this.state.images.concat({
									cover: true,
									path: result.data.filename
								})
							})
						} else {
							this.activateAlert("error", "There was an error in your attempt. Try again.");
							if (process.env.REACT_APP_ENV !== 'prod') {
								console.log("UPLOADING IMAGE ERROR2: ");
							}
						}
					})
					.catch(err => {
						this.activateAlert("error", "There was an error in your attempt. Try again.");
						if (process.env.REACT_APP_ENV !== 'prod') {
							console.log("UPLOADING IMAGE ERROR3: ", err);
						}
					});
			}
		}
		reader.readAsDataURL(file);
	}

	removeImg(key) {
		var array = [...this.state.images];
		array.splice(key, 1);
		this.setState({
			images: array
		});
	}

	//==========================

	// CATEGORIES, MANUFACTURER, VENDOR and BADGES
	//==========================
	handleSelectChange(flag, value) {
		switch (flag) {
			case "manufacturer":
				if (value !== '') {
					var v = this.props.manufacturers.filter(item => item.id === value);
					this.setState({
						manufacturer_id: v[0].id,
						manufacturer_name: v[0].short_name
					})
				} else {
					this.setState({
						manufacturer_id: "",
						manufacturer_name: ""
					})
				}
				break;
			case "vendor":
				if (value !== '') {
					var m = this.props.vendors.filter(item => item.id === value)
					this.setState({
						vendor_id: m[0].id,
						vendor_name: m[0].short_name
					})
				} else {
					this.setState({
						vendor_id: "",
						vendor_name: ""
					})
				}
				break;
			case "badges":
				let badges = this.state.badges;
				if (value && badges.indexOf(value) === -1) {
					badges = [...badges, value];
				}
				this.setState({
					badges
				});
				break;
			case "country":
				this.setState({
					country_of_origin: value
				})
				break;
			default:
				break;
		}
	}
	//==========================

	// PRICE
	//==========================

	handlePriceInputNumberChange = (e) => {
		let { value, name } = e.target;
		value = parseFloat(value);
		this.setState(state => ({
			newPrice: {
				...state.newPrice,
				[name]: (!Number.isNaN(value) && value > 0) ? value : 0
			}
		}));
	}

	handlePriceCurrencyChange = (value) => {
		this.setState(state => ({
			newPrice: {
				...state.newPrice,
				currency: value
			}
		}))

	}

	addNewPrice = () => {
		let { price, purchase_price, currency, tax } = this.state.newPrice;
		if (
			price <= 0 ||
			purchase_price <= 0 ||
			tax <= 0 ||
			currency === ''
		) {
			return;
		}
		this.setState(state => {
			return {
				price: [state.newPrice, ...state.price],
				newPrice: {
					_created: new Date(),
					currency: "MKD",
					price: 0,
					purchase_price: 0,
					tax: 0,
				}
			}
		});
	}

	savePrices = (product_id) => {
		if (product_id === undefined) {
			return Promise.reject("Missing product id");
		}
		let promises = [];
		for (let i in this.state.price) {
			if (this.state.price[i].id !== undefined) {
				continue;
			}
			let p = new Price();
			p.loadFromJSON({
				product_id: product_id,
				price: this.state.price[i].price,
				purchase_price: parseFloat(this.state.price[i].purchase_price),
				currency: this.state.price[i].currency,
				tax: parseFloat(this.state.price[i].tax)
			});
			let promise = oblax.prices.insertPrice(product_id, p);
			promises.push(promise);
		}
		if (promises.length === 0) {
			return Promise.resolve(true);
		}
		return Promise.all(promises)
			.then(r => Promise.resolve(r))
			.catch(e => Promise.reject(e));
	}

	deleteUnsavedPrice = (itemIndex) => {
		if (!Number.isInteger(itemIndex)) {
			return;
		};
		this.setState(state => {
			return {
				price: state.price.filter((item, i) => i !== itemIndex)
			}
		});
	}

	//==========================

	// PROMOTION
	//==========================

	// Handle price and percentage inputs
	handlePromotionInputNumberChange = (e) => {
		let { value, name } = e.target;
		value = parseFloat(value);
		this.setState(state => ({
			newPromotion: {
				...state.newPromotion,
				[name]: (!Number.isNaN(value) && value > 0) ? value : 0
			}
		}));
	}

	// Handle available_from and available_to inputs
	handlePromotionDateChange = (value, prop) => {
		if (value) {
			this.setState(state => ({
				newPromotion: {
					...state.newPromotion,
					[prop]: new Date(value)
				}
			}));
		}
		return;
	}

	savePromotions = (product_id) => {
		if (product_id === undefined) {
			return Promise.reject("Missing product id");
		}
		let promises = [];
		let { promotion } = this.state;
		for (let i in promotion) {
			if (promotion[i].id !== undefined) {
				continue;
			}
			let p = new Promotion();
			p.loadFromJSON({
				product_id: product_id,
				available_from: new Date(promotion[i].available_from),
				available_to: new Date(promotion[i].available_to),
				price: parseFloat(promotion[i].price),
				percentage: parseFloat(promotion[i].percentage)
			});
			let promise = oblax.promotions.insertPromotion(product_id, p);
			promises.push(promise);
		}
		if (promises.length === 0) {
			return Promise.resolve(true);
		}
		return Promise.all(promises)
			.then(r => Promise.resolve(r))
			.catch(e => Promise.reject(e));
	}

	addNewPromotion = () => {
		let { price, percentage, available_from, available_to } = this.state.newPromotion;
		if (
			price <= 0 ||
			percentage <= 0 ||
			!(available_from instanceof Date) ||
			!(available_to instanceof Date)
		) {
			return;
		}
		if (!this.validatePromotionDates()) {
			return;
		}
		this.setState(state => {
			return {
				promotion: [state.newPromotion, ...state.promotion],
				newPromotion: {
					_created: new Date(),
					available_from: new Date(),
					available_to: new Date(),
					percentage: 0,
					price: 0
				},
				promotionValidateError: {
					message: '',
					isVisible: false
				}
			}
		});
	}

	validatePromotionDates = () => {
		let { available_from, available_to } = this.state.newPromotion;
		// create dates without time (set the time to 00:00:00)
		available_from = new Date(available_from.getFullYear(), available_from.getMonth(), available_from.getDate());
		available_to = new Date(available_to.getFullYear(), available_to.getMonth(), available_to.getDate());
		// available_from should be <= available_to
		if (available_from > available_to) {
			this.setState({
				promotionValidateError: {
					message: 'Available from cannot be greater then available to',
					isVisible: true
				}
			});
			return false;
		}
		// validate against last inserted promotion
		if (this.state.promotion.length === 0) {
			return true;
		}
		// get the last inserted promotion
		// last inserted promotion available to. create date instance in case the date is iso string
		let liavto = new Date(this.state.promotion[0].available_to);
		liavto = new Date(liavto.getFullYear(), liavto.getMonth(), liavto.getDate());
		if (available_from <= liavto) {
			this.setState({
				promotionValidateError: {
					message: 'The new promotion cannot overlap with the previous promotion',
					isVisible: true
				}
			})
			return false;
		}
		return true;
	}

	deleteUnsavedPromotion = (itemIndex) => {
		if (!Number.isInteger(itemIndex)) {
			return;
		};
		this.setState(state => {
			return {
				promotion: state.promotion.filter((item, i) => i !== itemIndex)
			}
		});
	}
	//==========================

	// Validate Data
	//==========================
	validateInputData(data) {
		const _errors = [];
		const _exeption = ["images", "badges", "tags", "food_allergens", "food_nutrition_values", "beverage_allergens", "beverage_nutrition_values", "cosmetics_allergens", "personal_hygiene_allergens", "food_gross_weight_measurement", "beverage_gross_weight_measurement", "cosmetics_gross_weight_measurement", "cleaning_gross_weight_measurement", "personal_hygiene_gross_weight_measurement"];
		const _nonrepeatable = { data: this.props.allProducts.filter(item => item.id !== data.id), list: ["barcode"] };

		validateData(data, _errors, _exeption);
		nonrepeatableValidator(data, _errors, _nonrepeatable);
		this.setState({
			_errors: _errors
		}, () => {
			if (process.env.REACT_APP_ENV !== 'prod') {
				console.log("VALIDATION HELPER: ", this.state._errors);
			}
		});

		if (_errors.length > 0) {
			return false;
		}
		return true;
	}
	//==========================

	// UPDATE PRODUCT
	//==========================
	handleUpdateProduct = () => {
		var data = {
			id: this.state.id,
			tags: this.state.tags,
			images: this.state.images,
			category: this.state.category,
			name: this.state.name,
			description: this.state.description,
			manufacturer_id: this.state.manufacturer_id,
			manufacturer_name: this.state.manufacturer_name,
			vendor_id: this.state.vendor_id,
			vendor_name: this.state.vendor_name,
			detail_description: this.state.detail_description,
			available_from: this.state.available_from,
			available_to: this.state.available_to,
			active: this.state.active,
			barcode: this.state.barcode,
			country_of_origin: this.state.country_of_origin,
			ipid: this.state.ipid,
			type: this.state.type,
			meta: this.state.meta,
			quantity: this.state.quantity,
			buy_max_limit: this.state.buy_max_limit,
			warehouse_position: this.state.warehouse_position,
			badges: this.state.badges
		};
		let promises = [];
		// Check for changes in product data
		if (JSON.stringify(this.props.selectedProduct) !== JSON.stringify(data)) {
			if (!this.validateInputData(data)) {
				return;
			}
			let promise = this.updateProduct(data);
			promises.push(promise);
		}
		// Check for changes in prices section
		if (JSON.stringify(this.props.selectedPrices) !== JSON.stringify(this.state.price)) {
			let promise = this.savePrices(data.id)
				.then(res => {
					this.getPrice();
					return Promise.resolve(res);
				})
				.catch(err => Promise.reject(err));
			promises.push(promise);
		}
		// Check for changes in promotions section
		if (JSON.stringify(this.props.selectedPromotions) !== JSON.stringify(this.state.promotion)) {
			let promise = this.savePromotions(data.id)
				.then(res => {
					this.getPromotions();
					return Promise.resolve(res);
				})
				.catch(err => Promise.reject(err));
			promises.push(promise);
		}

		if (promises.length === 0) {
			return;
		}
		Promise.all(promises)
			.then(r => {
				this.activateAlert("success", `You have successfully updated ${this.state.name}.`);
			})
			.catch(e => {
				this.activateAlert("error", "There was an error in your attempt to Update Product. Try again.");
			});
	}

	updateProduct(data) {
		var p = ProductFactory.createProduct(data.type);
		p.loadFromJSON({
			...data,
			available_from: new Date(data.available_from),
			available_to: new Date(data.available_to),
			quantity: parseInt(data.quantity),
			buy_max_limit: parseInt(data.buy_max_limit)
		});

		return oblax.products.updateProduct(data.id, p)
			.then((result) => {
				if (!result.ok) {
					return Promise.reject(result);
				}
				this.props.setSelectedProduct(data);
				this.props.navName(data.name);
				return Promise.resolve(result);
			})
			.catch(err => Promise.reject(err));
	}
	//==========================
	// SAVE PRODUCT
	//==========================
	handleSaveProduct = () => {
		var data = {
			available_from: new Date(this.state.available_from),
			available_to: new Date(this.state.available_to),
			active: this.state.active,
			barcode: this.state.barcode,
			category: this.state.category,
			country_of_origin: this.state.country_of_origin,
			description: this.state.description,
			detail_description: this.state.detail_description,
			tags: this.state.tags,
			id: this.state.id,
			images: this.state.images,
			ipid: this.state.ipid,
			manufacturer_id: this.state.manufacturer_id,
			meta: this.state.meta,
			name: this.state.name,
			quantity: parseInt(this.state.quantity),
			buy_max_limit: parseInt(this.state.buy_max_limit),
			type: this.state.type,
			vendor_id: this.state.vendor_id,
			badges: this.state.badges,
			warehouse_position: this.state.warehouse_position
		};
		if (!this.validateInputData(data)) {
			return;
		}
		this.saveProduct(data)
			.then((res) => {
				var alert = {
					type: "success",
					text: `You have successfully created ${data.name}.`
				}
				this.props.setAlertType(alert);
				this.props.history.push('/e-shop/products');
			})
			.catch(err => {
				this.activateAlert("error", "There was an error in your attempt. Try again.");
			});
	}

	saveProduct(data) {
		var p = ProductFactory.createProduct(data.type);
		p.loadFromJSON(data);
		return oblax.products.insertProduct(p)
			.then((result) => {
				if (!result.ok) {
					return Promise.reject(result);
				}
				return Promise.all([this.savePrices(result.data.id), this.savePromotions(result.data.id)])
					.then(res => Promise.resolve(res))
					.catch(err => Promise.reject(err));
			})
			.catch(err => Promise.reject(err));
	}
	//==========================

	// Delete Product
	//==========================
	deleteProduct() {
		oblax.products.deleteProduct(this.state.id)
			.then((result) => {
				if (result.ok) {
					this.props.history.push('/e-shop/products');
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("DELETE ERROR: ", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("DELETE ERROR: ", err);
				}
			});
	}
	//==========================


	// BADGES
	// =========================
	handleBadgesClose(removedBadges) {
		const badges = this.state.badges.filter(item => item !== removedBadges);
		this.setState({
			badges
		});
	}
	//==========================

	// CANCEL
	//==========================
	cancelProduct() {
		this.props.history.push('/e-shop/products');
	}
	//==========================

	// SUPPLY DATE
	// =========================
	selectSupplyDate() {
		this.setState({
			supplyDateShow: this.state.supplyDateShow === '' ? 'opened' : ''
		})
	}
	// =========================

	activateAlert(type, text) {
		this.setState({
			alert: {
				type: type,
				text: text,
				trigger: true
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					alert: {
						type: "",
						text: "",
						trigger: false
					}
				})
			}, UTILITIES.alertTime);
		});
	}

	handleCategoriesTreeChange(value, label, extra) {
		// New category selected
		if (extra.selected) {
			let { title, value } = extra.triggerNode.props;
			this.setState((state) => {
				return { category: [...state.category, { id: value, name: title }] }
			});
		} else {
			this.setState((state) => {
				return { category: state.category.filter(cat => cat.id !== extra.triggerValue) }
			});
		}
	}

	// Sets the product as active or inactive
	HandleProductStatus() {
		this.setState({
			active: !this.state.active
		})
	}

	render() {
		const loop = data => data.sort((a, b) => { return a.order - b.order }).map((item) => {
			if (item.children && item.children.length) {
				return (
					<TreeNode value={item.id} title={item.name} key={item.id}>{loop(item.children)}</TreeNode>
				)
			}
			return <TreeNode value={item.id} title={item.name} key={item.id} />;
		});

		console.log("STATE => ", this.state);

		return (
			<div id="product-details" className="two-block-content">
				{/* ALERTS */}
				{this.state.alert.trigger &&
					<Toast type={this.state.alert.type} description={this.state.alert.text} />
				}
				<div className="bc-details-container">
					<div className="full-block two">
						<div className="content-block light">
							<div className="content-block-intro">
								<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
									General Information</h2>
							</div>
							<div className="content-block-main">
								<p className={"input-holder full name " + (this.state._errors.includes("name") ? "error-active" : "")}>
									<input
										className="input-field--labeled"
										id="product-name"
										name="name"
										onChange={this.inputChange}
										onBlur={(e) => this.inputProductName(e)}
										value={this.state.name}
										required
									/>
									<label className="field-label" htmlFor="product-name">Product Name</label>
									<span className="input-error">Name is required</span>
									<span className="input-border"></span>
								</p>
								<p className={"rounded-data-holder badges light categories-ant " + (this.state._errors.includes("category") ? "error-active" : "")}>
									{this.state._errors.includes("category") && this.isEmpty(this.state.category) ? <span className="input-error">Category is required</span> : null}
									<span className="data-line">
										<TreeSelect
											showSearch
											style={{ width: 300 }}
											dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
											placeholder="Add new category"
											multiple
											treeDefaultExpandAll
											treeNodeFilterProp='title'
											onChange={this.handleCategoriesTreeChange}
											name="category"
											value={this.state.category.map(cat => cat.id)}
										>
											{loop(this.props.categories)}
										</TreeSelect>
									</span>
								</p>

								<div className="input-grid">
									<p className={"input-holder half " + (this.state._errors.includes("description") ? "error-active" : "")}>
										<input
											className="input-field--labeled"
											id="product-description"
											name="description"
											onChange={this.inputChange}
											value={this.state.description}
											required

										/>
										<label className="field-label" htmlFor="product-description">Product Description</label>
										<span className="input-error">Description is required</span>
										<span className="input-border"></span>
									</p>
									{/* TODO SUPLY DATE */}
									{/* <p className={`select-holder half ${this.state.supplyDateShow}`}>
                                        <span onClick={this.selectSupplyDate} className="select-value-container">
                                            <span className="select-label">Supply Date</span>
                                            <span className="select-value"></span>
                                        </span>
                                        <span className="select-dropdown">
                                            <span>Tue Apr 9, 2019</span>
                                            <span>Tue Apr 12, 2019</span>
                                            <span>Tue Apr 18, 2019</span>
                                            <span>Tue Apr 22, 2019</span>
                                        </span>
                                    </p> */}
									{/* <DropDown
										disabled={false}
                                        title="Supply Date"
                                        handler="name"
                                        list={[{id:"1", name:"Tue Apr 9, 2019"}]}
                                    /> */}
									<p className={"input-holder half " + (this.state._errors.includes("barcode") ? "error-active" : "")}>
										<input
											className="input-field--labeled"
											id="product-barcode"
											name="barcode"
											onChange={this.inputChange}
											value={this.state.barcode}
											required
										/>
										<label className="field-label" htmlFor="product-barcode">Barcode</label>
										<span className="input-error">Barcode is required</span>
										<span className="input-border"></span>
									</p>
									<p className={"input-holder half " + (this.state._errors.includes("ipid") ? "error-active" : "")}>
										<input
											className="input-field--labeled"
											id="product-iid"
											name="ipid"
											onChange={this.inputChange}
											value={this.state.ipid}
											required
										/>
										<label className="field-label" htmlFor="product-iid">Internal Product ID</label>
										<span className="input-error">Internal Product ID is required</span>
										<span className="input-border"></span>
									</p>
									<p className={"input-holder half " + (this.state._errors.includes("quantity") ? "error-active" : "")}>
										<input
											className="input-field--labeled"
											id="product-in-stock-quantity"
											min="0"
											type="number"
											name="quantity"
											value={this.state.quantity}
											onChange={this.inputChange}
											required
										/>
										<label className="field-label" htmlFor="product-in-stock-quantity">In Stock Quantity</label>
										<span className="input-error">Internal Product ID is required</span>
										<span className="input-border"></span>
									</p>
								</div>

								<p className={"textarea-holder input-holder full detail-description " + (this.state._errors.includes("detail_description") ? "error-active" : "")}>
									<textarea
										className="text-field--labeled"
										id="product-detail-description"
										name="detail_description"
										onChange={this.inputChange}
										value={this.state.detail_description}
										autosize={{ minRows: 2, maxRows: 6 }}
										required
									/>
									<label className="text-field-label" htmlFor="product-detail-description">
										Detailed Description
                                    </label>
									<span className="input-error">Detailed description is required</span>
									<span className="input-border"></span>
								</p>
								<p className={"rounded-data-holder tags light " + (this.state._errors.includes("tags") && this.isEmpty(this.state.tags) ? "error-active" : "")}>
									<span className="data-title">Tags</span>
									{this.state._errors.includes("tags") && this.isEmpty(this.state.tags) ? <span className="input-error">Tags are required</span> : null}
									<ProductTags tags={this.state.tags} editTags={this.editTags} edit={false} />
								</p>
								<p className={"rounded-data-holder badges light "}>
									<span className="data-title">Badges</span>
									<span className="data-line">
										{this.state.badges !== undefined && this.state.badges.map(badge =>
											<span className="single-data-item" key={badge}>{mapBadges(badge)}<button className="remove-button" onClick={() => this.handleBadgesClose(badge)}></button></span>
										)}
										<ButtonDropDown
											handler=""
											mapFunction={mapBadges}
											value=""
											title="Add new badge"
											list={this.props.badges}
											handleSelectChange={(badge) => this.handleSelectChange('badges', badge)}
											disabled={true}
										/>
									</span>
								</p>

								<div className="input-grid select-input-grid">
									<DropDown
										disabled={false}
										title="Manufacturer"
										handler="short_name"
										list={this.props.manufacturers.filter(item => item.tags.includes('manufacturer'))}
										handleSelectChange={(value) => this.handleSelectChange('manufacturer', value)}
										value={this.state.manufacturer_name}
										errorMessage="Manufacturer is required"
										error={this.state._errors.includes("manufacturer_id") && this.state._errors.includes("manufacturer_name")}
										className=""
									/>
									<DropDown
										disabled={false}
										title="Vendor"
										handler="short_name"
										list={this.props.vendors.filter(item => item.tags.includes('vendor'))}
										handleSelectChange={(value) => this.handleSelectChange('vendor', value)}
										value={this.state.vendor_name}
										errorMessage="Vendor is required"
										error={this.state._errors.includes("vendor_id") && this.state._errors.includes("vendor_name")}
										className=""
									/>
									<p className="input-holder date-holder half">
										<Datetime
											formatDate={"DD/MM/YYYY"}
											inputProps={{
												readOnly: true,
												id: "pdetail-available-from",
												onClick: () => {
													this.PDETAIL_AVAILABLE_FROM.classList.add("active-date-label")
												},
												onBlur: (e) => {
													if (e.target.value !== "") {
														this.PDETAIL_AVAILABLE_FROM.classList.add("active-date-label");
													} else {
														this.PDETAIL_AVAILABLE_FROM.classList.remove("active-date-label")
													}
												}
											}}
											value={new Date(this.state.available_from)}
											locale={"mk"}
											timeFormat={false}
											input={true}
											disableOnClickOutside={true}
											closeOnSelect={true}
											onChange={(e, value) => {
												this.editChanges("available_from", e, value)
												if (value !== "") {
													this.PDETAIL_AVAILABLE_FROM.classList.add("active-date-label");
												} else {
													this.PDETAIL_AVAILABLE_FROM.classList.remove("active-date-label")
												}
											}}
										/>
										<label ref={e => this.PDETAIL_AVAILABLE_FROM = e} className={`field-label ${this.state.available_from !== '' && 'active-date-label'}`} htmlFor="pdetail-available-from">Available From</label>
									</p>
									<p className="input-holder date-holder half">
										<Datetime
											formatDate={"DD/MM/YYYY"}
											inputProps={{
												readOnly: true,
												id: "pdetail-available-to",
												onClick: () => {
													this.PDETAIL_AVAILABLE_TO.classList.add("active-date-label")
												},
												onBlur: (e) => {
													if (e.target.value !== "") {
														this.PDETAIL_AVAILABLE_TO.classList.add("active-date-label");
													} else {
														this.PDETAIL_AVAILABLE_TO.classList.remove("active-date-label")
													}
												}
											}}
											value={new Date(this.state.available_to)}
											locale={"mk"}
											timeFormat={false}
											input={true}
											disableOnClickOutside={true}
											closeOnSelect={true}
											onChange={(e, value) => {
												this.editChanges("available_to", e, value)
												if (value !== "") {
													this.PDETAIL_AVAILABLE_TO.classList.add("active-date-label");
												} else {
													this.PDETAIL_AVAILABLE_TO.classList.remove("active-date-label")
												}
											}}
										/>
										<label ref={e => this.PDETAIL_AVAILABLE_TO = e} className={`field-label ${this.state.available_to !== '' && 'active-date-label'}`} htmlFor="pdetail-available-to">Available To</label>
									</p>

									<DropDown
										disabled={false}
										title="Country of origin"
										handler="name"
										list={this.props.countries}
										handleSelectChange={(value) => this.handleSelectChange('country', value)}
										value={getCountryName(this.props.countries, this.state.country_of_origin)}
										errorMessage="Country is required"
										error={this.state._errors.includes("country_of_origin")}
										className=""
									/>

									<p className={`switch-holder form-product-details-active ${this.state.edit ? 'disabled' : ''}`}>
										<label className="field-label" htmlFor="form-email-response">Active Product</label>
										<Switch
											checked={this.state.active}
											onChange={() => this.HandleProductStatus()}
											disabled={false}
										/>
									</p>
									<p className={"input-holder half " + (this.state._errors.includes("buy_max_limit") ? "error-active" : "")}>
										<input
											className="input-field--labeled"
											id="product-in-stock-quantity"
											min="0"
											type="number"
											name="buy_max_limit"
											value={this.state.buy_max_limit}
											onChange={this.inputChange}
											required
										/>
										<label className="field-label" htmlFor="product-in-stock-quantity">Buy Max Limit</label>
										<span className="input-error">Buy Max Limit is required</span>
										<span className="input-border"></span>
									</p>
									<p className={"input-holder half " + (this.state._errors.includes("warehouse_position") ? "error-active" : "")}>
										<input
											className="input-field--labeled"
											id="product-warehouse-position"
											type="text"
											name="warehouse_position"
											value={this.state.warehouse_position}
											onChange={this.inputChange}
											required
										/>
										<label className="field-label" htmlFor="product-warehouse-position">Warehouse position</label>
										<span className="input-error">Warehouse position is required</span>
										<span className="input-border"></span>
									</p>
								</div>

								<div className="content-block-intro images">
									<h2 className="content-block-title">
										<span className="content-block-icon images"></span>
										Images
                                	</h2>
								</div>
								<ProductImages
									images={this.state.images}
									choosePrimaryImage={this.choosePrimaryImage}
									addImage={this.addImage}
									removeImg={this.removeImg}
								/>
							</div>
						</div>

						<div className="content-block dark metadata">
							<div className="content-block-intro">
								<h2 className="content-block-title">
									<span className="content-block-icon metadata"></span>
									Product Metadata
                            	</h2>
							</div>

							<div className="content-block-inner-intro">
								<DropDown
									disabled={false}
									title="Product Type"
									handler=""
									list={this.props.productTypes}
									handleSelectChange={(value) => this.setMetaDataType(value)}
									value={this.state.type}
									errorMessage="Metadata is required"
									error={this.state._errors.includes("type")}
									className=""
									mapFunction={mapProductTypes}
								/>
							</div>

							{/* Product Metadata Component */}
							{isString(this.state.type) && this.state.type.length > 0 &&
								<PrototypeMeta
									type={this.state.type}
									meta={this.state.meta}
									edit={this.state.edit}
									setMetaData={this.setMetaData}
									errors={this.state._errors}
								/>
							}

						</div>
					</div>
					<ProductPrices
						price={this.state.price}
						newPrice={this.state.newPrice}
						currencies={this.props.currencies}
						handlePriceInputNumberChange={this.handlePriceInputNumberChange}
						handlePriceCurrencyChange={this.handlePriceCurrencyChange}
						addNewPrice={this.addNewPrice}
						deleteUnsavedPrice={this.deleteUnsavedPrice}
					/>

					<ProductPromotions
						promotion={this.state.promotion}
						newPromotion={this.state.newPromotion}
						promotionValidateError={this.state.promotionValidateError}
						handlePromotionInputNumberChange={this.handlePromotionInputNumberChange}
						handlePromotionDateChange={this.handlePromotionDateChange}
						addNewPromotion={this.addNewPromotion}
						deleteUnsavedPromotion={this.deleteUnsavedPromotion}
					/>
				</div>


				{this.state.addNewProduct ?
					<div className="action-buttons">
						<button className="rounded-button primary primary-icon gradient blue save-button" onClick={this.handleSaveProduct}>
							<span className="button-text" >Save</span></button>
						<button className="rounded-button primary primary-icon gradient pink cancel-button" onClick={this.cancelProduct}>
							<span className="button-text" >Cancel</span> </button>
					</div>
					:
					<div className="action-buttons">
						<button className="rounded-button primary primary-icon gradient blue save-button" onClick={this.handleUpdateProduct}>
							<span className="button-text" >Update</span></button>
						<button className="rounded-button primary primary-icon gradient pink cancel-button" onClick={this.cancelProduct}>
							<span className="button-text" >Cancel</span> </button>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		manufacturers: state.company.companies,
		vendors: state.company.companies,
		categories: state.home.categories,
		currencies: state.home.currencies,
		badges: state.home.badges,
		productTypes: state.home.productTypes,
		selectedProduct: state.product.selectedProduct,
		selectedPrices: state.product.selectedPrices,
		selectedPromotions: state.product.selectedPromotions,
		alert: state.product.alert,
		allProducts: state.product.allProducts,
		countries: state.home.countries
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		navName: (data) => {
			dispatch(navName(data));
		},
		setAlertType: (data) => {
			dispatch(setAlertType(data));
		},
		setSelectedProduct: (data) => {
			dispatch(setSelectedProduct(data));
		},
		setProductPrices: (data) => {
			dispatch(setProductPrices(data));
		},
		setProductPromotions: (data) => {
			dispatch(setProductPromotions(data));
		},
		setProductList: (data) => {
			dispatch(setProductList(data))
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductsDetails);