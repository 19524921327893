const initialState = {
    user: {},
    filter: {
        name: '',
        type: '',
        email: '',
        global: 'active',
        active: false
    },
};

const order = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_USER':
            return Object.assign({}, state, {
                ...state,
                user: action.payload
            });
        case 'SET_FILTER_DATA_USER':
            return Object.assign({}, state, {
                ...state,
                filter: action.payload
            });
        default:
            return state;
    }
}

export default order;