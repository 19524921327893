export function setCompanies(data) {
    return {
        type: 'SET_COMPANIES',
        payload: data
    }
}

export function setFilterData(data) {
    return {
        type: 'SET_FILTER_COMPANY_DATA',
        payload: data
    }
}

export function setSelectedCompany(data) {
    return {
        type: 'SET_SELECTED_COMPANY',
        payload: data
    }
}

