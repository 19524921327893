import React, { Component } from "react";
import { connect } from "react-redux";
import { Card } from "../../../parts/index";
import { BoosterChart, BoosterList, BoosterChartLegend } from "../../../parts/index";
import ReactEcharts from 'echarts-for-react';
import oblax from "../../../assets/oblax";
import { ArrayIsEmpty, calculatePreviousDay, calculatePreviousMonth } from '../../../assets/helper.js';
import { STATS_PERIOD_OPTIONS, MONTHS } from "../../../assets/constants";

import "../css/Dashboard.css"

const DEFAULT_PERIOD_OPTION = STATS_PERIOD_OPTIONS[0];

class Dashboard extends Component {

	constructor(props) {
		super(props);
		this.state = {
			ordersCount: null,
			ordersAvgPrice: null,
			topSellingProducts: null,
			productsCounters: null,
			dashboardPeriod: DEFAULT_PERIOD_OPTION,
			ordersCountPeriod: DEFAULT_PERIOD_OPTION,
			ordersAvgPricePeriod: DEFAULT_PERIOD_OPTION,
			topSellingProductPeriod: DEFAULT_PERIOD_OPTION,
			currentLegendDataAverageOrder: this.generateCurrentLegendData(STATS_PERIOD_OPTIONS[0].id, new Date),
			previousLegendDataAverageOrder: this.generatePreviousLegendData(STATS_PERIOD_OPTIONS[0].id, new Date),
			currentLegendDataOrderCount: this.generateCurrentLegendData(STATS_PERIOD_OPTIONS[0].id, new Date),
			previousLegendDataOrderCount: this.generatePreviousLegendData(STATS_PERIOD_OPTIONS[0].id, new Date)
		}
	}

	componentDidMount() {
		let d = new Date();
		this.populateStatistics();
	}

	populateStatistics = () => {
		let d = new Date();
		this.getDashboardProductsCounters();
		this.getDashboardOrdersCount(DEFAULT_PERIOD_OPTION, d.getFullYear(), d.getMonth() + 1, d.getDate());
		this.getDashboardOrdersAvgPrice(DEFAULT_PERIOD_OPTION, d.getFullYear(), d.getMonth() + 1, d.getDate());
		this.getDashboardTopSellingProducts(DEFAULT_PERIOD_OPTION, d.getFullYear(), d.getMonth() + 1, d.getDate(), 10);
	}

	getDashboardProductsCounters = () => {
		oblax.stats.dashboardProductsCounters()
			.then(res => {
				if (res.ok) {
					console.log("Dashboard -> getDashboardProductsCounters -> res", res);
					this.setState({ productsCounters: res.data });
				}
			})
			.catch();
	}

	getDashboardOrdersCount = (type, year, month, day) => {
		oblax.stats.dashboardOrdersCount(type, year, month, day)
			.then(res => {
				this.setState({ ordersCount: res.data });
			})
			.catch();
	}

	getDashboardOrdersAvgPrice = (type, year, month, day) => {
		oblax.stats.dashboardOrdersAvgPrice(type, year, month, day)
			.then(res => {
				this.setState({ ordersAvgPrice: res.data });
			})
			.catch();
	}

	getDashboardTopSellingProducts = (type, year, month, day, limit) => {
		oblax.stats.dashboardTopSellingProducts(type, year, month, day, limit)
			.then(res => {
				this.setState({ topSellingProducts: res.data });
			})
			.catch();
	}

	generateCurrentLegendData = (type, date) => {
		var color = '#8e44ad';
		var title = '';
		date = date ? date : new Date();
		switch (type) {
			case 'day':
				title = `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
				break;
			case 'month':
				title = `${MONTHS[date.getMonth()]}, ${date.getFullYear()}`;
				break;
			case 'year':
				title = `${date.getFullYear()}`;
				break;
			default:
				title = `${MONTHS[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
				break;
		}
		return { color, title };
	};

	generatePreviousLegendData = (type, date) => {
		var color = '#CAB3D3';
		var title = '';
		date = date ? date : new Date();
		switch (type) {
			case 'day': {
				let prev_date = calculatePreviousDay(date);
				title = `${MONTHS[prev_date.getMonth()]} ${prev_date.getDate()}, ${prev_date.getFullYear()}`;
				break;
			}
			case 'month': {
				let prev_date = calculatePreviousMonth(date);
				title = `${MONTHS[prev_date.getMonth()]}, ${prev_date.getFullYear()}`;
				break;
			}
			case 'year':
				title = `${date.getFullYear() - 1}`;
				break;
			default:
				let prev_date = calculatePreviousDay(date);
				title = `${MONTHS[prev_date.getMonth()]} ${prev_date.getDate()}, ${prev_date.getFullYear()}`;
				break;
		}
		return { color, title };
	}

	handleOrdersCountPeriod = (value) => {
		let d = new Date();
		this.getDashboardOrdersCount(value.id, d.getFullYear(), d.getMonth() + 1, d.getDate());
		switch (value.id) {
			case 'day':
				this.setState(state => {
					return {
						ordersCountPeriod: value,
						currentLegendDataOrderCount: this.generateCurrentLegendData(value.id, d),
						previousLegendDataOrderCount: this.generatePreviousLegendData(value.id, d)
					}
				});
				break;
			case "month":
				this.setState(state => {
					return {
						ordersCountPeriod: value,
						currentLegendDataOrderCount: this.generateCurrentLegendData(value.id, d),
						previousLegendDataOrderCount: this.generatePreviousLegendData(value.id, d)
					}
				});
				break;
			case "year":
				this.setState(state => {
					return {
						ordersCountPeriod: value,
						currentLegendDataOrderCount: this.generateCurrentLegendData(value.id, d),
						previousLegendDataOrderCount: this.generatePreviousLegendData(value.id, d)
					}
				});
				break;
		}
	}

	handleOrdersAvgPricePeriod = (value) => {
		let d = new Date();
		this.getDashboardOrdersAvgPrice(value.id, d.getFullYear(), d.getMonth() + 1, d.getDate());

		switch (value.id) {
			case "day":
				this.setState(state => {
					return {
						ordersAvgPricePeriod: value,
						currentLegendDataAverageOrder: this.generateCurrentLegendData(value.id, d),
						previousLegendDataAverageOrder: this.generatePreviousLegendData(value.id, d)
					}
				});
				break;
			case "month":
				this.setState(state => {
					return {
						ordersAvgPricePeriod: value,
						currentLegendDataAverageOrder: this.generateCurrentLegendData(value.id, d),
						previousLegendDataAverageOrder: this.generatePreviousLegendData(value.id, d)
					}
				});
				break;
			case "year":
				this.setState(state => {
					return {
						ordersAvgPricePeriod: value,
						currentLegendDataAverageOrder: this.generateCurrentLegendData(value.id, d),
						previousLegendDataAverageOrder: this.generatePreviousLegendData(value.id, d)
					}
				});
				break;
		}
	}

	handleTopSellingProductPeriod = (value) => {
		let d = new Date();
		this.getDashboardTopSellingProducts(value, d.getFullYear(), d.getMonth() + 1, d.getDate(), 10);
		this.setState({ topSellingProductPeriod: value });
	}

	/**
	 * Generates xAxis data for line chart when comparing current and previous data.
	 * @param {array} data array of dates
	 * @param {string} type selected period
	 */
	generateXAxisData = (data, type) => {
		switch (type) {
			case "day":
				return data.map(item => new Date(item).getHours());
			case "month":
				let month = data.map(item => new Date(item).getDate());
				return month;
			case "year":
				return data.map(item => new Date(item).getMonth() + 1);
			default:
				return [];
		}
	}

	/**
    * Total orders for a given period [PIE]
	*/
	LineChartOrdersCount = () => {
		let { ordersCount, ordersCountPeriod } = this.state;
		let scurrent = [];
		let sprevious = [];
		let xAxisData = [];
		if (ordersCount != null) {
			let { current_data, previous_data } = ordersCount;
			scurrent = current_data.map(i => i.total_orders);
			sprevious = previous_data.map(i => i.total_orders);

			let cdlength = current_data.length;
			let pdlength = previous_data.length;
			if (cdlength > 0 || pdlength > 0) {
				let data = cdlength > pdlength ? current_data.map(i => i.date) : previous_data.map(i => i.date);
				xAxisData = this.generateXAxisData(data, ordersCountPeriod.id);
			}
		}
		return {
			xAxis: {
				type: 'category',
				data: xAxisData
			},
			yAxis: {
				type: 'value'
			},
			tooltip: {
				show: true,
				trigger: 'none',
				axisPointer: {
					type: 'cross'
				}
			},
			legend: {
				show: true,
				align: "left",
				bottom: 0,
				height: 80,
				data: [this.state.currentLegendDataOrderCount.title, this.state.previousLegendDataOrderCount.title]
			},
			series: [
				{
					name: this.state.currentLegendDataOrderCount.title,
					data: scurrent,
					type: 'line',
					color: '#8e44ad',
					smooth: true
				},
				{
					name: this.state.previousLegendDataOrderCount.title,
					data: sprevious,
					type: 'line',
					color: '#D7CCDC',
					smooth: true
				},
			],
			grid: {
				top: 20,
				bottom: 40,
				left: 30,
				right: 10
			}
		};
	};

	/**
    * Average order price for a given period [PIE]
	*/
	LineChartOrdersAvgPrice = () => {
		let { ordersAvgPrice, ordersAvgPricePeriod } = this.state;
		let scurrent = [];
		let sprevious = [];
		let xAxisData = [];
		if (ordersAvgPrice != null) {
			let { current_data, previous_data } = ordersAvgPrice;
			scurrent = current_data.map(i => i.avg_price);
			sprevious = previous_data.map(i => i.avg_price);
			let cdlength = current_data.length;
			let pdlength = previous_data.length;
			if (cdlength > 0 || pdlength > 0) {
				let data = cdlength > pdlength ? current_data.map(i => i.date) : previous_data.map(i => i.date);
				xAxisData = this.generateXAxisData(data, ordersAvgPricePeriod.id);
			}
		}
		return {
			xAxis: {
				type: 'category',
				data: xAxisData
			},
			yAxis: {
				type: 'value'
			},
			tooltip: {
				show: true,
				trigger: 'none',
				axisPointer: {
					type: 'cross'
				}
			},
			legend: {
				show: true,
				align: "left",
				bottom: 0,
				height: 80,
				data: [this.state.currentLegendDataAverageOrder.title, this.state.previousLegendDataAverageOrder.title]
			},
			series: [
				{
					name: this.state.currentLegendDataAverageOrder.title,
					data: scurrent,
					type: 'line',
					color: '#8e44ad',
					smooth: true
				},
				{
					name: this.state.previousLegendDataAverageOrder.title,
					data: sprevious,
					type: 'line',
					color: '#D7CCDC',
					smooth: true
				},
			],
			grid: {
				top: 20,
				bottom: 40,
				left: 30,
				right: 10
			},
		};
	};

	render() {
		let { ordersCount, ordersAvgPrice, topSellingProducts, productsCounters } = this.state;
		let { allProducts, productsMap } = this.props;
		// console.log("ALL PRODUCTS", allProducts, productsMap);

		const EmptyChartDisclaimer = (props) => {
			return (
				<div className={"empty-chart-disclaimer " + (props.className)}>
					<div className="centered">
						<div className="empty-chart-icon"></div>
						<h2>We don't have any information to show you right now</h2>
					</div>
				</div>
			)
		}

		return (
			<div id="dashboard">
				<div id="cards">
					{productsCounters &&
						<div className="cards">
							<div className="single-card hybrid products">
								<div className="items">
									<div className="item active">
										<h2 className="card-title">{productsCounters.total_active}</h2>
										<span>active</span>
									</div>
									<div className="item inactive">
										<h2 className="card-title">{productsCounters.total_inactive}</h2>
										<span>inactive</span>
									</div>
									<div className="item total">
										<h2 className="card-title">{productsCounters.total_active + productsCounters.total_inactive}</h2>
										<span>total</span>
									</div>
								</div>
								<div className="card-description">
									<span className="card-icon"></span>
									<span className="card-icon-title">Products</span>
								</div>
							</div>
							<div className="single-card hybrid out-of-stock">
								<div className="items">
									<div className="item active">
										<h2 className="card-title">{productsCounters.out_of_stock_active}</h2>
										<span>active</span>
									</div>
									<div className="item inactive">
										<h2 className="card-title">{productsCounters.out_of_stock_inactive}</h2>
										<span>inactive</span>
									</div>
									<div className="item total">
										<h2 className="card-title">{productsCounters.out_of_stock_active + productsCounters.out_of_stock_inactive}</h2>
										<span>total</span>
									</div>
								</div>
								<div className="card-description">
									<span className="card-icon"></span>
									<span className="card-icon-title">Out of stock</span>
								</div>
							</div>
							<Card
								for="On promotion"
								value={productsCounters.on_promotion}
								className="on-promotion"
							/>
						</div>
					}
				</div>
				<div className="dashboard-container">

					<div className="charts">
						{ordersCount !== null &&
							<BoosterChart
								title="Total orders"
								totalValue={ordersCount.total_orders}
								growthRate={ordersCount.growth_rate}
								description="orders for"
								type="full-width"
								filter="Day"
								handleSelectChange={this.handleOrdersCountPeriod}
								selectModel={this.state.ordersCountPeriod}
							>
								{!ArrayIsEmpty(ordersCount.current_data) || !ArrayIsEmpty(ordersCount.previous_data)
									?
									<ReactEcharts
										className="react_for_echarts line-chart"
										option={this.LineChartOrdersCount()}
										style={{ height: 250 }}
										notMerge={true}
										lazyUpdate={true}
									/>
									:
									<EmptyChartDisclaimer className="orders-per-hour-dsclmr" />
								}
							</BoosterChart>
						}

						<div className="chart-group">
							{ordersAvgPrice !== null &&
								<BoosterChart
									title="Average order value"
									totalValue={ordersAvgPrice.avg_price}
									growthRate={ordersAvgPrice.growth_rate}
									description="average price for"
									type="percentage-width push-right"
									filter="Day"
									handleSelectChange={this.handleOrdersAvgPricePeriod}
									selectModel={this.state.ordersAvgPricePeriod}
								>
									{!ArrayIsEmpty(ordersAvgPrice.current_data) || !ArrayIsEmpty(ordersAvgPrice.previous_data)
										?
										<ReactEcharts
											className="react_for_echarts line-chart"
											option={this.LineChartOrdersAvgPrice()}
											style={{ height: 250 }}
											notMerge={true}
											lazyUpdate={true}
										/>
										:
										<EmptyChartDisclaimer className="average-order-price-dsclmr" />
									}
								</BoosterChart>
							}

							{(topSellingProducts !== null && allProducts.length > 0 && Object.keys(productsMap).length) &&
								<BoosterList
									title="Top selling products"
									type="math-width"
									filter="Day"
								>
									{topSellingProducts && Array.isArray(topSellingProducts.products) && topSellingProducts.products.length > 0
										?
										topSellingProducts.products.map((p, i) => {
											return (
												<div key={i} className="list-item">
													<h2 className="item-title">{allProducts[productsMap[p.product_id]].name}</h2>

													<div className="item-values">
														<h4>{p.quantity}</h4>
														<h4>{Math.trunc(p.total)}</h4>
													</div>
												</div>
											)
										})
										:
										<EmptyChartDisclaimer className="average-order-price-dsclmr" />
									}
								</BoosterList>
							}
						</div>
					</div>
				</div>
			</div >
		)
	}
}

const mapStateToProps = (state) => {
	return {
		allProducts: state.product.allProducts,
		productsMap: state.product.productsMap
	};
}

export default connect(mapStateToProps, null)(Dashboard);