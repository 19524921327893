const initialState = {
	orders: [],
	filter: {
		name: '',
		email: '',
		phone: '',
		address: '',
		date: "",
		// delivery_time, 
		status: 'accepted',
		active: false,
		invoice_id: ''
	} 
};

const order = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_ORDERS':
			return Object.assign({}, state, {
				...state,
				orders: action.payload
			});
		case 'SET_ORDER_FILTER_DATA':
			return Object.assign({}, state, {
				...state,
				filter: action.payload
			});
		default:
			return state;
	}
}

export default order;