const convertText = (data) => {
    let ar = [];
    let prim_string = data.toLowerCase().split(" ");
    prim_string.forEach(item => {
        let d = item.split("");
        let string_en = [];
        let string_mk = [];
        d.forEach(it => {
            if (it === 'a') {
                string_en.push(it);
                string_mk.push('а');
            } else if (it === 'а') {
                string_en.push('a');
                string_mk.push(it);
            }

            if (it === 'b') {
                string_en.push(it);
                string_mk.push('б');
            } else if (it === 'б') {
                string_en.push('b');
                string_mk.push(it);
            }

            if (it === 'c') {
                string_en.push(it);
                string_mk.push('ц');
            } else if (it === 'ц') {
                string_en.push('c');
                string_mk.push(it);
            }

            if (it === 'd') {
                string_en.push(it);
                string_mk.push('д');
            } else if (it === 'д') {
                string_en.push('d');
                string_mk.push(it);
            }

            if (it === 'e') {
                string_en.push(it);
                string_mk.push('е');
            } else if (it === 'е') {
                string_en.push('e');
                string_mk.push(it);
            }

            if (it === 'f') {
                string_en.push(it);
                string_mk.push('ф');
            } else if (it === 'ф') {
                string_en.push('f');
                string_mk.push(it);
            }

            if (it === 'g') {
                string_en.push(it);
                string_mk.push('г');
            } else if (it === 'г') {
                string_en.push('g');
                string_mk.push(it);
            }

            if (it === 'h') {
                string_en.push(it);
                string_mk.push('х');
            } else if (it === 'х') {
                string_en.push('h');
                string_mk.push(it);
            }

            if (it === 'i') {
                string_en.push(it);
                string_mk.push('и');
            } else if (it === 'и') {
                string_en.push('i');
                string_mk.push(it);
            }

            if (it === 'j') {
                string_en.push(it);
                string_mk.push('ј');
            } else if (it === 'ј') {
                string_en.push('j');
                string_mk.push(it);
            }

            if (it === 'k') {
                string_en.push(it);
                string_mk.push('к');
            } else if (it === 'к') {
                string_en.push('k');
                string_mk.push(it);
            }

            if (it === 'l') {
                string_en.push(it);
                string_mk.push('л');
            } else if (it === 'л') {
                string_en.push('l');
                string_mk.push(it);
            }

            if (it === 'm') {
                string_en.push(it);
                string_mk.push('м');
            } else if (it === 'м') {
                string_en.push('m');
                string_mk.push(it);
            }

            if (it === 'n') {
                string_en.push(it);
                string_mk.push('н');
            } else if (it === 'н') {
                string_en.push('n');
                string_mk.push(it);
            }

            if (it === 'o') {
                string_en.push(it);
                string_mk.push('о');
            } else if (it === 'о') {
                string_en.push('o');
                string_mk.push(it);
            }

            if (it === 'p') {
                string_en.push(it);
                string_mk.push('п');
            } else if (it === 'п') {
                string_en.push('p');
                string_mk.push(it);
            }

            if (it === 'q') {
                string_en.push(it);
                string_mk.push('к');
            } else if (it === 'ќ') {
                string_en.push('kj');
                string_mk.push(it);
            }

            if (it === 'r') {
                string_en.push(it);
                string_mk.push('р');
            } else if (it === 'р') {
                string_en.push('r');
                string_mk.push(it);
            }

            if (it === 's') {
                string_en.push(it);
                string_mk.push('с');
            } else if (it === 'с') {
                string_en.push('s');
                string_mk.push(it);
            }

            if (it === 't') {
                string_en.push(it);
                string_mk.push('т');
            } else if (it === 'т') {
                string_en.push('t');
                string_mk.push(it);
            }

            if (it === 'u') {
                string_en.push(it);
                string_mk.push('у');
            } else if (it === 'у') {
                string_en.push('u');
                string_mk.push(it);
            }

            if (it === 'v') {
                string_en.push(it);
                string_mk.push('в');
            } else if (it === 'в') {
                string_en.push('v');
                string_mk.push(it);
            }

            if (it === 'w') {
                string_en.push(it);
                string_mk.push('в');
            }

            if (it === 'x') {
                string_en.push(it);
                string_mk.push('кс');
            }

            if (it === 'ш') {
                string_en.push('sh');
                string_mk.push(it);
            }

            if (it === 'y') {
                string_en.push(it);
                string_mk.push('j');
            }

            if (it === 'ѓ') {
                string_en.push('gj');
                string_mk.push(it);
            }

            if (it === 'z') {
                string_en.push(it);
                string_mk.push('з');
            } else if (it === 'з') {
                string_en.push('z');
                string_mk.push(it);
            }

            if (it === 'љ') {
                string_en.push('lj');
                string_mk.push(it);
            }

            if (it === 'њ') {
                string_en.push('nj');
                string_mk.push(it);
            }

            if (it === 'ж') {
                string_en.push('zh');
                string_mk.push(it);
            }

            if (it === 'ч') {
                string_en.push('ch')
                string_mk.push(it);
            }
        })
        // console.log("string en: ", string_en);
        // console.log("string en: ", string_mk.join(''));
        if (string_en.length >= 3) {
            ar.push(string_en.join(''));
            ar.push(string_mk.join(''));
        }
    })
    return ar;
}


const formatDate = (date, type) => {
    if (type === "YYYY-MM-DD") {
        if (date !== null) {
            let d = date.split("T");
            return d[0];
        } else {
            let d = new Date().toISOString().split("T")
            return d[0];
        }
    } else {
        if (date !== null) {
            let d = date.split("T");
            let s = d[0].split("-");

            let year = s[0]
            let month = s[1]
            let day = s[2]
            return `${day}.${month}.${year}`;
        } else {
            let d = new Date().toISOString().split("T")
            let s = d[0].split("-");

            let year = s[0]
            let month = s[1]
            let day = s[2]
            return `${day}.${month}.${year}`;
        }
    }
}

const formatDateByLocaleString = (date, locale) => {
    switch (locale) {
        case 'en-EN':
        case 'en-GB':
        case 'de-DE':
            return new Intl.DateTimeFormat(locale).format(new Date(date));
        default:
            return new Intl.DateTimeFormat().format(new Date(date));
    }
}

const badgeType = (data) => {
    var badge = '';
    switch (data) {
        case "NEW_PRODUCT":
            badge = "n";
            break;
        case "ONLY_HERE":
            badge = "c";
            break;
        case "LOCAL_PRODUCT":
            badge = "l";
            break;
        case "FLYER_PRODUCT":
            badge = "f";
            break;
        case "ORGANIC":
            badge = "o";
            break;
        case "HALAL":
            badge = "h";
            break;
        case "KOSHER":
            badge = "k";
        case "VEGAN":
            badge = "v";
            break;
        case "RECOMENDED":
            badge = "p";
            break;
        case "BEST_SELLER":
            badge = "b";
            break;
        case "LOW_PRICE":
            badge = "n";
            break;
        case "OUR_BRAND":
            badge = "n";
            break;
    }
    return badge;
}

const isString = (value) => {
    return typeof value === 'string' || value instanceof String;
}

const isEmpty = (obj) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

const ArrayIsEmpty = (arr) => {
    if (arr && Array.isArray(arr) && arr.length === 0) {
        return true;
    }
    return false;
}

const objectHasProperty = (obj, prop) => {
    for (var key in obj) {
        if (obj.hasOwnProperty(prop))
            return true;
    }
    return false;
}

const checkIfObjExists = (obj, id) => {
    for (var key in obj) {
        if (obj[key].id === id) {
            return false;
        }
    }
    return true;
}

const getCountryName = (list, alpha2) => {
    var country = list.filter(item => item.alpha2 === alpha2);
    if (country.length > 0) {
        return country[0].name
    }
    return '';
}

const addZeroes = (data) => {
    var num = data.toString()
    var value = data;
    var res = num.split(".");
    if (res.length == 1 || (res[1].length < 3)) {
        value = value.toFixed(2);
    }
    return value
}

const validateIfDataExists = (data, listData, prop) => {
    let _err = [];
    for (var i = 0; i < listData.length; i++) {
        if (listData[i][prop] === data[prop]) {
            if (!_err.includes[prop]) {
                _err.push(prop)
            }
        }
    }
    return _err;
}

const validateData = (data, _errors, _exeption) => {
    for (var key in data) {
        if (_exeption !== undefined) {
            if (!_exeption.includes(key)) {
                // number
                if (typeof data[key] === "number") {
                    if (data[key] === '') {
                        if (!_errors.includes(key)) {
                            _errors.push(key);
                        }
                    }
                }

                // string
                if (typeof data[key] === 'string') {
                    if (data[key] === '') {
                        if (!_errors.includes(key)) {
                            _errors.push(key);
                        }
                    }
                }

                // array
                if (data[key] && typeof data[key] === 'object' && data[key].constructor === Array) {
                    if (data[key].length == 0) {
                        if (!_errors.includes(key)) {
                            _errors.push(key);
                        }
                    }
                }

                // object
                if (data[key] && typeof data[key] === 'object' && data[key].constructor === Object) {
                    if (isEmpty(data[key])) {
                        _errors.push(key);
                    } else {
                        validateData(data[key], _errors, _exeption);
                    }
                }
            }
        }
    }
}

const nonrepeatableValidator = (data, _errors, _nonrepeatable) => {
    if (_nonrepeatable !== undefined) {
        for (var i in _nonrepeatable.list) {
            if (validateIfDataExists(data, _nonrepeatable.data, _nonrepeatable.list[i]).length > 0) {
                if (!_errors.includes(_nonrepeatable.list[i])) {
                    _errors.push(_nonrepeatable.list[i]);
                }
            }
        }
    }
}

const mapBadges = (data) => {
    var badge = '';
    switch (data) {
        case "NEW_PRODUCT":
            badge = "New Product";
            break;
        case "ONLY_HERE":
            badge = "Only Here";
            break;
        case "LOCAL_PRODUCT":
            badge = "Local Product";
            break;
        case "FLYER_PRODUCT":
            badge = "Flyer Product";
            break;
        case "ORGANIC":
            badge = "Organic";
            break;
        case "HALAL":
            badge = "Halal";
            break;
        case "KOSHER":
            badge = "Kosher";
            break;
        case "VEGAN":
            badge = "Vegan";
            break;
        case "RECOMENDED":
            badge = "Recomended";
            break;
        case "BEST_SELLER":
            badge = "Best Seller";
            break;
        case "LOW_PRICE":
            badge = "Low Price";
            break;
        case "OUR_BRAND":
            badge = "Our Brand";
            break;
        case "GLUTEN_FREE":
            badge = "Gluten Free";
            break;
    }
    return badge;
}

const mapUnits = (data) => {
    var unit = '';
    switch (data) {
        case "L":
            unit = "l";
            break;
        case "ML":
            unit = "ml";
            break;
        case "G":
            unit = "g";
            break;
        case "KG":
            unit = "kg";
            break;
        case "KCAL":
            unit = "Kcal";
            break;
        case "KJ":
            unit = "kJ";
            break;
        case "PIECES":
            unit = "pieces";
            break;

    }
    return unit;
}

const mapAlergens = (data) => {
    var alergens = '';
    switch (data) {
        case "MILK":
            alergens = "Milk";
            break;
        case "EGGS":
            alergens = "Eggs";
            break;
        case "FISH":
            alergens = "Fish";
            break;
        case "SHELLFISH":
            alergens = "Shellfish";
            break;
        case "TREE_NUTS":
            alergens = "Tree Nuts";
            break;
        case "PEANUTS":
            alergens = "Peanuts";
            break;
        case "WHEAT":
            alergens = "Wheat";
            break;
        case "SOYBEANS":
            alergens = "Soybeans";
            break;
        case "CELERY":
            alergens = "Celery";
            break;
        case "HONEY":
            alergens = "Honey";
            break;
        case "SINAPIS":
            alergens = "Sinapis";
            break;

    }
    return alergens;
}

const mapNutriTypes = (data) => {
    var nutriTypes = '';
    switch (data) {
        case "FAT":
            nutriTypes = "Fat";
            break;
        case "CHOLESTEROL":
            nutriTypes = "Cholesterol";
            break;
        case "SODIUM":
            nutriTypes = "Sodium";
            break;
        case "CARBOHYDRATE":
            nutriTypes = "Carbohydrate";
            break;
        case "FIBER":
            nutriTypes = "Fiber";
            break;
        case "SUGARS":
            nutriTypes = "Sugars";
            break;
        case "PROTEIN":
            nutriTypes = "Protein";
            break;
        case "ENERGY_VALUE":
            nutriTypes = "Energy value";
            break;
        case "SATURATED_FAT":
            nutriTypes = "Saturated Fat";
            break;
        case "SALT":
            nutriTypes = "Salt";
            break;

    }
    return nutriTypes;
}

const mapPackaging = (data) => {
    var pack = '';
    switch (data) {
        case "PAPER":
            pack = "Paper";
            break;
        case "METAL":
            pack = "Metal";
            break;
        case "PLASTIC":
            pack = "Plastic";
            break;
        case "GLASS":
            pack = "Glass";
            break;
        case "WOOD":
            pack = "Wood";
            break;
        case "OTHER":
            pack = "Order";
            break;

    }
    return pack;
}

const mapProductTypes = (data) => {
    switch (data) {
        case "food":
            return "Food";
        case "beverage":
            return "Beverage";
        case "cosmetics":
            return "Cosmetics";
        case "personal_hygiene":
            return "Personal Hygiene";
        case "cleaning":
            return "Cleaning";
        default:
            return '';
    }
}

const mapTimeMeasurements = (data) => {
    var time = '';
    switch (data) {
        case "YEARS":
            time = "Years";
            break;
        case "MONTHS":
            time = "Months";
            break;
        case "DAYS":
            time = "Days";
            break;
        case "HOURS":
            time = "Hours";
            break;
        case "MINUTES":
            time = "Minutes";
            break;
        case "SECONDS":
            time = "Seconds";
            break;

    }
    return time;
}

const calculatePreviousDay = (date) => {
    var prev_date = new Date(date);
    prev_date.setDate(prev_date.getDate() - 1);
    return prev_date;
}

const calculatePreviousMonth = (date) => {
    var prev_date = new Date(date);
    prev_date.setMonth(prev_date.getMonth() - 1);
    return prev_date;
}

export {
    convertText,
    formatDate,
    badgeType,
    isEmpty,
    ArrayIsEmpty,
    objectHasProperty,
    getCountryName,
    addZeroes,
    validateData,
    nonrepeatableValidator,
    mapBadges,
    mapUnits,
    mapAlergens,
    mapNutriTypes,
    mapPackaging,
    mapTimeMeasurements,
    checkIfObjExists,
    isString,
    mapProductTypes,
    calculatePreviousDay,
    calculatePreviousMonth,
    formatDateByLocaleString
};
