import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../../css/Parts/Header.css";

import { User, Notifications } from "../Parts";

class Header extends Component {
    render() {
        return (
            <div id="header">
                <div className="header-container">
                    <div className="nav-history">
                        <ul className="nav-history-list">
                            {this.props.paths.map((item, i) => 
                                <li key={i}>
                                    {i > 1 ?
                                        <NavLink
                                            to={item}
                                        >
                                            {this.props.navName}
                                        </NavLink>
                                        :   
                                        <NavLink
                                            to={item}
                                        >
                                            {item.split('/')[i + 1]}
                                        </NavLink>
                                    }
                                </li>
                            )}
                        </ul>
                    </div>

                    <div className="user-corner">
                        <Notifications/>
                        <User
                            name={`${this.props.user.first_name} ${this.props.user.last_name}`}
                            role={this.props.user.role}
                            picture={this.props.user.picture}
                            logout={this.props.logout}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default Header;