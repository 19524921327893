import React from "react";
import { Link } from "react-router-dom";
import SelectDropdown from "./SelectDropdown";

import "../css/BoosterList.css";

const BoosterList = (props) => {
	return (
		<div className={"single-list " + (props.type)}>
			<div className="list-header">
				<h2 className="list-title">{props.title}</h2>
				<Link className="list-link" to="/dashboard">View details</Link>
			</div>
			{/* <div className="list-values">
				<div className="list-values-divide">
					<h2 className="list-value">{props.value} <span>Products</span></h2>
				</div>
			</div> */}
			<div className="list-map">
				{(Array.isArray(props.children) && props.children.length > 0) &&
					<div className="bc-list-header">
						<h2 className="bc-list-header-title">Title</h2>

						<div className="bc-list-header-values">
							<h4>Qty.</h4>
							<h4>Amount</h4>
						</div>
					</div>
				}

				{props.children}

			</div>
		</div>
	)
}

export default BoosterList;