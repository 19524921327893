import React from 'react';

import ProductFoodMeta from './ProductFoodMeta';
import ProductBeverageMeta from './ProductBeverageMeta';
import ProductCosmeticsMeta from './ProductCosmeticsMeta';
import ProductPersonalHygieneMeta from './ProductPersonalHygieneMeta';
import ProductCleaningMeta from './ProductCleaningMeta';

const metaComponents = {
    food: ProductFoodMeta,
    beverage: ProductBeverageMeta,
    cosmetics: ProductCosmeticsMeta,
    personal_hygiene: ProductPersonalHygieneMeta,
    cleaning: ProductCleaningMeta
}

const PrototypeMeta = (props) => {
    const ProductMeta = metaComponents[props.type];
    return ProductMeta ? <ProductMeta {...props} /> : null;
}

export default PrototypeMeta;