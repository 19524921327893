import React, { Component } from "react";


class Carts extends Component {
    render() {
        return (
            <div>Carts TODO</div>
        )
    }
}

export default Carts;