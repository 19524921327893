const initialState = {
    categories: [],
    badges:[],
    productTypes: [],
    currencies: [],
    user: {},
    productsCount: 0,
    ordersCount: 0,
    navigationName: '',
    usersCount: 0,
    warehouseCount: 0,
    pagesCount: 0,
    formsCount: 0,
    countries: []
};

const home = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_CURRENCIES':
            return Object.assign({}, state, {
                ...state,
                currencies: action.payload
            });
        case 'SET_BADGES':
            return Object.assign({}, state, {
                ...state,
                badges: action.payload
            });
        case 'SET_TYPES':
            return Object.assign({}, state, {
                ...state,
                productTypes: action.payload
            });
        case 'SET_CATEGORIES':
            return Object.assign({}, state, {
                ...state,
                categories: action.payload
            }); 
        case 'SET_USER_DATA':
            return Object.assign({}, state, {
                ...state,
                user: action.payload
            }); 
        case 'SET_PRODUCT_COUNT':
            return Object.assign({}, state, {
                ...state,
                productsCount: action.payload
            }); 
        case 'SET_ORDER_COUNT':
            return Object.assign({}, state, {
                ...state,
                ordersCount: action.payload
            }); 
        case 'SET_NAVIGATION_NAME':
            return Object.assign({}, state, {
                ...state,
                navigationName: action.payload
            }); 
        case 'SET_USERS_COUNT':
            return Object.assign({}, state, {
                ...state,
                usersCount: action.payload
            }); 
        case 'SET_WAREHOUSE_COUNT':
            return Object.assign({}, state, {
                ...state,
                warehouseCount: action.payload
            }); 
        case 'SET_PAGES_COUNT':
            return Object.assign({}, state, {
                ...state,
                pagesCount: action.payload
            }); 
        case 'SET_FORMS_COUNT':
            return Object.assign({}, state, {
                ...state,
                formsCount: action.payload
            }); 
        case 'SET_COUNTRIES':
            return Object.assign({}, state, {
                ...state,
                countries: action.payload
            }); 
		default:
			return state;
	}
}

export default home;