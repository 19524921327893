import React, { Component } from "react";
import PropTypes from 'prop-types';
import "../css/DropDown.css";

class SelectDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false
        };
        this.wrapperRef = null;
        this.selectDropdownRef = null;
        this.dropdownItemsRefs = [];
    }

    componentDidMount() { }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    setSelectDropdownRef = (node) => {
        this.selectDropdownRef = node;
    }

    handleDrop = () => {
        if (!this.props.disabled) {
            if (!this.state.isOpen) {
                document.addEventListener('mousedown', this.handleClickOutside);
            } else {
                document.removeEventListener('mousedown', this.handleClickOutside);
            }
            this.setState((state) => {
                return { isOpen: !state.isOpen };
            });
        }
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.handleDrop();
        }
    }

    handleClearSelected = (e) => {
        e.stopPropagation();
        this.props.handleSelectChange({});
    }

    handleSelectChange = (selectedValue) => {
        let { model, valueField } = this.props;
        let returnValue = this.props.options.find(i => i[this.props.valueField] === selectedValue);
        if (returnValue && (model[valueField] !== selectedValue)) {
            this.props.handleSelectChange(returnValue);
        }
        this.handleDrop();
    }

    GoToItem = (event) => {
        let items = []
        for (let i in this.props.list) {
            if (this.props.list[i][this.props.handler] !== undefined) {
                if (this.props.list[i][this.props.handler].startsWith(String.fromCharCode(event.keyCode), 0)) {
                    for (let a in this.dropdownItemsRefs) {
                        if (this.props.list[i][this.props.handler] === this.dropdownItemsRefs[a].innerHTML) {
                            items.push(this.dropdownItemsRefs[a]);
                            let first = items[0];
                            let top = first.offsetTop;
                            this.selectDropdown.scrollTop = top;
                        }
                    }
                }
            }
        }
    }

    render() {
        return (
            <p
                className={`input-holder select-holder half ${this.state.isOpen ? 'opened' : ''} ${this.props.disabled ? 'disabled' : ''} ${this.props.error && this.props.value === '' ? 'error-active' : ''}`}
                ref={this.setWrapperRef}
                tabIndex="0"
                onKeyDown={this.GoToItem}
            >
                <span className={`select-value-container ${this.props.value !== '' || this.props.title === '' ? 'dirty' : ''}`} onClick={this.handleDrop}>
                    <span className="select-label">{this.props.title}</span>
                    {this.props.error && this.props.value === '' ? <span className="input-error">{this.props.errorMessage}</span> : null}
                    {this.props.value !== '' &&
                        <span className="select-value">
                            <span className="select-value-src">{this.props.model[this.props.labelField]}</span>
                            {this.props.clearable &&
                                <span
                                    className="delete-select-value"
                                    onClick={this.handleClearSelected}
                                >
                                </span>
                            }
                        </span>
                    }
                </span>
                <span className="select-dropdown" ref={this.setSelectDropdownRef}>
                    {this.props.options.map((item, i) =>
                        <SelectDropdownItem
                            key={i}
                            ref={dropdownContent => this.dropdownItemsRefs[i] = dropdownContent}
                            label={item[this.props.labelField]}
                            value={item[this.props.valueField]}
                            handler={this.handleSelectChange}
                        >
                            {item[this.props.labelField]}
                        </SelectDropdownItem>
                    )}
                </span>
            </p>
        )
    }
}

SelectDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    model: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
    elementName: PropTypes.string,
    labelField: PropTypes.string,
    valueField: PropTypes.string,
    clearable: PropTypes.bool,
    handleSelectChange: PropTypes.func.isRequired,
};

SelectDropdown.defaultProps = {
    options: [],
    model: {},
    disabled: false,
    elementName: '',
    labelField: 'label',
    valueField: 'value',
    clearable: false,
    handleSelectChange: () => undefined
};

const SelectDropdownItem = (props) => {
    const handleItemClick = () => {
        props.handler(props.value)
    }
    return (
        <span onClick={handleItemClick}>
            {props.label}
        </span>
    )
}

export default SelectDropdown;