import React, { Component } from "react";
import "../css/DropDown.css";


class DropDown extends Component {
	constructor() {
		super();
		this.state = {
			drop: '',
			disabled: false
		}

		this.GoToItem = this.GoToItem.bind(this);
		this.DropdownItems =[]
	}

	componentDidMount() {
		document.addEventListener('mousedown', this.handleClickOutside);
	}

	componentWillUnmount() {
		document.removeEventListener('mousedown', this.handleClickOutside);
	}

	handleDrop = () => {
		if (!this.props.disabled) {
			this.setState({
				drop: this.state.drop === '' ? 'opened' : ''
			})
		}
	}

	handleSelect = (item) => {
        // console.log("TCL: DropDown -> handleSelect -> item", item);
		this.setState({
			drop: ''
		}, () => {
			if (item.hasOwnProperty("alpha2")) {
				this.props.handleSelectChange(item.alpha2);
			} else {
				if (item.id !== undefined) {
					this.props.handleSelectChange(item.id);
				} else {
					this.props.handleSelectChange(item);
				}
			}
		})
	}

	setWrapperRef = (node) => {
		this.wrapperRef = node;
	}

	setSelectDropdown = (node) => {
		this.selectDropdown = node;
	}

	handleClickOutside = (event) => {
		if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
			this.setState({
				drop: ''
			})
		}
	}

	handleDataProperty = (item, handler, mapFunction) => {
		if (handler !== "" || handler !== undefined) {
			if (mapFunction !== undefined) {
				return mapFunction(item[`${handler}`] !== undefined ? item[`${handler}`] : item);
			} else {
				return item[`${handler}`] !== undefined ? item[`${handler}`] : item;
			}
		} else {
			if (mapFunction !== undefined) {
				return mapFunction(item !== undefined ? item : item);
			} else {
				return item !== undefined ? item : item;
			}
		}
	}

	GoToItem(event) {
		let items = []
		for (let i in this.props.list) {
			if(this.props.list[i][this.props.handler] !== undefined) {
				if(this.props.list[i][this.props.handler].startsWith(String.fromCharCode(event.keyCode), 0)) {
					for (let a in this.DropdownItems) {
						if(this.props.list[i][this.props.handler] === this.DropdownItems[a].innerHTML) {
							items.push(this.DropdownItems[a]);
							let first = items[0];
							let top = first.offsetTop;
							this.selectDropdown.scrollTop = top;
						}
					}
				}
			}
		}
	}

	// this.props.classname is a property specifically made for the STATUS DROPDOWN. Any other instance of the DropDown should use className as a property and set it to an empty string

	render() {
		return (
			<p className={`input-holder select-holder half ${this.state.drop} ${this.props.disabled ? 'disabled' : ''}` + (this.state.disabled ? "disabled" : "") + (this.props.error && this.props.value === "" ? "error-active" : "")} ref={this.setWrapperRef} tabIndex="0" onKeyDown={this.GoToItem}>
				<span className={`select-value-container ${this.props.value !== '' || this.props.title === '' ? 'dirty' : ''}`} onClick={this.handleDrop}>
					<span className="select-label">{this.props.title}</span>
					{this.props.error && this.props.value === "" ? <span className="input-error">{this.props.errorMessage}</span> : null}
					{this.props.value !== "" &&
						<span className="select-value">
							<span className="select-value-src">{this.props.value}</span>
							<span className="delete-select-value" onClick={() => this.handleSelect("")}></span>
						</span>
					}
				</span>
				<span className="select-dropdown" ref={this.setSelectDropdown}>
					{this.props.list.map((item, i) =>
						<span ref={dropdownContent => this.DropdownItems[i] = dropdownContent} className={this.props.className !== "" ? this.props.className.orderNumber > item.orderNumber ? "status-done" : "" : ""} key={i} onClick={() => this.handleSelect(item)}>{this.handleDataProperty(item, this.props.handler, this.props.mapFunction)}</span>
					)}
				</span>
			</p>
		)
	}
}

export default DropDown;