const initialState = {
    forms: []
};

const form = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FORMS':
            return Object.assign({}, state, {
                ...state,
                forms: action.payload
            });
        default:
            return state;
    }
}

export default form;