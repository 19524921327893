import React, { Component } from 'react';
import oblax from '../../../../assets/oblax';

import { DropDown, ButtonDropDown } from "../../../../parts";
import { ProductTags } from "../Parts";
import { mapUnits, mapAlergens, mapNutriTypes, mapPackaging, mapTimeMeasurements } from "../../../../assets/helper";
import { ConfirmAlert } from "../../../../parts";

class ProductBeverageMeta extends Component {
	constructor(props) {
		super(props);

		this.state = {
			beverage_allergens: [],
			beverage_ingredients: [],
			beverage_expiration: {},
			beverage_measurement: {},
			beverage_gross_weight_measurement: {},
			beverage_nutrition_values: [],
			beverage_packaging: '',

			beverage_nutrition_new: {
				type: "",
				unit: "",
				value: 0,
				id: "",
			},

			beverage_unit: [],
			beverage_allergens_list: [],
			beverage_nutrition_list: [],
			beverage_packaging_list: [],
			beverage_time_list: [],

			deleteAlert: false,
			removeType: ''
		}

		// Get data from api 
		this.getMeasurements = this.getMeasurements.bind(this);

		// Beverage Allergens
		this.handleAllergenClose = this.handleAllergenClose.bind(this);
		this.handleAllergenInputChange = this.handleAllergenInputChange.bind(this);

		// Beverage Expiration
		this.setExpiration = this.setExpiration.bind(this);
		this.setExpirationTime = this.setExpirationTime.bind(this);

		// Beverage Ingredients
		this.editIngredients = this.editIngredients.bind(this);

		// Beverage Measurement
		this.setMeasurementUnit = this.setMeasurementUnit.bind(this);
		this.setMeasurementValue = this.setMeasurementValue.bind(this);
		this.setGrossWeightMeasurementUnit = this.setGrossWeightMeasurementUnit.bind(this);
		this.setGrossWeightMeasurementValue = this.setGrossWeightMeasurementValue.bind(this);

		// Beverage Nutruition
		this.setNutritionType = this.setNutritionType.bind(this);
		this.setNutritionUnit = this.setNutritionUnit.bind(this);
		this.setNutritionValue = this.setNutritionValue.bind(this);
		this.editNewNutritionValue = this.editNewNutritionValue.bind(this);
		this.editValueNewNutrition = this.editValueNewNutrition.bind(this);
		this.addNewNutritionValueToList = this.addNewNutritionValueToList.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);
		this.removeAlertNutrition = this.removeAlertNutrition.bind(this);
		this.removeNutritionData = this.removeNutritionData.bind(this);

		// Beverage Packaging
		this.setPackaging = this.setPackaging.bind(this);
	}

	componentDidMount() {
		this.getMeasurements();
		this.getProductAlergens();
		this.getProductNutriTypes();
		this.getProductPackaging();
		this.getTimeMeasurements();

		if (!this.isEmpty(this.props.meta)) {
			this.setState({
				beverage_allergens: this.props.meta.beverage_allergens !== undefined ? this.props.meta.beverage_allergens : [],
				beverage_expiration: this.props.meta.beverage_expiration !== undefined ? this.props.meta.beverage_expiration : {},
				beverage_ingredients: this.props.meta.beverage_ingredients !== undefined ? this.props.meta.beverage_ingredients : [],
				beverage_measurement: this.props.meta.beverage_measurement !== undefined ? this.props.meta.beverage_measurement : {},
				beverage_gross_weight_measurement: this.props.meta.beverage_gross_weight_measurement !== undefined ? this.props.meta.beverage_gross_weight_measurement : {},
				beverage_nutrition_values: this.props.meta.beverage_nutrition_values !== undefined ? this.props.meta.beverage_nutrition_values : [],
				beverage_packaging: this.props.meta.beverage_packaging,
			})
		} else {
			this.props.setMetaData('beverage_allergens', []);
			this.props.setMetaData('beverage_expiration', {});
			this.props.setMetaData('beverage_ingredients', []);
			this.props.setMetaData('beverage_measurement', {});
			this.props.setMetaData('beverage_gross_weight_measurement', {});
			this.props.setMetaData('beverage_nutrition_values', []);
			this.props.setMetaData('beverage_packaging', "");
		}
	}


	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

	// Get data from api 
	// ==========================
	getMeasurements() {
		return oblax.lists.getMeasurements()
			.then((result) => {
				if (result.ok) {
					this.setState({
						beverage_unit: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("MEASUREMENTS ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("MEASUREMENTS ERROR", err);
				}
			});
	}

	getProductAlergens() {
		return oblax.lists.getProductAlergens()
			.then((result) => {
				if (result.ok) {
					this.setState({
						beverage_allergens_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("ALERGENS ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ALERGENS ERROR", err);
				}
			});
	}

	getProductNutriTypes() {
		return oblax.lists.getProductNutriTypes()
			.then((result) => {
				if (result.ok) {
					this.setState({
						beverage_nutrition_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("NUTRITYPES ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("NUTRITYPES ERROR", err);
				}
			});
	}

	getProductPackaging() {
		return oblax.lists.getProductPackaging()
			.then((result) => {
				if (result.ok) {
					this.setState({
						beverage_packaging_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PACKAGING ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PACKAGING ERROR", err);
				}
			});
	}

	getTimeMeasurements() {
		return oblax.lists.getTimeMeasurements()
			.then((result) => {
				if (result.ok) {
					this.setState({
						beverage_time_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("TIME MEASUREMENTS ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("TIME MEASUREMENTS ERROR", err);
				}
			});
	}

	// Beverage Allergens
	// ==========================
	handleAllergenClose(removedAllergen) {
		const beverage_allergens = this.state.beverage_allergens.filter(item => item !== removedAllergen);
		this.setState({
			beverage_allergens
		}, () => {
			this.props.setMetaData('beverage_allergens', beverage_allergens);
		});
	}

	handleAllergenInputChange(value) {
		let beverage_allergens = this.state.beverage_allergens;
		if (value && beverage_allergens.indexOf(value) === -1) {
			beverage_allergens = [...beverage_allergens, value];
		}
		this.setState({
			beverage_allergens
		}, () => {
			this.props.setMetaData('beverage_allergens', beverage_allergens);
		});
	}
	// ==========================

	// Beverage Expiration
	// ==========================
	setExpiration(value) {
		this.setState(prevState => ({
			beverage_expiration: {
				...prevState.beverage_expiration,
				expiration_measurement: value
			}
		}), () => {
			this.props.setMetaData('beverage_expiration', this.state.beverage_expiration);
		})
	}

	setExpirationTime({ target }) {
		this.setState(prevState => ({
			beverage_expiration: {
				...prevState.beverage_expiration,
				expiration_time: parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('beverage_expiration', this.state.beverage_expiration);
		})
	}
	// ==========================

	// Beverage Ingredients
	// ==========================
	editIngredients(beverage_ingredients) {
		this.setState({
			beverage_ingredients
		}, () => {
			this.props.setMetaData('beverage_ingredients', this.state.beverage_ingredients);
		});
	}
	// ==========================

	// Beverage Measurement
	// ==========================
	setMeasurementUnit(value) {
		this.setState(prevState => ({
			beverage_measurement: {
				...prevState.beverage_measurement,
				unit: value
			}
		}), () => {
			this.props.setMetaData('beverage_measurement', this.state.beverage_measurement);
		})
	}

	setGrossWeightMeasurementUnit(value) {
		this.setState(prevState => ({
			beverage_gross_weight_measurement: {
				...prevState.beverage_gross_weight_measurement,
				unit: value
			}
		}), () => {
			this.props.setMetaData('beverage_gross_weight_measurement', this.state.beverage_gross_weight_measurement);
		})
	}

	setMeasurementValue({ target }) {
		this.setState(prevState => ({
			beverage_measurement: {
				...prevState.beverage_measurement,
				value: parseFloat(target.value)
			}
		}), () => {
			this.props.setMetaData('beverage_measurement', this.state.beverage_measurement);
		})
	}

	setGrossWeightMeasurementValue({ target }) {
		this.setState(prevState => ({
			beverage_gross_weight_measurement: {
				...prevState.beverage_gross_weight_measurement,
				value: target.value === "" ? "" : parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('beverage_gross_weight_measurement', this.state.beverage_gross_weight_measurement);
		})
	}
	// ==========================

	// Beverage Nutrition Values
	// ==========================
	setNutritionType(value, id) {
		this.setState(prevState => ({
			beverage_nutrition_values: prevState.beverage_nutrition_values.map((obj) =>
				(obj.id === id ?
					Object.assign(obj, { type: value })
					:
					Object.assign(obj)
				)
			)
		}), () => {
			this.props.setMetaData('beverage_nutrition_values', this.state.beverage_nutrition_values);
		});
	}

	setNutritionUnit(value, id) {
		this.setState(prevState => ({
			beverage_nutrition_values: prevState.beverage_nutrition_values.map((obj) =>
				(obj.id === id ?
					Object.assign(obj, { unit: value })
					:
					Object.assign(obj)
				)
			)
		}), () => {
			this.props.setMetaData('beverage_nutrition_values', this.state.beverage_nutrition_values);
		});
	}

	setNutritionValue(value, id) {
		this.setState(prevState => ({
			beverage_nutrition_values: prevState.beverage_nutrition_values.map((obj) =>
				(obj.id === id ?
					Object.assign(obj, { value: parseInt(value) })
					:
					Object.assign(obj)
				)
			)
		}), () => {
			this.props.setMetaData('beverage_nutrition_values', this.state.beverage_nutrition_values);
		});
	}

	removeAlertNutrition(type) {
		this.setState({
			deleteAlert: true,
			removeType: type
		})
	}

	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			removeType: ''
		})
	}

	removeNutritionData() {
		var type = this.state.removeType;
		const beverage_nutrition_values = this.state.beverage_nutrition_values.filter(item => item.type !== type);
		this.setState({
			beverage_nutrition_values
		}, () => {
			this.props.setMetaData('beverage_nutrition_values', beverage_nutrition_values);
			this.setState({
				deleteAlert: false,
				removeType: ''
			})
		});

	}

	editNewNutritionValue(flag, value) {
		this.setState(prevState => ({
			beverage_nutrition_new: {
				...prevState.beverage_nutrition_new,
				[flag]: value
			}
		}))
	}

	editValueNewNutrition({ target }) {
		this.setState(prevState => ({
			beverage_nutrition_new: {
				...prevState.beverage_nutrition_new,
				value: parseFloat(target.value)
			}
		}))
	}

	addNewNutritionValueToList() {
		if (this.state.beverage_nutrition_new.type !== "" &&
			this.state.beverage_nutrition_new.unit !== ""
		) {
			this.setState({
				beverage_nutrition_values: this.state.beverage_nutrition_values.concat(this.state.beverage_nutrition_new)
			}, () => {
				this.props.setMetaData('beverage_nutrition_values', this.state.beverage_nutrition_values);
				this.setState({
					beverage_nutrition_new: {
						type: "",
						unit: "",
						value: 0,
						id: "",
					}
				})
			})
		}
	}
	// ==========================

	// Beverage Packaging
	// ==========================
	setPackaging(value) {
		this.setState({
			beverage_packaging: value
		}, () => {
			this.props.setMetaData('beverage_packaging', this.state.beverage_packaging);
		})
	}
	// ==========================

	render() {
		const {
			beverage_allergens,
			beverage_ingredients,
			beverage_packaging_list,
			beverage_nutrition_values,
			beverage_unit,
			beverage_nutrition_list,
			beverage_nutrition_new,
			beverage_time_list
		} = this.state;

		return (
			<div className="content-block-main" id="product-food-metadata">
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Nutrition Value"
						message="You are attempting to delete a nutrition value from the table. Are you sure you want to proceed?"
						handleMainFunction={this.removeNutritionData}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}
				<div className="input-block-titled two">
					<h2 className="input-block-title">Beverage Expiration</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Time Measurment"
							mapFunction={mapTimeMeasurements}
							list={beverage_time_list}
							handleSelectChange={this.setExpiration}
							value={mapTimeMeasurements(this.props.meta !== null ? !this.isEmpty(this.props.meta.beverage_expiration) ? this.props.meta.beverage_expiration.expiration_measurement : "" : "")}
							errorMessage="Time measurment is required"
							error={this.props.errors.includes("beverage_expiration")}
							className=""
						/>
						<p className={"input-holder half " + (this.props.errors.includes("beverage_expiration") ? "error-active" : "")}>
							<input
								className="input-field--labeled"
								id="food-expiration-time"
								min="1"
								type="number"
								name="expiration_time"
								value={!this.isEmpty(this.props.meta) ? this.props.meta.beverage_expiration.expiration_time : 0}
								onChange={this.setExpirationTime}
								required
							/>
							<label className="field-label" htmlFor="food-expiration-time">Time Value</label>
							<span className="input-error">Time value is required</span>
							<span className="input-border"></span>
						</p>
					</div>
				</div>
				<div className="input-block-titled two">
					<h2 className="input-block-title">Beverage Measurment</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Unit"
							mapFunction={mapUnits}
							list={beverage_unit}
							handleSelectChange={this.setMeasurementUnit}
							value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.beverage_measurement) ? this.props.meta.beverage_measurement.unit : "" : "")}
							className=""
						/>
						<p className="input-holder half">
							<input
								className="input-field--labeled"
								id="food-measurement"
								min="1"
								type="number"
								name="food_measurement"
								value={!this.isEmpty(this.props.meta) ? this.props.meta.beverage_measurement.value : 0}
								onChange={this.setMeasurementValue}
								required
							/>
							<label className="field-label" htmlFor="food-measurement">Value</label>
							<span className="input-border"></span>
						</p>
					</div>
				</div>
				<div className="input-block-titled two">
					<h2 className="input-block-title">Gross Weight</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Unit"
							mapFunction={mapUnits}
							list={beverage_unit}
							handleSelectChange={this.setGrossWeightMeasurementUnit}
							value={mapUnits(
								this.props.meta ? 
								!this.isEmpty(this.props.meta.beverage_gross_weight_measurement) ? 
									this.props.meta.beverage_gross_weight_measurement.unit : 
									"" : 
								"")
							}
							className=""
						/>
						<p className="input-holder half">
							<input
								className="input-field--labeled"
								id="beverage_gross_weight_measurement"
								min="1"
								type="number"
								name="beverage_gross_weight_measurement"
								value={!this.isEmpty(this.props.meta) ? this.props.meta.beverage_gross_weight_measurement.value : 0}
								onChange={this.setGrossWeightMeasurementValue}
								required
							/>
							<label className="field-label" htmlFor="beverage_gross_weight_measurement">Value</label>
							<span className="input-border"></span>
						</p>
					</div>
				</div>
				<div className="input-block-titled one select">
					<h2 className="input-block-title">Beverage Alergens</h2>

					<div className="input-grid">
						<p className="rounded-data-holder tags color orange">
							<span className="data-title">Alergens</span>
							<span className="data-line">
								{beverage_allergens.map(allergen =>
									<span className="single-data-item" key={allergen}>{mapAlergens(allergen.toUpperCase())}<button className="remove-button" onClick={() => this.handleAllergenClose(allergen)}></button></span>
								)}
								<ButtonDropDown
									handler=""
									value=""
									mapFunction={mapAlergens}
									title="Add new alergen"
									list={this.state.beverage_allergens_list}
									handleSelectChange={(allergen) => this.handleAllergenInputChange(allergen)}
									disabled={true}
								/>
							</span>
						</p>
					</div>
				</div>
				<div className="input-block-titled one select">
					<h2 className="input-block-title">Beverage Ingredients</h2>

					<div className="input-grid">
						<p className="rounded-data-holder tags color orange">
							<span className="data-title">Ingredients</span>
							<ProductTags
								tags={beverage_ingredients}
								editTags={this.editIngredients}
								title="Add new ingredient"
							/>
						</p>
					</div>
				</div>
				<div className="input-block-titled two">
					<h2 className="input-block-title">Beverage Packaging</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Packaging"
							mapFunction={mapPackaging}
							list={beverage_packaging_list}
							handleSelectChange={this.setPackaging}
							value={mapPackaging(!this.isEmpty(this.props.meta) ? this.props.meta.beverage_packaging : "select packaging")}
							className=""
						/>
					</div>
				</div>
				<div className="input-block-titled select four">
					<h2 className="input-block-title">Beverage Nutrition Values</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Type"
							mapFunction={mapNutriTypes}
							list={beverage_nutrition_list}
							handleSelectChange={(value) => this.editNewNutritionValue("type", value)}
							value={mapNutriTypes(beverage_nutrition_new.type)}
							className=""
						/>
						<DropDown
							disabled={false}
							title="Unit"
							mapFunction={mapUnits}
							list={beverage_unit}
							handleSelectChange={(value) => this.editNewNutritionValue("unit", value)}
							value={mapUnits(beverage_nutrition_new.unit)}
							className=""
						/>
						<p className="input-holder half">
							<input
								className="input-field--labeled"
								id="food-nutrition-value"
								min="1"
								type="number"
								name="value"
								value={parseFloat(beverage_nutrition_new.value)}
								onChange={(value) => this.editValueNewNutrition(value)}
								required
							/>
							<label className="field-label" htmlFor="food-nutrition-value">Value</label>
							<span className="input-border"></span>
						</p>
						<button
							className="circle-button icon dark add-new-nutirition-value-button"
							onClick={this.addNewNutritionValueToList}
						></button>
					</div>
					<div className="content-block-table">
						<table className="minimal-table">
							<thead className="minimal-table-head">
								<tr>
									<th>Name</th>
									<th>Unit</th>
									<th>Value</th>
								</tr>
							</thead>
							<tbody className="minimal-table-body">
								{(beverage_nutrition_values !== undefined && beverage_nutrition_values.length > 0) && beverage_nutrition_values.map((val, i) =>
									<tr key={i}>
										<td>{mapNutriTypes(val.type)}</td>
										<td>{mapUnits(val.unit)}</td>
										<td>{val.value}</td>
										<td><button className="ghost-icon-button delete-button" onClick={() => this.removeAlertNutrition(val.type)}></button></td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>

			</div>
		);
	}
}

export default ProductBeverageMeta;