export function setCurrencies(data) {
    return {
        type: 'SET_CURRENCIES',
        payload: data
    }
}

export function setBadges(data) {
    return {
        type: 'SET_BADGES',
        payload: data
    }
}

export function setProductTypes(data) {
    return {
        type: 'SET_TYPES',
        payload: data
    }
}

export function setCategories(data) {
    return {
        type: 'SET_CATEGORIES',
        payload: data
    }
}

export function setVendors(data) {
    return {
        type: 'SET_VENDORS',
        payload: data
    }
}

export function setManufacturers(data) {
    return {
        type: 'SET_MANUFACTURERS',
        payload: data
    }
}

export function setUserData(data) {
    return {
        type: 'SET_USER_DATA',
        payload: data
    }
}

export function setProductCount(data) {
    return {
        type: 'SET_PRODUCT_COUNT',
        payload: data
    }
}

export function setOrderCount(data) {
    return {
        type: 'SET_ORDER_COUNT',
        payload: data
    }
}

export function setSelected(data) {
    return {
        type: 'SET_SELECTED',
        payload: data
    }
}

export function navName(data) {
    return {
        type: 'SET_NAVIGATION_NAME',
        payload: data
    }
}

export function setUsersCount(data) {
    return {
        type: 'SET_USERS_COUNT',
        payload: data
    }
}

export function setWarehouseCount(data) {
    return {
        type: 'SET_WAREHOUSE_COUNT',
        payload: data
    }
}

export function setPagesCount(data) {
    return {
        type: 'SET_PAGES_COUNT',
        payload: data
    }
}

export function setFormsCount(data) {
    return {
        type: 'SET_FORMS_COUNT',
        payload: data
    }
}

export function setCountries(data) {
    return {
        type: 'SET_COUNTRIES',
        payload: data
    }
}