import React, { Component } from 'react';
import { formatDateByLocaleString } from "../../../../assets/helper";
import Datetime from "react-datetime";

class ProductPromotions extends Component {
	constructor(props) {
		super(props);

		this.availableFromLabelRef = null;
		this.availableToLabelRef = null;
	}

	disabledDate(current) {
		var yesterday = Datetime.moment().subtract(1, 'day');
		return current.isAfter(yesterday);
	}

	handleAvailableFromChange = (value) => {
		if (value && typeof value === 'object') {
			this.props.handlePromotionDateChange(value, 'available_from');
			this.availableFromLabelRef.classList.add("active-date-label");
		} else {
			this.availableFromLabelRef.classList.remove("active-date-label")
		}
	}

	handleAvailableToChange = (value) => {
		if (value && typeof value === 'object') {
			this.props.handlePromotionDateChange(value, 'available_to');
			this.availableToLabelRef.classList.add("active-date-label");
		} else {
			this.availableToLabelRef.classList.remove("active-date-label")
		}
	}

	render() {
		return (
			<div className="full-block one promotional-price">
				<div className="content-block">
					<div className="content-block-intro">
						<h2 className="content-block-title"> <span className="content-block-icon price"></span> Promotional Pricing</h2>
						{this.props.promotionValidateError.isVisible &&
							<span className="promotion-error-massage">
								<h4>{this.props.promotionValidateError.message}</h4>
							</span>}
					</div>

					<div className="content-block-main">
						<div className={`content-block-header ${this.props.promotionValidateError.isVisible ? 'border-error' : ''}`}>
							<div className="select-holders">
								<p className="input-holder half">
									<input
										className="input-field--labeled"
										id="promotion-price"
										min="1"
										type="number"
										name="price"
										value={this.props.newPromotion.price}
										onChange={this.props.handlePromotionInputNumberChange}
										required
									/>
									<label className="field-label" htmlFor="promotion-price">Price</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder half">
									<input
										className="input-field--labeled"
										id="promotion-percentage"
										min="1"
										type="number"
										name="percentage"
										value={this.props.newPromotion.percentage}
										onChange={this.props.handlePromotionInputNumberChange}
										required
									/>
									<label className="field-label" htmlFor="promotion-percentage">Percentage</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder date-holder">
									<Datetime
										dateFormat={"DD/MM/YYYY"}
										inputProps={{
											readOnly: true,
											id: "pdetail-promotion-available-from",
											onClick: () => {
												this.availableFromLabelRef.classList.add("active-date-label")
											},
											onBlur: (e) => {
												if (e.target.value !== "") {
													this.availableFromLabelRef.classList.add("active-date-label");
												} else {
													this.availableFromLabelRef.classList.remove("active-date-label")
												}
											}
										}}
										value={new Date(this.props.newPromotion.available_from)}
										locale={"mk"}
										timeFormat={false}
										input={true}
										disableOnClickOutside={true}
										closeOnSelect={true}
										isValidDate={this.disabledDate}
										onChange={this.handleAvailableFromChange}
									/>
									<label ref={e => this.availableFromLabelRef = e} className={`field-label ${this.props.newPromotion.available_from !== '' && 'active-date-label'}`} htmlFor="pdetail-promotion-available-from">Available From</label>
								</p>
								<p className="input-holder date-holder">
									<Datetime
										dateFormat={"DD/MM/YYYY"}
										inputProps={{
											readOnly: true,
											id: "pdetail-promotion-available-to",
											onClick: () => {
												this.availableToLabelRef.classList.add("active-date-label")
											},
											onBlur: (e) => {
												if (e.target.value !== "") {
													this.availableToLabelRef.classList.add("active-date-label");
												} else {
													this.availableToLabelRef.classList.remove("active-date-label")
												}
											}
										}}
										value={new Date(this.props.newPromotion.available_to)}
										locale={"mk"}
										timeFormat={false}
										input={true}
										disableOnClickOutside={true}
										isValidDate={this.disabledDate}
										closeOnSelect={true}
										onChange={this.handleAvailableToChange}
									/>
									<label ref={e => this.availableToLabelRef = e} className={`field-label ${this.props.newPromotion.available_to !== '' && 'active-date-label'}`} htmlFor="pdetail-promotion-available-to">Available To</label>
								</p>
							</div>
							<button className="circle-button icon dark add-new-nutirition-value-button" onClick={this.props.addNewPromotion}></button>
						</div>

						<div className="content-block-table">
							<table className="minimal-table">
								<thead className="minimal-table-head">
									<tr>
										<th>Price</th>
										<th>Percentage</th>
										<th>Available From</th>
										<th>Available To</th>
										<th>Created</th>
										<th></th>
									</tr>
								</thead>

								<tbody className="minimal-table-body">
									{this.props.promotion.map((item, i) =>
										<tr key={i} className={`${!item.hasOwnProperty('id') ? 'new-entry-animate' : ''}`}>
											<td>{item.price.toFixed(2)}</td>
											<td>{item.percentage}</td>
											<td>{formatDateByLocaleString(item.available_from, 'en-GB')}</td>
											<td>{formatDateByLocaleString(item.available_to, 'en-GB')}</td>
											<td>{formatDateByLocaleString(item._created, 'en-GB')}</td>
											{!item.hasOwnProperty('id')
												?
												<td><button onClick={() => this.props.deleteUnsavedPromotion(i)} className="ghost-icon-button delete-button"></button></td>
												:
												<td></td>
											}
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ProductPromotions;