import React, { Component } from "react";
import "../css/Tabs.css";

class Tabs extends Component {

	constructor(props) {
		super(props);

		this.state = {
			selected: this.props.selected || 0
		}
	}

	SelectTab = (i) => {
		this.setState({ selected: i })
	}

	render() {
		return (
			<div id="tabs">
				<div className="tabs-container">
					<div className="tab-titles">
						{this.props.children.map((elem, i) => {
							let style = i == this.state.selected ? 'active' : '';
							return <div className={"tab-title " + (style)} key={i} onClick={() => this.SelectTab(i)}><h2>{elem.props.title}</h2></div>
						})}
					</div>
					<div className="tab-content">{this.props.children[this.state.selected]}</div>
				</div>
			</div>
		)
	}
}

export default Tabs;