const initialState = {
	allProducts:[],
	productsMap: {},
    selectedProduct: {},
    selectedPrices: [],
    selectedPromotions: [],
    alert: {
        type: '',
        text: ''
    },
    filter: {
        name: '',
        type: '',
        barcode: '',
        vendor: '',
        vendor_name: '',
        manufacturer: '',
        manufacturer_name: '',
        category: '',
        category_name: '',
        global: 'active',
        active: false,
        ipid: '',
        warehouse_position: ''
    }
};

const product = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SELECTED_PRODUCT':
            return Object.assign({}, state, {
                ...state,
                selectedProduct: action.payload
            });
        case 'SET_ALERT_TYPE':
            return Object.assign({}, state, {
                ...state,
                alert: {
                    type: action.payload.type,
                    text: action.payload.text
                }
            });
        case 'SET_FILTER_DATA':
            return Object.assign({}, state, {
                ...state,
                filter: action.payload
            });
        case 'SET_PRODUCT_PRICES':
            return Object.assign({}, state, {
                ...state,
                selectedPrices: action.payload
            });
        case 'SET_PRODUCT_PROMOTIONS':
            return Object.assign({}, state, {
                ...state,
                selectedPromotions: action.payload
            });
        case 'SET_PRODUCT_LIST':
            return Object.assign({}, state, {
                ...state,
				allProducts: action.payload,
            });
        case 'SET_PRODUCT_MAP':
            return Object.assign({}, state, {
                ...state,
				productsMap: action.payload
            });
        default:
            return state;
    }
}

export default product;