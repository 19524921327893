import React, { Component } from "react";
import "../css/ConfirmAlert.css";

class ConfirmAlert extends Component {
	render() {
		return (
			<div id="alert" className="confirm-alert">
				<div className="alert-overlay"></div>

				<div className="alert-container">
					<div className="alert-header">
						<h2 className="alert-header-title">{this.props.title}</h2>
						<button className="icon-button close-alert-button" onClick={this.props.handleSeconderyFunction}></button>
					</div>

					<div className="alert-data">
						<p className="alert-data-description">{this.props.message}</p>
					</div>

					<div className="alert-actions">
						<button className="rounded-button ghost save-button" onClick={this.props.handleSeconderyFunction}><span className="button-text">Cancel</span></button>
						<button className="rounded-button primary primary-icon gradient darkblue delete-button" onClick={this.props.handleMainFunction}><span className="button-text">Delete</span></button>
					</div>
				</div>
			</div>
		)
	}
}

export default ConfirmAlert;