import React from "react";
import "../css/EmptyStateDisclaimer.css";

const EmptyStateDisclaimer = (props) => {
	return (
		<span className="empty-state-container">
			<span className="content-centered">
				<span className={"centered-icon " + props.icon}></span>
				<h2 className="centered-title">{props.message}</h2>
			</span>
		</span>
	)
}

export default EmptyStateDisclaimer;