import React, { Component } from 'react';
import "../css/Companies.css";
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, Input } from 'antd';
import oblax from "../../../assets/oblax";
import { setCompanies } from "../actions";
import { Loader, DropDown, ConfirmAlert } from '../../../parts';
import { setFilterData } from "../actions";
import { navName } from "../../Home/actions";


class Companies extends Component {
	constructor() {
		super()
		this.state = {
			filter: '',
			loader: true,
			companies: [],
			deleteAlert: false,
			deleteCompanyId: ''
		}

		this.filterChange = this.filterChange.bind(this);
		this.filterChangeMain = this.filterChangeMain.bind(this);
		this.getCompanies = this.getCompanies.bind(this);
		this.addNewCompanie = this.addNewCompanie.bind(this);
		this.CompanyTypeClass = this.CompanyTypeClass.bind(this);
		this.deleteCompany = this.deleteCompany.bind(this);
		this.deleteAlert = this.deleteAlert.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);
	}

	componentWillMount() {
		this.setState({
			filter: this.props.filter
		})
		this.props.navName('');
		this.getCompanies();
	}

	getCompanies() {
		return oblax.companies.getAll()
			.then((result) => {
				if (result.ok) {
					this.setState({
						companies: result.data,
						loader: false
					}, () => {
						this.props.setCompanies(result.data)
					})
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("companies ERROR", result.state);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("companies ERROR: ", err);
				}
			});
	}

	filterChange({ target }) {
		if (target.name === "active") {
			this.setState(prevState => {
				return {
					filter: {
						...prevState.filter,
						[target.name]: !prevState.filter.active
					}
				}
			})
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: target.value
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	getCompanieDetails(data) {
		//GET VENDOR DETAILS
		this.props.history.push(`/e-shop/companies/${data.id}`);
	}

	addNewCompanie() {
		this.props.history.push("/e-shop/companies/new");
	}

	filterChange({ target }) {
		if (target.name === "active") {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: !prevState.filter.active
					}
				}), () => {
					if (this.state.filter.active) {
						this.props.setFilterData(this.state.filter);
					} else {
						this.setState({
							filter: {
								name: '',
								global: '',
								active: false
							}
						}, () => {
							this.props.setFilterData(this.state.filter);
						})

					}
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: target.value
					}
				}), () => {
					this.props.setFilterData(this.state.filter)
				}
			)
		}
	}

	filterChangeMain(data) {
		if (this.props.filter.global === data) {
			var value = ''
		} else {
			var value = data
		}
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					global: value
				}
			}), () => {
				this.props.setFilterData(this.state.filter)
			}
		)
	}

	CompanyTypeClass(c) {
		let classes = [''];
		c.tags.map((item, i) => classes.push(item.replace(',', ' ')));
		return classes.join(' ');
	}

	deleteAlert(id) {
		this.setState({
			deleteAlert: true,
			deleteCompanyId: id
		});
	}

	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			deleteCompanyId: ''
		})
	}

	deleteCompany() {
		oblax.companies.deleteCompany(this.state.deleteCompanyId)
			.then((result) => {
				if (result.ok) {
					this.setState({
						deleteAlert: false,
						deleteCompanyId: ''
					}, () => {
						this.getCompanies();
					});
				} else {
					this.setState({
						deleteAlert: false,
						deleteCompanyId: ''
					});
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("companies ERROR", result.state);
					}
				}
			})
			.catch(err => {
				this.setState({
					deleteAlert: false,
					deleteCompanyId: ''
				});
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("companies ERROR: ", err);
				}
			});

	}

	render() {
		const CompanyType = (c) => {
			return (
				c.type.tags.map((item, key) => <span title={item} key={key} className={"bc-card-description " + (item)}>{item.slice(0, 1)}</span>)
			)
		}

		return (
			<div id="companies" className="products">
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Company"
						message="You are attempting to delete a company from the database. Are you sure you want to proceed?"
						handleMainFunction={this.deleteCompany}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}
				<div className="table-filter users-title-search">
					<div className="filter-row main">
						<div className="filter-item main">
							<button className="circle-button light icon filter-icon" onClick={this.filterChange} name="active"></button>
							<div className="filter-buttons">
								<ul>
									<li className={this.props.filter.global === "" ? "active" : ""} onClick={() => this.filterChangeMain("")}><span>All</span></li>
									<li className={this.props.filter.global === "vendor" ? "active" : ""} onClick={() => this.filterChangeMain("vendor")}><span>Vendors</span></li>
									<li className={this.props.filter.global === "manufacturer" ? "active" : ""} onClick={() => this.filterChangeMain("manufacturer")}><span>Manufacturers</span></li>
								</ul>
							</div>
						</div>

						<div className="filter-item">
							<button
								className="rounded-button primary primary-icon gradient orange new-product-button"
								onClick={this.addNewCompanie}
							>
								<span className="button-text">New Company</span>
							</button>
						</div>
					</div>
					{this.props.filter.active &&
						<div className="filter-row detail">
							<p className="input-holder">
								<input
									type="text"
									className="input-field--labeled"
									id="filter-company-name"
									onChange={this.filterChange}
									name="name"
									value={this.props.filter.name}
									required
								/>
								<label className="field-label" htmlFor="filter-company-name">Company name</label>
								<span className="input-border"></span>
							</p>
						</div>
					}
				</div>
				<div className="bc-cards-container">
					{this.state.loader ?
						<div className="companies-loader">
							<Loader />
						</div>
						:
						this.state.companies
							.filter(items => (
								this.props.filter.global !== '' ?
									items.tags && items.tags.includes(this.props.filter.global) &&
									items.short_name && items.short_name.toLowerCase().includes(this.props.filter.name)
									:
									items.tags && items.tags &&
									items.short_name && items.short_name.toLowerCase().includes(this.props.filter.name)
							))
							.map((item) =>
								<div key={item.id} className={"bc-card" + (this.CompanyTypeClass(item))}>
									<div className="bc-card-content" onClick={() => this.getCompanieDetails(item)}>
										<h2 className="bc-card-title">{item.short_name}</h2>
										<CompanyType type={item} />
									</div>
									<div className="bc-card-actions">
										<button onClick={() => this.getCompanieDetails(item)} className="icon-button edit"></button>
										<button onClick={() => this.deleteAlert(item.id)} className="icon-button delete-button"></button>
									</div>
								</div>
							)
					}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		companies: state.company.companies,
		filter: state.company.filter
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setCompanies: (data) => {
			dispatch(setCompanies(data))
		},
		setFilterData: (data) => {
			dispatch(setFilterData(data))
		},
		navName: (data) => {
            dispatch(navName(data));
        }
	};
}



// export default Main;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Companies));