const initialState = {
	companies: [],
	filter: {
		name: '',
		global: '',
		active: false
	},
	selectedCompany: {}
};

const company = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_COMPANIES':
			return Object.assign({}, state, {
				...state,
				companies: action.payload
			});
		case 'SET_FILTER_COMPANY_DATA':
			return Object.assign({}, state, {
				...state,
				filter: action.payload
			});
		case 'SET_SELECTED_COMPANY':
			return Object.assign({}, state, {
				...state,
				selectedCompany: action.payload
			});
		default:
			return state;
	}
}

export default company;