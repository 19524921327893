import React from "react";
import "../css/Card.css";

const Card = (props) => {
	return (
		<div className={"single-card " + (props.className)}>
			<button className="remove-card">remove</button>
			<h2 className="card-title">{props.value}</h2>
			<div className="card-description">
				<span className="card-icon"></span>
				<span className="card-icon-title">{props.for}</span>
			</div>
		</div>
	)
}

export default Card;