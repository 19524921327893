import React, { Component } from "react";
import { connect } from "react-redux";
// import { Input, Menu, Dropdown, Button, Icon } from "antd";
import oblax from "../../../assets/oblax";
// import moment from 'moment';
import { formatDate, badgeType, isEmpty } from "../../../assets/helper";
import { TreeSelect } from "antd";
import "../css/Products.css";

// COMPONENTS
import { DropDown, Loader, Toast, EmptyStateDisclaimer, ConfirmAlert } from "../../../parts";
import { setAlertType, setFilterData, setProductList } from "../actions";
import { UTILITIES } from "../../../assets/constants";
import { setProductCount, navName } from "../../Home/actions"

const TreeNode = TreeSelect.TreeNode;

class Products extends Component {
	constructor() {
		super();
		this.state = {
			name: '',
			type: '',
			products: [],
			loader: true,
			alert: false,
			productSettings: '',
			deleteAlert: false,
			selectedProductId: ''
		}

		this.handleMenuClick = this.handleMenuClick.bind(this);
		this.filterChange = this.filterChange.bind(this);
		this.filterChangeMain = this.filterChangeMain.bind(this);
		this.addNewProduct = this.addNewProduct.bind(this);
		this.getAllProducts = this.getAllProducts.bind(this);
		this.availabilityEnds = this.availabilityEnds.bind(this);
		this.selectedSettings = this.selectedSettings.bind(this);
		this.closeSelectSettings = this.closeSelectSettings.bind(this);
		this.deleteProduct = this.deleteProduct.bind(this);
		this.deleteAlert = this.deleteAlert.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);
	}

	componentDidMount() {
		this.setState({
			filter: this.props.filter
		});

		if (this.props.alert.text !== '') {
			this.setState({
				alert: true
			}, () => {
				setTimeout(() => {
					var alert = {
						type: '',
						text: ''
					}
					this.setState({
						alert: false
					})
					this.props.setAlertType(alert);

				}, UTILITIES.alertTime);
			})
		}
		this.props.navName('');
		this.getAllProducts()
	}

	getAllProducts() {
		return oblax.products.getAll(true)
			.then((result) => {
				if (result.ok) {
					this.setState({
						products: result.data,
						loader: false
					}, () => {
						this.props.setProductCount(this.state.products.length);
						this.props.setProductList(this.state.products);
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PRODUCTS_ERROR: ", result.status);
					}
					this.setState({
						products: [],
						loader: true
					});
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PRODUCTS_ERROR: ", err);
				}
				this.setState({
					products: [],
					loader: true
				});
			});
	}

	handleMenuClick(e) {
		this.setState({
			type: e.key
		})
	}

	getProductDetails(data) {
		this.props.history.push(`/e-shop/products/${data.id}`);
	}

	filterChange({ target }) {
		if (target.name === "active") {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: !prevState.filter.active
					}
				}), () => {
					if (this.state.filter.active) {
						this.props.setFilterData(this.state.filter);
					} else {
						this.setState({
							filter: {
								name: '',
								type: '',
								barcode: '',
								vendor: '',
								vendor_name: '',
								manufacturer: '',
								manufacturer_name: '',
								category: '',
								category_name: '',
								global: 'active',
								active: false,
								ipid: '',
								warehouse_position: ''
							}
						}, () => {
							this.props.setFilterData(this.state.filter);
						})

					}
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: target.value
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	ShowFilerOptions = () => {
		this.setState(prevState => ({
			filter: {
				...prevState.filter,
				active: !prevState.filter.active
			}
		}), () => {
			this.props.setFilterData(this.state.filter);
		})
	}

	filterChangeMain(data) {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					global: data
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	filterChangeDrop = (flag, value) => {
		if (flag === 'vendor') {
			if (value !== '') {
				var v = this.props.vendors.filter(item => item.id === value)
				this.setState(
					prevState => ({
						filter: {
							...prevState.filter,
							vendor: v[0].id,
							vendor_name: v[0].short_name
						}
					}), () => {
						this.props.setFilterData(this.state.filter);
					}
				)
			} else {
				this.setState(
					prevState => ({
						filter: {
							...prevState.filter,
							vendor: '',
							vendor_name: ''
						}
					}), () => {
						this.props.setFilterData(this.state.filter);
					}
				)
			}
		}

		if (flag === 'manufacturer') {
			if (value !== '') {
				var m = this.props.manufacturers.filter(item => item.id === value);
				this.setState(
					prevState => ({
						filter: {
							...prevState.filter,
							manufacturer: m[0].id,
							manufacturer_name: m[0].short_name
						}
					}), () => {
						this.props.setFilterData(this.state.filter);
					}
				)
			} else {
				this.setState(
					prevState => ({
						filter: {
							...prevState.filter,
							manufacturer: '',
							manufacturer_name: ''
						}
					}), () => {
						this.props.setFilterData(this.state.filter);
					}
				)
			}
		}

		if (flag === 'type') {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						type: value
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}

	}

	HandleCategoryFilter = (value, data) => {
		if (value !== '') {
			var m = data.filter(item => item.id === value);
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						category: m[0].id,
						category_name: m[0].name
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						category: '',
						category_name: ''
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	addNewProduct() {
		this.props.history.push("/e-shop/products/new");
	}

	availabilityEnds(date) {
		var today = new Date();
		const cmas = new Date(date);
		const one_day = 1000 * 60 * 60 * 24;

		if ((Math.ceil((cmas.getTime() - today.getTime()) / (one_day))) > 3) {
			return true;
		} else {
			return false;
		}
	}

	selectedSettings(id) {
		if (this.state.productSettings !== id) {
			this.setState({
				productSettings: id
			})
		} else {
			this.setState({
				productSettings: ''
			})
		}
	}

	closeSelectSettings() {
		this.setState({
			productSettings: ''
		})
	}

	deleteAlert(id) {
		this.setState({
			deleteAlert: true,
			selectedProductId: id
		});
	}

	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			selectedProductId: ''
		})
	}

	deleteProduct() {
		oblax.products.deleteProduct(this.state.selectedProductId)
			.then((result) => {
				if (result.ok) {
					this.setState({
						deleteAlert: false,
						selectedProductId: ''
					}, () => {
						this.getAllProducts();
					});
				} else {
					this.setState({
						deleteAlert: false,
						selectedProductId: ''
					});
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("DELETE ERROR: ", result.status);
					}
				}
			})
			.catch(err => {
				this.setState({
					deleteAlert: false,
					selectedProductId: ''
				});
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("DELETE ERROR: ", err);
				}
			});
	}

	render() {
		const FilteredProductsList = this.state.products.filter(items => {
			switch (this.props.filter.global.toLowerCase()) {
				case "out_of_stock":
					return (
						items.quantity === 0 &&
						items.name && items.name.toLowerCase().includes(this.props.filter.name.toLowerCase()) &&
						items.barcode && items.barcode.includes(this.props.filter.barcode) &&
						items.vendor_id && items.vendor_id.includes(this.props.filter.vendor) &&
						items.manufacturer_id && items.manufacturer_id.includes(this.props.filter.manufacturer) &&
						items.category.some(el => el.id.includes(this.props.filter.category)) && 
						items.active === true && 
						items.ipid && items.ipid.includes(this.props.filter.ipid) &&
						items.warehouse_position && items.warehouse_position.includes(this.props.filter.warehouse_position)
					)
				case "inactive":
					return (
						items.quantity > 0 &&
						items.name && items.name.toLowerCase().includes(this.props.filter.name.toLowerCase()) &&
						items.barcode && items.barcode.includes(this.props.filter.barcode) &&
						items.vendor_id && items.vendor_id.includes(this.props.filter.vendor) &&
						items.manufacturer_id && items.manufacturer_id.includes(this.props.filter.manufacturer) &&
						items.category.some(el => el.id.includes(this.props.filter.category)) &&
						items.active === false &&
						items.ipid && items.ipid.includes(this.props.filter.ipid) &&
						items.warehouse_position && items.warehouse_position.includes(this.props.filter.warehouse_position) &&
						(items.promotion_price === null || items.promotion_percentage === null)
					)
				case "active":
					return (
						items.quantity > 0 &&
						items.name && items.name.toLowerCase().includes(this.props.filter.name.toLowerCase()) &&
						items.barcode && items.barcode.includes(this.props.filter.barcode) &&
						items.vendor_id && items.vendor_id.includes(this.props.filter.vendor) &&
						items.manufacturer_id && items.manufacturer_id.includes(this.props.filter.manufacturer) &&
						items.category.some(el => el.id.includes(this.props.filter.category)) &&
						items.active === true &&
						items.ipid && items.ipid.includes(this.props.filter.ipid) &&
					    items.warehouse_position && items.warehouse_position.includes(this.props.filter.warehouse_position) &&
						(items.promotion_price === null || items.promotion_percentage === null)
					)
				case "on_promotion":
					return (
						items.quantity > 0 &&
						items.name && items.name.toLowerCase().includes(this.props.filter.name.toLowerCase()) &&
						items.barcode && items.barcode.includes(this.props.filter.barcode) &&
						items.vendor_id && items.vendor_id.includes(this.props.filter.vendor) &&
						items.manufacturer_id && items.manufacturer_id.includes(this.props.filter.manufacturer) &&
						items.category.some(el => el.id.includes(this.props.filter.category)) &&
						items.active === true &&
						items.ipid && items.ipid.includes(this.props.filter.ipid) &&
						items.warehouse_position && items.warehouse_position.includes(this.props.filter.warehouse_position) &&
						(items.promotion_price !== null || items.promotion_percentage !== null)
					)
			}
		});

		const categories = (arr) => {
			var flatten = [];
			for (var i = 0; i < arr.length; i++) {
				if (Array.isArray(arr[i].children)) {
					flatten = flatten.concat(categories(arr[i].children));
					if (arr[i].hasOwnProperty("children")) {
						flatten.push(arr[i]);
					}
				} else {
					flatten.push(arr[i]);
				}
			}
			return flatten;
		}

		const MetaMeasurment = (product) => {
			switch (product.type) {
				case "food":
					return (
						<h2 className="table-classic-data">{product.meta.food_measurement.value}<span className="currency">{product.meta.food_measurement.unit.toLowerCase()}</span></h2>
					)
				case "beverage":
					return (
						<h2 className="table-classic-data">{product.meta.beverage_measurement.value} <span className="currency">{product.meta.beverage_measurement.unit.toLowerCase()}</span></h2>
					)
				case "cosmetics":
					return (
						<h2 className="table-classic-data">{product.meta.cosmetics_measurement.value} <span className="currency">{product.meta.cosmetics_measurement.unit.toLowerCase()}</span></h2>
					)
				case "personal_hygiene":
					return (
						<h2 className="table-classic-data">{product.meta.personal_hygiene_measurement.value} <span className="currency">парчиња</span></h2>
					)
				case "cleaning":
					return (
						<h2 className="table-classic-data">{product.meta.cleaning_measurement.value} <span className="currency">{product.meta.cleaning_measurement.unit.toLowerCase()}</span></h2>
					)
			}
		}

		return (
			<div id="products-table" >
				{this.state.alert &&
					<Toast type={this.props.alert.type} description={this.props.alert.text} />}

				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Product"
						message="You are attempting to delete a product from the database. Are you sure you want to proceed?"
						handleMainFunction={this.deleteProduct}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}

				<div className="products-table-container">
					<div className="table-filter">
						<div className="filter-row main">
							<div className="filter-item main">
								<button className="circle-button light icon filter-icon" onClick={this.ShowFilerOptions} name="active"></button>
								<div className="filter-buttons">
									<ul>
										<li className={this.props.filter.global === "active" ? "active" : ""} onClick={() => this.filterChangeMain("active")}><span>Active</span></li>
										<li className={this.props.filter.global === "inactive" ? "active" : ""} onClick={() => this.filterChangeMain("inactive")}><span>Inactive</span></li>
										<li className={this.props.filter.global === "on_promotion" ? "active" : ""} onClick={() => this.filterChangeMain("on_promotion")}><span>On Promotion</span></li>
										<li className={this.props.filter.global === "out_of_stock" ? "active" : ""} onClick={() => this.filterChangeMain("out_of_stock")}><span>Out of stock</span></li>
									</ul>
								</div>
							</div>
							<div className="filter-item">
								<button
									className="rounded-button primary primary-icon gradient orange new-product-button"
									onClick={this.addNewProduct}
								>
									<span className="button-text">New product</span>
								</button>
							</div>
						</div>
						{this.props.filter.active &&
							<div className="filter-row detail">
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-product-name"
										onChange={this.filterChange}
										name="name"
										value={this.props.filter.name}
										required
									/>
									<label className="field-label" htmlFor="filter-product-name">Product name</label>
									<span className="input-border"></span>
								</p>
								<DropDown
									handler="name"
									value={this.props.filter.category_name}
									title="Category"
									list={categories(this.props.categories)}
									handleSelectChange={(value) => this.HandleCategoryFilter(value, categories(this.props.categories))}
									className=""
								/>
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-product-ipid"
										onChange={this.filterChange}
										name="ipid"
										value={this.props.filter.ipid}
										required
									/>
									<label className="field-label" htmlFor="filter-product-ipid">Internal Id</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-product-warehouse-position"
										onChange={this.filterChange}
										name="warehouse_position"
										value={this.props.filter.warehouse_position}
										required
									/>
									<label className="field-label" htmlFor="filter-product-warehouse-position">Warehouse position</label>
									<span className="input-border"></span>
								</p>
								<DropDown
									value={this.props.filter.vendor_name}
									handler="short_name"
									title="Vendor"
									list={this.props.vendors}
									handleSelectChange={(value) => this.filterChangeDrop('vendor', value)}
									className=""
								/>
								<DropDown
									handler="short_name"
									value={this.props.filter.manufacturer_name}
									title="Manufacturer"
									list={this.props.manufacturers}
									handleSelectChange={(value) => this.filterChangeDrop('manufacturer', value)}
									className=""
								/>
								{/* <TreeSelect
                                        showSearch
                                        style={{ width: 300 }}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder={isEmpty(this.props.filter.category) ? undefined : this.props.filter.category[this.props.filter.category.length - 1].name}
                                        allowClear
                                        treeDefaultExpandAll
                                        onChange={(value) => this.filterChangeDrop('category', value)}
                                    >
                                    {this.props.categories.map((item) =>
                                        <TreeNode value={`${item.id}-parent`} title={item.name} key={item.id}>
                                            {item.children !== undefined && item.children.map((child) =>
                                                <TreeNode value={`${child.id}-child`} title={child.name} key={child.id} />
                                            )}
                                        </TreeNode>
                                    )}
                                </TreeSelect> */}
							</div>
						}
					</div>
					<div className="products-table-wrap">
						<table className="bc-table">
							<thead className="bc-table-head">
								<tr>
									<th>Internal ID</th>
									<th>Name</th>
									<th>Size</th>
									<th>Price</th>
									<th>In Stock</th>
									<th>Vendor</th>
									<th>Purchase Price</th>
									<th>Margin</th>
									<th>Category</th>
									<th></th>
								</tr>
							</thead>
							<tbody className="bc-table-body">
								{this.state.loader ?
									<tr>
										<td>
											<Loader />
										</td>
									</tr>
									:
									FilteredProductsList.length > 0 ?
										FilteredProductsList.map((item) =>
											<tr onClick={this.closeSelectSettings} className="bc-table-row" key={item.id}>
												<td onClick={() => this.getProductDetails(item)}>
													<span className="internal_id">{item.ipid}</span>
												</td>
												<td onClick={() => this.getProductDetails(item)}>
													<h2 className="product-title">{item.name}</h2>
													<h4 className="product-sub">{item.manufacturer_name}</h4>
												</td>
												<td className="bc-table-data" onClick={this.closeSelectSettings}>
													<MetaMeasurment type={item.type} meta={item.meta} />
												</td>
												<td className="bc-table-data price" onClick={this.closeSelectSettings}>
													<h2 className="table-classic-data">{Math.trunc(item.price)} <span className="currency">{item.price_currency}</span></h2>
												</td>
												<td className="td-centered bc-table-data" onClick={this.closeSelectSettings}>
													<h2 className="table-color-data">{item.quantity}</h2>
												</td>
												<td className="bc-table-data vendor" onClick={this.closeSelectSettings}>
													<h2 className="table-classic-data">{item.vendor_name}</h2>
												</td>
												<td className="bc-table-data price" onClick={this.closeSelectSettings}>
													<h2 className="table-classic-data">{item.purchase_price} <span className="currency">{item.price_currency}</span></h2>
												</td>
												<td className="bc-table-data price" onClick={this.closeSelectSettings}>
													<h2 className="table-classic-data">{Math.trunc(((item.price / item.purchase_price) * 100) - 100)}%</h2>
												</td>
												<td className="bc-table-data" onClick={this.closeSelectSettings}>
													<h2 className="table-classic-data">{item.category.length > 1 ? item.category[1].name : item.category[0].name}</h2>
												</td>
												<td className="bc-table-data">
													<button className="square-button light icon settings-icon" onClick={() => this.selectedSettings(item.id)}></button>
													<div id="table-settings-dropdown" className={this.state.productSettings === item.id ? "opened" : ""}>
														<ul className="dropdown-list">
															<li>
																<a onClick={() => this.getProductDetails(item)}>
																	<span className="dropdown-icon edit"></span>
																	<h2 className="dropdown-title">Edit</h2>
																</a>
															</li>
															<li>
																<a onClick={() => this.deleteAlert(item.id)}>
																	<span className="dropdown-icon delete"></span>
																	<h2 className="dropdown-title">Delete</h2>
																</a>
															</li>
														</ul>
													</div>
												</td>
											</tr>
										)

										:

										<tr>
											<td colSpan="8">
												<EmptyStateDisclaimer
													message="No such info on our shuttle"
													icon="rocket"
												/>
											</td>
										</tr>
								}
							</tbody>
						</table>
					</div>
				</div>
			</div >
		);
	}
}

const mapStateToProps = (state) => {
	return {
		productTypes: state.home.productTypes,
		vendors: state.company.companies.filter(item => item.tags.includes('vendor')),
		manufacturers: state.company.companies.filter(item => item.tags.includes('manufacturer')),
		alert: state.product.alert,
		filter: state.product.filter,
		categories: state.home.categories
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAlertType: (data) => {
			dispatch(setAlertType(data))
		},
		setFilterData: (data) => {
			dispatch(setFilterData(data))
		},
		setProductCount: (data) => {
			dispatch(setProductCount(data))
		},
		setProductList: (data) => {
			dispatch(setProductList(data))
		},
		navName: (data) => {
			dispatch(navName(data));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Products);