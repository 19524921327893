import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../css/Warehouse.css";
import oblax from "../../../assets/oblax";
import { Receiving } from "@oblax/sdk-js";
import { DropDown, Loader, Toast, EmptyStateDisclaimer, ConfirmAlert } from "../../../parts";
import { navName } from "../../Home/actions";
import { isEmpty, formatDate, ArrayIsEmpty } from "../../../assets/helper";
import { setProductsInReceiver, setAlertType } from "../actions";
import placeholder from "../../../assets/images/person-placeholder.png";
import { UTILITIES } from "../../../assets/constants";

class RecivingDetails extends Component {
	constructor() {
		super();
		this.state = {
			_errors: [],
			products: [],
			ipid: '',
			iiid: '',
			selectedProduct: '',
			deleteAlert: false,
			selectedReceivingID: '',

			quantity: 1,
			price_with_tax: 0,
			tax: 0,
			price: 0,
			total: 0,

			selectedReceiver: {},
			productObject: {},
			receiving: {},
			loader: true,
			importApproved: false,

			users: [],
			usersMap: {},
			vendor: "",
			product: "",

			vendors: [],
			vendorsMap: {},

			edit: true,

			filteredProductViaIpID: [],
			warehouseImportProducts: [],
			filterViaVendor: false,
			chosenProduct: {},

			alert: {
				type: '',
				text: '',
				trigger: false
			},
			purchasePriceSum: []
		}

		this.getAllProducts = this.getAllProducts.bind(this);
		this.onInputChange = this.onInputChange.bind(this);
		this.getVendorName = this.getVendorName.bind(this);
		this.getSelectedReceiver = this.getSelectedReceiver.bind(this);
	}

	componentDidMount() {
		this.getAllProducts();
		this.GetUsers();
		this.GenerateVendorsMap();
		var url = window.location.href.split('warehouse/');
		if (url[1] === "new") {
			this.setState({
				addNewWarehouse: true,
				edit: false
			}, () => {
				this.props.navName('Import Warehouse');
			})
		} else {
			this.props.navName(this.getVendorName(this.props.match.params.id));
			this.getSelectedReceiver(this.props.match.params.id);
			this.GetReceivingById(this.props.match.params.id);
		}
	}

	activateAlert = (type, text) => {
		this.setState({
			alert: {
				type: type,
				text: text,
				trigger: true
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					alert: {
						type: "",
						text: "",
						trigger: false
					}
				})
			}, UTILITIES.alertTime);
		});
	}

	HandleVendorFilter = (val) => {
		var m = this.props.vendors.filter(item => item.id === val);
		var p = this.props.allProducts.filter(el => el.vendor_id === val);
		if (val !== '') {
			this.setState({
				filterViaVendor: true,
				chosenVendor: val,
				vendor: m[0].short_name,
				filteredProductsByVendorId: p
			});
		} else {
			this.setState({
				filterViaVendor: false,
				chosenVendor: "val",
				vendor: "",
				chosenProduct: {},
				filteredProductsByVendorId: []
			});
		}
	}

	HandleProductChange = (val) => {
		let p = this.props.allProducts.filter(el => el.id === val);
		if (val !== '') {
			this.setState({
				filterViaVendor: true,
				chosenProduct: p[0],
				product: p[0].name
			});
		} else {
			this.setState({
				filterViaVendor: true,
				chosenProduct: {},
				product: ""
			});
		}
	}

	getVendorName(id) {
		var vendor = this.props.vendors.filter(item => item.id === id);
		if (vendor.length > 0) {
			return vendor[0].short_name
		}
		return '';
	}

	getAllProducts() {
		return oblax.products.getAll(true)
			.then((result) => {
				console.log("TCL: Warehouse -> getAllProducts -> result", result)
				if (result.ok) {
					this.setState({
						products: result.data,
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PRODUCTS WAREHOUSE ERROR: ", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PPRODUCTS WAREHOUSE ERROR 2: ", err);
				}
			});
	}

	onInputChange({ target }) {
		this.setState({
			[target.name]: target.value
		}, () => {
			if (target.name === 'ipid') {
				var product = this.state.products.filter(item => item.ipid === this.state.ipid)
				this.setState({
					selectedProduct: product[0].name,
					price: product[0].price,
					productObject: product[0]
				})
			}
		})
	}

	filterChangeDrop = (value) => {
		var product = this.state.products.filter(item => item.id === value)
		if (product[0] !== undefined) {
			this.setState({
				ipid: product[0].ipid,
				price: product[0].price,
				selectedProduct: product[0].name
			})
		}

	}

	getSelectedReceiver(id) {
		for (var key in this.props.new_receivers) {
			if (this.props.new_receivers[key].vendor_id === id) {
				this.setState({
					selectedReceiver: this.props.new_receivers[key]
				})
			}
		}
	}

	addNewProduct = () => {
		var r = this.state.selectedReceiver;
		var data = {
			product_id: this.state.productObject.id,
			ipid: this.state.ipid,
			price: this.state.price,
			quantity: parseInt(this.state.quantity)
		}

		r.products.push(data);
		this.props.setProductsInReceiver(r);
	}

	GetReceivingById = (id) => {

		let arr = [];

		return oblax.warehouse.getReceivingById(id)
			.then((params) => {
				if (params.ok) {

					for (var i = 0; i < params.data.products.length; i++) {
						if (this.props.allProducts[this.props.productsMap[params.data.products[i].product_id]] !== undefined) {
							let purcahse_price_data = this.props.allProducts[this.props.productsMap[params.data.products[i].product_id]].purchase_price * params.data.products[i].quantity;
							arr.push(purcahse_price_data)
						}
					}

					console.log("GetReceivingById -> arr", arr)

					this.setState({
						receiving: params.data,
						loader: false,
						importApproved: params.data.approved_by !== "" ? true : false,
						purchasePriceSum: arr
					})
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("SAVE WAREHOUSE DATA ERROR", params.status);
						this.setState({
							receiving: {},
							loader: false,
							importApproved: false
						})
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("SAVE WAREHOUSE DATA ERROR 2: ", err)
					this.setState({
						receiving: {},
						loader: false,
						importApproved: false
					})
				}
			});
	}

	GenerateVendorsMap = () => {
		return oblax.companies.getAll()
			.then((params) => {
				if (params.ok) {
					console.log("Warehouse -> GenerateVendorsMap -> params", params);
					let vendorsMap = {};

					for (let i in params.data) {
						vendorsMap[params.data[i].id] = parseInt(i);
					}

					this.setState({
						vendors: params.data,
						vendorsMap: vendorsMap,
						loader: false
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log('ERROR: ', params.status)
					}
					this.setState({
						loader: false,
						vendors: [],
						vendorsMap: {}
					});
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ERROR: ", err);
				}
			});
	}

	GetUsers = () => {
		oblax.users.getAll()
			.then((params) => {
				if (params.ok) {
					let usersMap = {};

					for (let i in params.data) {
						usersMap[params.data[i].id] = parseInt(i);
					}

					this.setState({
						users: params.data,
						usersMap: usersMap,
						loader: false
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log('ERROR: ', params.status)
					}
					this.setState({
						loader: false,
						users: [],
						usersMap: {}
					});
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ERROR: ", err);
				}
			});
	}

	FilterProductViaBIpID = (e) => {
		this.setState({
			ipid: e.target.value,
			filterViaVendor: false,
			chosenVendor: "",
			vendor: ""
		})

		for (let i in this.props.allProducts) {
			this.setState({
				filteredProductViaIpID: this.props.allProducts.filter(items => (items.ipid === e.target.value))
			}, () => {
                // console.log("FilterProductViaBIpID -> filteredProductViaIpID", this.state.filteredProductViaIpID)
			});
		}
	}

	AddProductToWarehouseImport = (item) => {
		let product = {
			product_id: this.state.filteredProductViaIpID[0].id,
			name: this.state.filteredProductViaIpID[0].name,
			vendor_id: this.state.filteredProductViaIpID[0].vendor_id,
			price: this.state.filteredProductViaIpID[0].price,
			quantity: item.quantity,
			tax: 0,
			price_with_tax: 0,
			total_purchase_price: item.quantity * this.state.filteredProductViaIpID[0].purchase_price,
			total_selling_price: item.quantity * this.state.filteredProductViaIpID[0].price,
		}

		this.setState(prevState => ({
			filteredProductViaIpID: [],
			ipid: "",
			warehouseImportProducts: [
				product,
				...prevState.warehouseImportProducts
			],
		}))
	}

	AddProductToWarehouseImportViaVendor = (item) => {
		let tax_decimal = (item.price_tax / 100) + 1;
		let product = {
			product_id: this.state.chosenProduct.id,
			name: this.state.chosenProduct.name,
			vendor_id: this.state.chosenProduct.vendor_id,
			price: this.state.chosenProduct.price,
			quantity: item.quantity,
			tax: item.price_tax,
			price_with_tax: Math.trunc(item.price / tax_decimal),
			total_purchase_price: item.quantity * item.purchase_price,
			total_selling_price: item.quantity * item.price
		}

		this.setState(prevState => ({
			chosenProduct: {},
			ipid: "",
			product: "",
			warehouseImportProducts: [
				product,
				...prevState.warehouseImportProducts
			],
		}))
	}

	PriceWithTaxHandle = (e, pID) => {
		const { value } = e.target;
		let data = this.state.warehouseImportProducts;
		const targetIndex = data.findIndex(item => {
			return item.product_id == pID;
		});

		if (targetIndex !== -1) {
			data[targetIndex].tax = value !== "" ? parseInt(value) : 0;
			data[targetIndex].price_with_tax = data[targetIndex].tax !== 0 ? parseInt(data[targetIndex].price) * (parseInt(value) / 100) : 0;
			this.setState({
				data
			});
		}
	}

	QuantityHandle = (e, pID) => {
		const { value } = e.target;
		let data = this.state.warehouseImportProducts;
		const targetIndex = data.findIndex(item => {
			return item.product_id == pID;
		});
		
		console.log("QuantityHandle -> pID", pID)
		if (targetIndex !== -1 && this.props.allProducts[this.props.productsMap[pID]] !== undefined) {
			data[targetIndex].quantity = value !== "" ? parseInt(value) : 0;
			data[targetIndex].total_selling_price = data[targetIndex].quantity !== 1 ? data[targetIndex].quantity * data[targetIndex].price : 1 * data[targetIndex].price;
			data[targetIndex].total_purchase_price = data[targetIndex].quantity !== 1 ? data[targetIndex].quantity * this.props.allProducts[this.props.productsMap[pID]].purchase_price : 1 * this.props.allProducts[this.props.productsMap[pID]].purchase_price;
			this.setState({
				data
			}, () => {
                console.log("QuantityHandle -> data", this.state.warehouseImportProducts)
			});
		}
	}

	WarehouseTotalPriceOnChange = () => {
		this.setState({
			total: this.state.quantity * this.state.price
		})
	}

	ImportProducts = () => {
		this.setState({
			loader: true
		})

		var r = new Receiving();

		r.loadFromJSON({
			vendor_id: this.state.warehouseImportProducts[0].vendor_id,
			status: "NEW",
			approved_by: null,
			received_by: null,
			documents: null,
			products: this.state.warehouseImportProducts,
			iiid: this.state.iiid
		});

		return oblax.warehouse.insertReceiving(r)
			.then((params) => {
				if (params.ok) {
					this.setState(prevState => ({
						edit: !prevState.edit,
						loader: false
					}), () => {
						this.props.history.push(`/e-shop/warehouse/${params.data.id}`);
						this.getSelectedReceiver(this.props.match.params.id);
						this.GetReceivingById(params.data.id);
						this.activateAlert("success", "You have successfully imported a receiving into the warehouse.");
					})
					console.log("ImportProducts -> params", params);
				} else {
					this.activateAlert("error", "There was an error with the import. Please refresh and try again.");
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("SAVE WAREHOUSE DATA ERROR", params.status);
					}
				}
			})
			.catch(err => {
				this.activateAlert("error", "There was an error with the import. Please refresh and try again.");
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("SAVE WAREHOUSE DATA ERROR 2: ", err);
				}
			});
	}

	RemoveProductFromWarehouse = (pID) => {
		this.setState({
			warehouseImportProducts: this.state.warehouseImportProducts.filter(el => el.product_id !== pID)
		})
	}

	deleteAlert = (id) => {
		this.setState({
			deleteAlert: true,
			selectedReceivingID: id
		});
	}

	cancelDeleteAlert = () => {
		this.setState({
			deleteAlert: false,
			selectedReceivingID: ''
		})
	}

	deleteReceiving = () => {
		oblax.warehouse.deleteReceiving(this.state.selectedReceivingID)
			.then((result) => {
				if (result.ok) {
					var alert = {
						type: "success",
						text: "You succesfully removed a receiving from the warehouse."
					}
					this.props.setAlertType(alert);
					this.setState({
						deleteAlert: false,
						selectedReceivingID: ''
					}, () => {
						this.props.history.push("/e-shop/warehouse");
					});
				} else {
					this.setState({
						deleteAlert: false,
						selectedReceivingID: ''
					});
					var alert = {
						type: "error",
						text: "There was an error in removing this receiving from the warehouse. Refresh and try again."
					}
					this.props.setAlertType(alert);
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("companies ERROR", result.state);
					}
				}
			})
			.catch(err => {
				this.setState({
					deleteAlert: false,
					selectedReceivingID: ''
				});
				var alert = {
					type: "error",
					text: "There was an error in removing this receiving from the warehouse. Refresh and try again."
				}
				this.props.setAlertType(alert);
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("companies ERROR: ", err);
				}
			});
	}

	ApproveReceiving = () => {
		return oblax.warehouse.updateReceivingStatus(this.state.receiving.id, "VERIFIED")
			.then((params) => {
				if (params.ok) {
					console.log("ApproveReceiving -> params", params);
					this.activateAlert("success", "You have successfully approved this receiving.");
					this.setState({
						importApproved: true
					});
				} else {
					this.activateAlert("error", "There was a problem with approving this receiving. Refresh and try again.");
					this.setState({
						importApproved: true
					});
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("companies ERROR", params.state);
					}
				}
			})
			.catch(err => {

				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("companies ERROR: ", err);
				}
			});
	}

	HandleIiid = (e) => {
		this.setState({
			iiid: e.target.value
		})
	}

	render() {
		console.log("STATE => ", this.state);

		return (
			<div id="warehouse-details" className="two-block-content">
				{this.state.alert.trigger &&
					<Toast type={this.state.alert.type} description={this.state.alert.text} />
				}
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Receiving"
						message="You are attempting to delete this receiving from the warehouse. Are you sure you want to proceed?"
						handleMainFunction={this.deleteReceiving}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}
				{
					!this.state.edit ?

						<div className="bc-details-container">
							<div className="full-block two">
								<div className="content-block light product-search filter">
									<div className="content-block-intro">
										<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
											Search Products</h2>
									</div>

									<div className="content-block-main">
										<DropDown
											value={this.state.vendor}
											handler="short_name"
											title="Vendor"
											list={this.props.vendors}
											handleSelectChange={(value) => this.HandleVendorFilter(value)}
											className=""
										/>

										<p className={"input-holder half " + (this.state._errors.includes("ipid") ? "error-active" : "")}>
											<input
												className="input-field--labeled"
												id="product-in-stock-quantity"
												type="text"
												name="ipid"
												value={this.state.ipid}
												onChange={this.FilterProductViaBIpID}
												required
											/>
											<label className="field-label" htmlFor="product-in-stock-quantity">Internal Product ID</label>
											<span className="input-error">IPID is required</span>
											<span className="input-border"></span>
										</p>
									</div>

									<div className="content-block-intro iiid-data">
										<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
											Warehouse Import Details</h2>
									</div>

									<div className="content-block-main">
										<p className={"input-holder full " + (this.state._errors.includes("iiid") ? "error-active" : "")}>
											<input
												className="input-field--labeled"
												id="internal-import-id"
												type="text"
												name="iiid"
												value={this.state.iiid}
												onChange={this.HandleIiid}
												required
											/>
											<label className="field-label" htmlFor="internal-import-id">Internal Import ID</label>
											<span className="input-error">Internal Import ID is required</span>
											<span className="input-border"></span>
										</p>
									</div>
								</div>
								<div className="content-block dark product-search border product-chooser">
									{this.state.filterViaVendor ?
										<div className={"content-block-main " + (ArrayIsEmpty(this.state.filteredProductsByVendorId) ? "disabled" : "")}>
											<DropDown
												value={this.state.product}
												handler="name"
												title="Choose a product"
												list={!ArrayIsEmpty(this.state.filteredProductsByVendorId) ? this.state.filteredProductsByVendorId : [{ name: "No products by this vendor" }]}
												handleSelectChange={(value) => this.HandleProductChange(value)}
												className=""
											/>

											{
												!isEmpty(this.state.chosenProduct) ?
													<div className="content-block-main">
														<div className="cbm-single avatar-title">
															<div className="cbm-single-avatar-holder">
																{
																	this.state.chosenProduct.hasOwnProperty('images') ? <img src={`${process.env.REACT_APP_MINIO_GATEWAY}products/${this.state.chosenProduct.images[0].path}`} onError={(e) => { e.target.src = placeholder }} alt="Product" /> : <img src={placeholder} onError={(e) => { e.target.src = placeholder }} alt="Product" />
																}
															</div>
															<div className="cbm-single-data">
																<h2>{this.state.chosenProduct.name}  <span>{Math.trunc(this.state.chosenProduct.price)} <span className="price-curency">mkd</span></span> </h2>
																<h4>{this.state.vendors[this.state.vendorsMap[this.state.chosenProduct.vendor_id]].short_name}</h4>
															</div>
														</div>

														<div className="cbm-single button">
															<button className="rounded-button primary primary-icon gradient orange create-button" onClick={() => this.AddProductToWarehouseImportViaVendor(this.state.chosenProduct)}>
																<span className="button-text">Add Product</span></button>
														</div>
													</div> :
													<div className="empty-centered-data">
														Your chosen product will appear here.
													</div>
											}
										</div> :
										!ArrayIsEmpty(this.state.filteredProductViaIpID) ?
                                        
											<div className="content-block-main">
												<div className="cbm-single avatar-title">
													<div className="cbm-single-avatar-holder">
														{
															this.state.filteredProductViaIpID[0].hasOwnProperty("images") ?
															<img src={`${process.env.REACT_APP_MINIO_GATEWAY}products/${this.state.filteredProductViaIpID[0].images[0].path}`} onError={(e) => { e.target.src = placeholder }} alt="Product" /> : <img
																src={placeholder}
																alt="Product image"
																onError={(e) => { e.target.src = placeholder }}
															/>
														}
													</div>
													<div className="cbm-single-data">
														<h2>{this.state.filteredProductViaIpID[0].name}  <span>{Math.trunc(this.state.filteredProductViaIpID[0].price)} <span className="price-curency">mkd</span></span> </h2>
														<h4>{this.state.vendors[this.state.vendorsMap[this.state.filteredProductViaIpID[0].vendor_id]].short_name}</h4>
													</div>
												</div>

												<div className="cbm-single button">
													<button className="rounded-button primary primary-icon gradient orange create-button" onClick={() => this.AddProductToWarehouseImport(this.state.filteredProductViaIpID[0])}>
														<span className="button-text">Add Product</span></button>
												</div>
											</div> :

											<div className="empty-centered-data">
												The product you searched will appear here
											</div>}
								</div>
							</div>

							<div className="full-block one price">
								<div className="content-block light product-search">
									<div className="content-block-intro">
										<h2 className="content-block-title"> <span className="content-block-icon price"></span> Products</h2>
									</div>

									<div className="content-block-main">
										<div className="content-block-table">
											<table className="minimal-table">
												<thead className="minimal-table-head">
													<tr>
														<th>Product name</th>
														<th>Quantity</th>
														<th>Price (with tax)</th>
														<th>Tax (%)</th>
														<th>Price</th>
														<th>Total (purchase)</th>
														<th>Total (selling)</th>
														<th className="remove-td"></th>
													</tr>
												</thead>
												<tbody className="minimal-table-body">
													{!ArrayIsEmpty(this.state.warehouseImportProducts) ?
														this.state.warehouseImportProducts.map((item, i) =>
															<tr key={item.product_id}>
																<td>{
																	item !== undefined ? item.name : <span>&#9432; Can't find product name</span>
																}</td>
																<td>
																	<p className="input-holder">
																		<input
																			type="text"
																			className="input-field--labeled"
																			id="filter-product-name"
																			onChange={(event) => this.QuantityHandle(event, item.product_id)}
																			name="quantity"
																			value={item.quantity}
																			// value={item.quantity}
																			required
																		/>
																		<span className="input-border"></span>
																	</p>
																</td>
																<td>{item.price_with_tax} <span>mkd</span></td>
																<td>{item.tax}</td>
																<td>{Math.trunc(item.price)} <span>mkd</span></td>
																<td>{Math.trunc(item.total_purchase_price)} <span>mkd</span></td>
																<td>{Math.trunc(item.total_selling_price)} <span>mkd</span></td>
																{!item.hasOwnProperty('id')
																	?
																	<td className="remove-td"><button onClick={() => this.RemoveProductFromWarehouse(item.product_id)} className="ghost-icon-button delete-button"></button></td>
																	:
																	<td></td>
																}
															</tr>
														)
														: null}
												</tbody>
											</table>
										</div>
									</div>

									{!ArrayIsEmpty(this.state.warehouseImportProducts) ?
										<div className="block-footer">
											<div className="single-footer-block">
												<h2>Total number of products: <span className="sfb-value">{this.state.warehouseImportProducts.length}</span></h2>
											</div>
											<div className="single-footer-block">
												<h2>Grand total (purchase price): <span className="sfb-value">
													{this.state.warehouseImportProducts.length <= 1 ?
														Math.trunc(this.state.warehouseImportProducts[0].total_purchase_price) : this.state.warehouseImportProducts.reduce((sum, a) => {
															return Math.trunc(sum + a.total_purchase_price);
														}, 0)
													} <span>mkd</span>
												</span>
												</h2>
											</div>
											<div className="single-footer-block">
												<h2>Grand total (selling price): <span className="sfb-value">
													{this.state.warehouseImportProducts.length <= 1 ?
														Math.trunc(this.state.warehouseImportProducts[0].total_selling_price) : this.state.warehouseImportProducts.reduce((sum, a) => {
															return Math.trunc(sum + a.total_selling_price);
														}, 0)
													} <span>mkd</span>
												</span>
												</h2>
											</div>
										</div> : null}
								</div>
							</div>
						</div> :
						this.state.loader &&
							(!ArrayIsEmpty(this.state.users) || Object.keys(this.state.usersMap).length) &&
							(!ArrayIsEmpty(this.state.vendors) || Object.keys(this.state.vendorsMap).length) &&
							!isEmpty(this.state.receiving) &&
							(!isEmpty(this.props.productsMap) || !ArrayIsEmpty(this.props.allProducts)) ?
							<Loader /> :
							<div className="bc-details-container">
								<div className="full-block one">
									<div className="content-block light product-search">
										<div className="content-block-intro">
											<h2 className="content-block-title"> <span className="content-block-icon general-information"></span>
												Warehouse Import Details</h2>
										</div>

										<div className="content-block-main">
											<div className="single-item vendor">
												<h2 className="si-intro">Vendor</h2>
												{
													this.state.vendors[this.state.vendorsMap[this.state.receiving.vendor_id]] !== undefined ?
														<h2 className="si-title">{this.state.vendors[this.state.vendorsMap[this.state.receiving.vendor_id]].short_name}</h2> : <h2 className="si-title no-data">No vendor data</h2>
												}
											</div>
											<div className="single-item user">
												<h2 className="si-intro">Imported by</h2>
												{
													this.state.users[this.state.usersMap[this.state.receiving.received_by]] !== undefined ?
														<h2 className="si-title">{this.state.users[this.state.usersMap[this.state.receiving.received_by]].first_name} {this.state.users[this.state.usersMap[this.state.receiving.received_by]].last_name}</h2> : <h2 className="si-title no-data">No Import Data</h2>
												}
											</div>
											<div className="single-item date">
												<h2 className="si-intro">Created on</h2>
												<h2 className="si-title">{
													this.state.receiving.hasOwnProperty("_created") ?
														formatDate(this.state.receiving._created).toString() : "No Date Data"}</h2>
											</div>
											<div className="single-item product-num">
												<h2 className="si-intro">Number of products</h2>
												<h2 className="si-title">{this.state.receiving.products_count}</h2>
											</div>
											<div className="single-item total-cost">
												<h2 className="si-intro">Total cost</h2>
												<h2 className="si-title">{Math.trunc(this.state.receiving.total_price)} <span>mkd</span> </h2>
											</div>
											<div className="single-item total-cost">
												<h2 className="si-intro">Internal Import ID</h2>
												<h2 className="si-title">{this.state.receiving.iiid !== "" ? this.state.receiving.iiid : "No data found"}</h2>
											</div>
										</div>
									</div>
								</div>

								<div className="full-block one price">
									<div className="content-block light product-search">
										<div className="content-block-intro">
											<h2 className="content-block-title"> <span className="content-block-icon price"></span> Products</h2>
										</div>

										<div className="content-block-main">
											<div className="content-block-table">
												<table className="minimal-table">
													<thead className="minimal-table-head">
														<tr>
															<th>Product name</th>
															<th>Quantity</th>
															<th>Price (with tax)</th>
															<th>Tax (%)</th>
															<th>Price</th>
															<th>Total (purchase)</th>
															<th>Total (selling)</th>
															<th className="remove-td"></th>
														</tr>
													</thead>
													<tbody className="minimal-table-body">
														{
															!isEmpty(this.state.receiving) && this.state.receiving ?
																this.state.receiving.products.map((item, i) =>
																	<tr key={item.id}>
																		<td>{
																			this.props.allProducts[this.props.productsMap[item.product_id]] !== undefined ? this.props.allProducts[this.props.productsMap[item.product_id]].name : <span>&#9432; Can't find product name</span>
																		}</td>
																		<td>{item.quantity}</td>
																		<td>{item.price_with_tax} <span>mkd</span></td>
																		<td>{this.props.allProducts[this.props.productsMap[item.product_id]] !== undefined ? this.props.allProducts[this.props.productsMap[item.product_id]].price_tax : <span>&#9432; Can't find product tax</span>}</td>
																		<td>{Math.trunc(item.price)} <span>mkd</span></td>
																		<td>{
																			this.props.allProducts[this.props.productsMap[item.product_id]] !== undefined ?
																				Math.trunc(item.quantity * this.props.allProducts[this.props.productsMap[item.product_id]].purchase_price) :
																				<span>&#9432; Can't find product purchase price</span>
																		} <span>mkd</span></td>
																		<td>{Math.trunc(item.quantity * item.price)} <span>mkd</span></td>
																		{!item.hasOwnProperty('id')
																			?
																			<td><button onClick={() => this.props.deleteUnsavedPrice(i)} className="ghost-icon-button delete-button"></button></td>
																			:
																			<td></td>
																		}
																	</tr>
																) : null}
													</tbody>
												</table>
											</div>
										</div>

										{!isEmpty(this.state.receiving) && this.state.receiving ? <div className="block-footer">
											<div className="single-footer-block">
												<h2>Total number of products: <span className="sfb-value">{this.state.receiving.products.length}</span></h2>
											</div>
											<div className="single-footer-block">
												<h2>Grand total (purchase price): <span className="sfb-value">
													{
														this.state.purchasePriceSum.length <= 0 ? "Can't find data" : this.state.purchasePriceSum.reduce((sum, a) => {
															return Math.trunc(sum + a);
														}, 0)
													}
													<span> mkd</span>
												</span>
												</h2>
											</div>
											<div className="single-footer-block">
												<h2>Grand total (selling price): <span className="sfb-value">
													{Math.trunc(this.state.receiving.total_price)}
													<span> mkd</span>
												</span>
												</h2>
											</div>
										</div> : null}
									</div>
								</div>
							</div>
				}

				{this.state.edit ?
					<div className="action-buttons">
						<button className="rounded-button primary primary-icon gradient pink cancel-button approve" onClick={() => this.deleteAlert(this.state.receiving.id)}>
							<span className="button-text" >Delete Import</span> </button>
						{!this.state.importApproved ?
							<button className="rounded-button primary primary-icon gradient orange save-button approve" onClick={this.ApproveReceiving}>
								<span className="button-text" >Approve Import</span></button> : null}
					</div>
					:
					<div className="action-buttons">
						{
							!ArrayIsEmpty(this.state.warehouseImportProducts) ?
								<button className="rounded-button primary primary-icon gradient blue save-button import" onClick={this.ImportProducts}>
									<span className="button-text" >Import Products</span></button> : null
						}
					</div>
				}
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		vendors: state.company.companies,
		new_receivers: state.warehouse.newReceivings,
		allProducts: state.product.allProducts,
		productsMap: state.product.productsMap,
		alert: state.product.alert,
		warehouseImportProducts: state.warehouse.warehouseImportProducts
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setAlertType: (data) => {
			dispatch(setAlertType(data))
		},
		navName: (data) => {
			dispatch(navName(data));
		},
		setProductsInReceiver: (data) => {
			dispatch(setProductsInReceiver(data));
		}
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RecivingDetails);