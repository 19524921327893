import React, { Component } from 'react';
import oblax from '../../../../assets/oblax';

import { DropDown, ButtonDropDown } from "../../../../parts";
import { ProductTags } from "../Parts";
import { mapUnits, mapAlergens, mapNutriTypes, mapPackaging, mapTimeMeasurements } from "../../../../assets/helper";
import { ConfirmAlert } from "../../../../parts";

class ProductFoodMeta extends Component {
	constructor(props) {
		super(props);

		this.state = {
			food_allergens: [],
			// allergensInputVisible: false,
			// allergensInputValue: '',

			food_ingredients: [],
			// ingredientsInputVisible: false,
			// ingredientsInputValue: '',


			food_expiration: {},
			food_measurement: {},
			food_gross_weight_measurement: {},
			food_nutrition_values: [],
			food_packaging: '',

			food_nutrition_new: {
				type: "",
				unit: "",
				value: 0,
				id: "",
			},

			food_unit: [],
			food_allergens_list: [],
			food_nutrition_list: [],
			food_packaging_list: [],
			food_time_list: [],

			deleteAlert: false,
			removeType: ''
		}

		//Get data from api 
		this.getMeasurements = this.getMeasurements.bind(this);

		// Food Allergens
		this.handleAllergenClose = this.handleAllergenClose.bind(this);
		this.handleAllergenInputChange = this.handleAllergenInputChange.bind(this);

		// Food Expiration
		this.setExpiration = this.setExpiration.bind(this);
		this.setExpirationTime = this.setExpirationTime.bind(this);

		// Food Ingredients
		this.editIngredients = this.editIngredients.bind(this);

		// Food Measurement
		this.setMeasurementUnit = this.setMeasurementUnit.bind(this);
		this.setMeasurementValue = this.setMeasurementValue.bind(this);
		this.setGrossWeightMeasurementUnit = this.setGrossWeightMeasurementUnit.bind(this);
		this.setGrossWeightMeasurementValue = this.setGrossWeightMeasurementValue.bind(this);

		// Food Nutruition
		this.setNutritionType = this.setNutritionType.bind(this);
		this.setNutritionUnit = this.setNutritionUnit.bind(this);
		this.setNutritionValue = this.setNutritionValue.bind(this);
		this.editNewNutritionValue = this.editNewNutritionValue.bind(this);
		this.editValueNewNutrition = this.editValueNewNutrition.bind(this);
		this.addNewNutritionValueToList = this.addNewNutritionValueToList.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);
		this.removeAlertNutrition = this.removeAlertNutrition.bind(this);
		this.removeNutritionData = this.removeNutritionData.bind(this);

		// Food Packaging
		this.setPackaging = this.setPackaging.bind(this);
	}

	componentDidMount() {
		this.getMeasurements();
		this.getProductAlergens();
		this.getProductNutriTypes();
		this.getProductPackaging();
		this.getTimeMeasurements();

		if (!this.isEmpty(this.props.meta)) {
			this.setState({
				food_allergens: this.props.meta.food_allergens !== undefined ? this.props.meta.food_allergens : [],
				food_expiration: this.props.meta.food_expiration !== undefined ? this.props.meta.food_expiration : {},
				food_ingredients: this.props.meta.food_ingredients !== undefined ? this.props.meta.food_ingredients : [],
				food_measurement: this.props.meta.food_measurement !== undefined ? this.props.meta.food_measurement : {},
				food_gross_weight_measurement: this.props.meta.food_gross_weight_measurement !== undefined ? this.props.meta.food_gross_weight_measurement : {},
				food_nutrition_values: this.props.meta.food_nutrition_values !== undefined ? this.props.meta.food_nutrition_values : [],
				food_packaging: this.props.meta.food_packaging,
			})
		} else {
			this.props.setMetaData('food_allergens', []);
			this.props.setMetaData('food_expiration', {});
			this.props.setMetaData('food_ingredients', []);
			this.props.setMetaData('food_measurement', {});
			this.props.setMetaData('food_gross_weight_measurement', {});
			this.props.setMetaData('food_nutrition_values', []);
			this.props.setMetaData('food_packaging', "");
		}
	}


	isEmpty(obj) {
		for (var key in obj) {
			if (obj.hasOwnProperty(key))
				return false;
		}
		return true;
	}

	// Get data from api 
	// ==========================
	getMeasurements() {
		return oblax.lists.getMeasurements()
			.then((result) => {
				if (result.ok) {
					this.setState({
						food_unit: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("MEASUREMENTS ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("MEASUREMENTS ERROR", err);
				}
			});
	}

	getProductAlergens() {
		return oblax.lists.getProductAlergens()
			.then((result) => {
				if (result.ok) {
					this.setState({
						food_allergens_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("ALERGENS ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ALERGENS ERROR", err);
				}
			});
	}

	getProductNutriTypes() {
		return oblax.lists.getProductNutriTypes()
			.then((result) => {
				if (result.ok) {
					this.setState({
						food_nutrition_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("NUTRITYPES ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("NUTRITYPES ERROR", err);
				}
			});
	}

	getProductPackaging() {
		return oblax.lists.getProductPackaging()
			.then((result) => {
				if (result.ok) {
					this.setState({
						food_packaging_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("PACKAGING ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("PACKAGING ERROR", err);
				}
			});
	}

	getTimeMeasurements() {
		return oblax.lists.getTimeMeasurements()
			.then((result) => {
				if (result.ok) {
					this.setState({
						food_time_list: result.data
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log("TIME MEASUREMENTS ERROR", result.status);
					}
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("TIME MEASUREMENTS ERROR", err);
				}
			});
	}

	// Food Allergens
	// ==========================
	handleAllergenClose(removedAllergen) {
		const food_allergens = this.state.food_allergens.filter(item => item !== removedAllergen);
		this.setState({
			food_allergens
		}, () => {
			this.props.setMetaData('food_allergens', food_allergens);
		});
	}

	handleAllergenInputChange(value) {
		// this.setState({ allergensInputValue: e.target.value });
		let food_allergens = this.state.food_allergens;
		if (value && food_allergens.indexOf(value) === -1) {
			food_allergens = [...food_allergens, value];
		}
		this.setState({
			food_allergens
		}, () => {
			this.props.setMetaData('food_allergens', food_allergens);
		});
	}
	// ==========================

	// Food Expiration
	// ==========================
	setExpiration(value) {
		this.setState(prevState => ({
			food_expiration: {
				...prevState.food_expiration,
				expiration_measurement: value
			}
		}), () => {
			this.props.setMetaData('food_expiration', this.state.food_expiration);
		})
	}

	setExpirationTime({ target }) {
		this.setState(prevState => ({
			food_expiration: {
				...prevState.food_expiration,
				expiration_time: target.value === "" ? "" : parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('food_expiration', this.state.food_expiration);
		})
	}
	// ==========================

	// Food Ingredients
	// ==========================
	editIngredients(food_ingredients) {
		this.setState({
			food_ingredients
		}, () => {
			this.props.setMetaData('food_ingredients', this.state.food_ingredients);
		});
	}
	// ==========================

	// Food Measurement
	// ==========================
	setMeasurementUnit(value) {
		this.setState(prevState => ({
			food_measurement: {
				...prevState.food_measurement,
				unit: value
			}
		}), () => {
			this.props.setMetaData('food_measurement', this.state.food_measurement);
		})
	}

	setGrossWeightMeasurementUnit(value) {
		this.setState(prevState => ({
			food_gross_weight_measurement: {
				...prevState.food_gross_weight_measurement,
				unit: value
			}
		}), () => {
			this.props.setMetaData('food_gross_weight_measurement', this.state.food_gross_weight_measurement);
		})
	}

	setMeasurementValue({ target }) {
		this.setState(prevState => ({
			food_measurement: {
				...prevState.food_measurement,
				value: target.value === "" ? "" : parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('food_measurement', this.state.food_measurement);
		})
	}

	setGrossWeightMeasurementValue({ target }) {
		this.setState(prevState => ({
			food_gross_weight_measurement: {
				...prevState.food_gross_weight_measurement,
				value: target.value === "" ? "" : parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('food_gross_weight_measurement', this.state.food_gross_weight_measurement);
		})
	}
	// ==========================

	// Food Nutrition Values
	// ==========================
	setNutritionType(value, id) {
		this.setState(prevState => ({
			food_nutrition_values: prevState.food_nutrition_values.map((obj) =>
				(obj.id === id ?
					Object.assign(obj, { type: value })
					:
					Object.assign(obj)
				)
			)
		}), () => {
			this.props.setMetaData('food_nutrition_values', this.state.food_nutrition_values);
		});
	}

	setNutritionUnit(value, id) {
		this.setState(prevState => ({
			food_nutrition_values: prevState.food_nutrition_values.map((obj) =>
				(obj.id === id ?
					Object.assign(obj, { unit: value })
					:
					Object.assign(obj)
				)
			)
		}), () => {
			this.props.setMetaData('food_nutrition_values', this.state.food_nutrition_values);
		});
	}

	setNutritionValue(value, id) {
		this.setState(prevState => ({
			food_nutrition_values: prevState.food_nutrition_values.map((obj) =>
				(obj.id === id ?
					Object.assign(obj, { value: parseInt(value) })
					:
					Object.assign(obj)
				)
			)
		}), () => {
			this.props.setMetaData('food_nutrition_values', this.state.food_nutrition_values);
		});
	}

	removeAlertNutrition(type) {
		this.setState({
			deleteAlert: true,
			removeType: type
		})
	}

	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			removeType: ''
		})
	}

	removeNutritionData() {
		var type = this.state.removeType;
		const food_nutrition_values = this.state.food_nutrition_values.filter(item => item.type !== type);
		this.setState({
			food_nutrition_values
		}, () => {
			this.props.setMetaData('food_nutrition_values', food_nutrition_values);
			this.setState({
				deleteAlert: false,
				removeType: ''
			})
		});

	}

	editNewNutritionValue(flag, value) {
		this.setState(prevState => ({
			food_nutrition_new: {
				...prevState.food_nutrition_new,
				[flag]: value
			}
		}))
	}

	editValueNewNutrition({ target }) {
        console.log("TCL: editValueNewNutrition -> target", target.value);
		this.setState(prevState => ({
			food_nutrition_new: {
				...prevState.food_nutrition_new,
				value: target.value === "" ? "" : parseFloat(target.value)
			}
		}))
	}

	addNewNutritionValueToList() {
		if (this.state.food_nutrition_new.type !== "" &&
			this.state.food_nutrition_new.unit !== ""
		) {
			this.setState({
				food_nutrition_values: this.state.food_nutrition_values.concat(this.state.food_nutrition_new)
			}, () => {
				this.props.setMetaData('food_nutrition_values', this.state.food_nutrition_values);
				this.setState({
					food_nutrition_new: {
						type: "",
						unit: "",
						value: 0,
						id: "",
					}
				})
			})
		}
	}
	// ==========================

	// Food Packaging
	// ==========================
	setPackaging(value) {
		this.setState({
			food_packaging: value
		}, () => {
			this.props.setMetaData('food_packaging', this.state.food_packaging);
		})
	}
	// ==========================

	render() {
		const {
			food_allergens,
			food_ingredients,
			food_packaging_list,
			food_nutrition_values,
			food_unit,
			food_nutrition_list,
			food_nutrition_new,
			food_time_list
		} = this.state;

		return (
			<div className="content-block-main" id="product-food-metadata">
				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Nutrition Value"
						message="You are attempting to delete a nutrition value from the table. Are you sure you want to proceed?"
						handleMainFunction={this.removeNutritionData}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}
				<div className="input-block-titled two">
					<h2 className="input-block-title">Food Expiration</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Time Measurment"
							mapFunction={mapTimeMeasurements}
							list={food_time_list}
							handleSelectChange={this.setExpiration}
							value={mapTimeMeasurements(this.props.meta !== null ? !this.isEmpty(this.props.meta.food_expiration) ? this.props.meta.food_expiration.expiration_measurement : "" : "")}
							errorMessage="Time measurment is required"
							error={this.props.errors.includes("food_expiration")}
							className=""
						/>
						<p className={"input-holder half " + (this.props.errors.includes("food_expiration") ? "error-active" : "")}>
							<input
								className="input-field--labeled"
								id="food-expiration-time"
								min="1"
								type="number"
								name="expiration_time"
								value={!this.isEmpty(this.props.meta) ? this.props.meta.food_expiration.expiration_time : 0}
								onChange={this.setExpirationTime}
								required
							/>
							<label className="field-label" htmlFor="food-expiration-time">Time Value</label>
							<span className="input-error">Time value is required</span>
							<span className="input-border"></span>
						</p>
					</div>
				</div>
				<div className="input-block-titled two">
					<h2 className="input-block-title">Food Measurment</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Unit"
							mapFunction={mapUnits}
							list={food_unit}
							handleSelectChange={this.setMeasurementUnit}
							value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.food_measurement) ? this.props.meta.food_measurement.unit : "" : "")}
							// value={!this.isEmpty(this.props.meta) ? this.props.meta.food_measurement.unit : "unit"}
							className=""
						/>
						<p className="input-holder half">
							<input
								className="input-field--labeled"
								id="food-measurement"
								min="1"
								type="number"
								name="food_measurement"
								value={!this.isEmpty(this.props.meta) ? this.props.meta.food_measurement.value : 0}
								onChange={this.setMeasurementValue}
								required
							/>
							<label className="field-label" htmlFor="food-measurement">Value</label>
							<span className="input-border"></span>
						</p>
					</div>
				</div>
				<div className="input-block-titled two">
					<h2 className="input-block-title">Food Gross Weight Measurment</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Unit"
							mapFunction={mapUnits}
							list={food_unit}
							handleSelectChange={this.setGrossWeightMeasurementUnit}
							value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.food_gross_weight_measurement) ? this.props.meta.food_gross_weight_measurement.unit : "" : "")}
							className=""
						/>
						<p className="input-holder half">
							<input
								className="input-field--labeled"
								id="food_gross_weight_measurement"
								min="1"
								type="number"
								name="food_gross_weight_measurement"
								value={!this.isEmpty(this.props.meta) ? this.props.meta.food_gross_weight_measurement.value : 0}
								onChange={this.setGrossWeightMeasurementValue}
								required
							/>
							<label className="field-label" htmlFor="food_gross_weight_measurement">Value</label>
							<span className="input-border"></span>
						</p>
					</div>
				</div>
				<div className="input-block-titled one select">
					<h2 className="input-block-title">Food Alergens</h2>
					<div className="input-grid">
						<p className="rounded-data-holder tags color orange">
							<span className="data-title">Alergens</span>
							<span className="data-line">
								{food_allergens.map(allergen =>
									<span className="single-data-item" key={allergen}>{mapAlergens(allergen.toUpperCase())}<button className="remove-button" onClick={() => this.handleAllergenClose(allergen)}></button></span>
								)}
								<ButtonDropDown
									handler=""
									value=""
									mapFunction={mapAlergens}
									title="Add new alergen"
									list={this.state.food_allergens_list}
									handleSelectChange={(allergen) => this.handleAllergenInputChange(allergen)}
									disabled={true}
								/>
							</span>
						</p>
					</div>

					{/* <Select 
                                defaultValue="Select Badges"
                                style={{ width: 120 }} 
                                onChange={(value) => this.handleAllergenInputChange(value)}
                                disabled={false}
                            >
                            {this.state.food_allergens_list.map((item, i) => 
                                <Select.Option key={i} value={item}>{item}</Select.Option>
                            )}
                        </Select>
                    {food_allergens.map((allergen, index) => {
                        const isLongIngredients = allergen.length > 20;
                        const ingredientElem = (
                            <Tag key={allergen} closable={false ? '' : index >= 0} afterClose={() => this.handleAllergenClose(allergen)}>
                                {isLongIngredients ? `${allergen.slice(0, 20)}...` : allergen}
                            </Tag>
                        );
                        return isLongIngredients ? <Tooltip title={allergen} key={allergen}>{ingredientElem}</Tooltip> : ingredientElem;
                    })} */}
				</div>
				<div className="input-block-titled one select">
					<h2 className="input-block-title">Food Ingredients</h2>

					<div className="input-grid">
						<p className="rounded-data-holder tags color orange">
							<span className="data-title">Ingredients</span>
							<ProductTags
								tags={food_ingredients}
								editTags={this.editIngredients}
								title="Add new ingredient"
							/>
						</p>
					</div>
				</div>
				<div className="input-block-titled two">
					<h2 className="input-block-title">Food Packaging</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Packaging"
							mapFunction={mapPackaging}
							list={food_packaging_list}
							handleSelectChange={this.setPackaging}
							value={mapPackaging(!this.isEmpty(this.props.meta) ? this.props.meta.food_packaging : "select packaging")}
							className=""
						/>
					</div>
				</div>
				<div className="input-block-titled select four">
					<h2 className="input-block-title">Food Nutrition Values</h2>

					<div className="input-grid">
						<DropDown
							disabled={false}
							title="Type"
							mapFunction={mapNutriTypes}
							list={food_nutrition_list}
							handleSelectChange={(value) => this.editNewNutritionValue("type", value)}
							value={mapNutriTypes(food_nutrition_new.type)}
							className=""
						/>
						<DropDown
							disabled={false}
							title="Unit"
							mapFunction={mapUnits}
							list={food_unit}
							handleSelectChange={(value) => this.editNewNutritionValue("unit", value)}
							value={mapUnits(food_nutrition_new.unit)}
							className=""
						/>
						<p className="input-holder half">
							<input
								className="input-field--labeled"
								id="food-nutrition-value"
								min="1"
								type="number"
								name="value"
								value={this.state.food_nutrition_new.value}
								onChange={(value) => this.editValueNewNutrition(value)}
								required
							/>
							<label className="field-label" htmlFor="food-nutrition-value">Value</label>
							<span className="input-border"></span>
						</p>
						<button
							className="circle-button icon dark add-new-nutirition-value-button"
							onClick={this.addNewNutritionValueToList}
						></button>
					</div>
					<div className="content-block-table">
						<table className="minimal-table">
							<thead className="minimal-table-head">
								<tr>
									<th>Name</th>
									<th>Unit</th>
									<th>Value</th>
								</tr>
							</thead>
							<tbody className="minimal-table-body">
								{(food_nutrition_values !== undefined && food_nutrition_values.length > 0) && food_nutrition_values.map((val, i) =>
									<tr key={i}>
										<td>{mapNutriTypes(val.type)}</td>
										<td>{mapUnits(val.unit)}</td>
										<td>{val.value}</td>
										<td><button className="ghost-icon-button delete-button" onClick={() => this.removeAlertNutrition(val.type)}></button></td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</div>

			</div>
		);
	}
}

export default ProductFoodMeta;