import React, { Component } from "react";
import { connect } from "react-redux";
import placeholder from "../../../assets/images/person-placeholder.png";
import { Input, Icon } from "antd";
import oblax from "../../../assets/oblax";

import "../css/Users.css";

import { setSelectedUser, setFilterData } from "../actions";
import { setUsersCount } from "../../Home/actions";
import { DropDown, Loader, Toast, EmptyStateDisclaimer } from "../../../parts";

// const Search = Input.Search;

class Users extends Component {
	constructor() {
		super();
		this.state = {
			users: [],
			loader: true,
		}

		this.filterChange = this.filterChange.bind(this);
		this.filterChangeMain = this.filterChangeMain.bind(this);
		this.addNewUser = this.addNewUser.bind(this);
	}

	componentDidMount() {
		this.setState({
			filter: this.props.filter
		})
		oblax.users.getAll()
			.then((result) => {
				if (result.ok) {
					this.setState({
						users: result.data,
						loader: false
					}, () => {
						this.props.setUsersCount(this.state.users.length)
					});
				} else {
					if (process.env.REACT_APP_ENV !== 'prod') {
						console.log('ERROR: ', result.status)
					}
					this.setState({
						users: [],
						loader: true
					});
				}
			})
			.catch(err => {
				if (process.env.REACT_APP_ENV !== 'prod') {
					console.log("ERROR: ", err);
				}
			});
	}

	getUserDetails(data) {
		// this.props.setSelectedUser(data);
		this.props.history.push(`/users/allUsers/${data.id}`);
	}

	filterChange({ target }) {
		if (target.name === "active") {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: !prevState.filter.active
					}
				}), () => {
					if (this.state.filter.active) {
						this.props.setFilterData(this.state.filter);
					} else {
						this.setState({
							filter: {
								name: '',
								type: '',
								email: '',
								global: 'active',
								active: false
							}
						}, () => {
							this.props.setFilterData(this.state.filter);
						})

					}
				}
			)
		} else {
			this.setState(
				prevState => ({
					filter: {
						...prevState.filter,
						[target.name]: target.value
					}
				}), () => {
					this.props.setFilterData(this.state.filter);
				}
			)
		}
	}

	filterChangeMain(data) {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					global: data
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	filterChangeDrop(data) {
		this.setState(
			prevState => ({
				filter: {
					...prevState.filter,
					type: data
				}
			}), () => {
				this.props.setFilterData(this.state.filter);
			}
		)
	}

	addNewUser() {
		this.props.history.push('/users/allUsers/new');
	}

	render() {
		const FilteredUserList = this.state.users.filter(items => (
			this.props.filter.global === "active" ?
				this.props.filter.name.split(" ").length === 2 ?
					items.first_name.toLowerCase().includes(this.props.filter.name.split(" ")[0].toLowerCase()) &&
					items.last_name.toLowerCase().includes(this.props.filter.name.split(" ")[1].toLowerCase()) &&
					items.email.toLowerCase().includes(this.props.filter.email.toLowerCase()) &&
					items.role.includes(this.props.filter.type) &&
					items.active
					:
					(
						items.first_name.toLowerCase().includes(this.props.filter.name.toLowerCase()) ||
						items.last_name.toLowerCase().includes(this.props.filter.name.toLowerCase())
					) &&
					items.email.toLowerCase().includes(this.props.filter.email.toLowerCase()) &&
					items.role.includes(this.props.filter.type) &&
					items.active
				:
				this.props.filter.name.split(" ").length === 2 ?
					items.first_name.toLowerCase().includes(this.props.filter.name.split(" ")[0].toLowerCase()) &&
					items.last_name.toLowerCase().includes(this.props.filter.name.split(" ")[1].toLowerCase()) &&
					items.email.toLowerCase().includes(this.props.filter.email.toLowerCase()) &&
					items.role.includes(this.props.filter.type) &&
					!items.active
					:
					(
						items.first_name.toLowerCase().includes(this.props.filter.name.toLowerCase()) ||
						items.last_name.toLowerCase().includes(this.props.filter.name.toLowerCase())
					) &&
					items.email.toLowerCase().includes(this.props.filter.email.toLowerCase()) &&
					items.role.includes(this.props.filter.type) &&
					!items.active

		));

		return (
			<div id="users-table" className="users">
				<div className="users-table-container">
					<div className="table-filter users-title-search">
						<div className="filter-row main">
							<div className="filter-item main">
								<button className="circle-button light icon filter-icon" onClick={this.filterChange} name="active"></button>
								<div className="filter-buttons">
									<ul>
										<li className={this.props.filter.global == "active" ? "active" : ""} onClick={() => this.filterChangeMain("active")}><span>Active</span></li>
										{/* <li><span>Inactive</span></li> */}
										<li className={this.props.filter.global == "inactive" ? "active" : ""} onClick={() => this.filterChangeMain("inactive")}><span>Inactive</span></li>
									</ul>
								</div>
							</div>
							<div className="filter-item">
								<button
									className="rounded-button primary primary-icon gradient orange new-product-button"
									onClick={this.addNewUser}
								>
									<span className="button-text">Create User</span>
								</button>
							</div>
						</div>
						{this.props.filter.active &&
							<div className="filter-row detail">
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-product-name"
										onChange={this.filterChange}
										name="name"
										value={this.props.filter.name}
										required
									/>
									<label className="field-label" htmlFor="filter-product-name">User name</label>
									<span className="input-border"></span>
								</p>
								<p className="input-holder">
									<input
										type="text"
										className="input-field--labeled"
										id="filter-product-barcode"
										onChange={this.filterChange}
										name="email"
										value={this.props.filter.email}
										required
									/>
									<label className="field-label" htmlFor="filter-product-barcode">E-mail</label>
									<span className="input-border"></span>
								</p>
								<DropDown
									disabled={false}
									value={this.props.filter.type}
									title="User Type"
									list={["user", "admin"]}
									handleSelectChange={(value) => this.filterChangeDrop(value)}
									className=""
								/>
							</div>
						}
					</div>

					<div className="bc-table-wrap">
						<table className="bc-table">
							<thead className="bc-table-head">
								<tr>
									<th>Picture</th>
									<th>Name</th>
									<th>Email</th>
									<th>Role</th>
									<th className="bc-table-data-centered">Confirmed</th>
									<th className="bc-table-data-centered">Active</th>
									<th className="pusher">pusher</th>
								</tr>
							</thead>

							<tbody className="bc-table-body">
								{this.state.loader ?
									<tr>
										<td>
											<Loader />
										</td>
									</tr>
									:
									FilteredUserList.length > 0 ?
									FilteredUserList.map((item, i) =>
												<tr onClick={() => this.getUserDetails(item)} className="bc-table-row" key={i}>
													<td className="bc-table-data user-img">
														<span className="bc-image-holder rounded">
															<img
																src={`${process.env.REACT_APP_MINIO_GATEWAY}avatars/${item.picture}`}
																onError={(e) => { e.target.src = placeholder }}
															/>
														</span>
													</td>
													<td className="bc-table-data user-name">{item.first_name} {item.last_name}</td>
													<td className="products-table-data table-classic-data user-email">{item.email}</td>

													<td className="bc-table-data table-classic-data user-role">{item.role}</td>
													<td className="bc-table-data table-classic-data user-confirmed bc-table-data-centered">
														{item.confirmed ?
															<span className="icon-button confirmed-icon confirmed"></span>
															:
															<span className="icon-button confirmed-icon not-confirmed"></span>
														}
													</td>
													<td className="bc-table-data user-active bc-table-data-centered">
														{item.active ?
															<span className="icon-button active-icon active"></span>
															:
															<span className="icon-button active-icon inactive"></span>
														}
													</td>
													<td className="products-table-data bc-button-table-data">
														<button className="square-button light icon settings-icon"></button>
														<div id="table-settings-dropdown">
															<ul className="dropdown-list">
																<li>
																	<a href="#">
																		<span className="dropdown-icon edit"></span>
																		<h2 className="dropdown-title">Edit</h2>
																	</a>
																</li>
																<li>
																	<a href="#">
																		<span className="dropdown-icon set-inactive"></span>
																		<h2 className="dropdown-title">Set Inactive</h2>
																	</a>
																</li>
																<li>
																	<a href="#">
																		<span className="dropdown-icon delete"></span>
																		<h2 className="dropdown-title">Delete</h2>
																	</a>
																</li>
															</ul>
														</div>
													</td>
												</tr>
											)
										:
										<tr>
											<td colSpan="8">
												<EmptyStateDisclaimer
													message="No such info on our shuttle"
													icon="rocket"
												/>
											</td>
										</tr>
								}
							</tbody>
						</table>
					</div>
				</div>

			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		filter: state.user.filter
	};
}

const mapDispatchToProps = (dispatch) => {
	return {
		setSelectedUser: (data) => {
			dispatch(setSelectedUser(data));
		},
		setFilterData: (data) => {
			dispatch(setFilterData(data))
		},
		setUsersCount: (data) => {
			dispatch(setUsersCount(data))
		}
	};
}

// export default Main;
export default connect(mapStateToProps, mapDispatchToProps)(Users);