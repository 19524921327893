import React, { Component } from 'react';
import {
    Radio,
    Button
} from "antd";
import placeholder from "../../../../assets/images/person-placeholder.png";

class ProductImages extends Component {
    constructor(props) {
        super(props);

        this.getObjectKey = this.getObjectKey.bind(this);
    }


    getObjectKey() {
        let keys = Object.keys(this.props.images);
        for(var index=0; index<keys.length; index++) {
            if (this.props.images[keys[index]].cover) {
                return parseInt(keys[index]);
            }
        }
    }

    render() {
        return(
            <div className="image-add-block">
                <div className="add-button image-item" onClick={() => this.inputElement.click()}>
                    <div className="button-centered">
                        <span>click to add</span>
                        <input
                            type="file"
                            name="pic"
                            accept="image/*"
                            ref={input => this.inputElement = input}
                            onChange={(e) => this.props.addImage(e)}
                            hidden
                        />
                    </div>
                </div>
				{this.props.images.map((image, i) =>
                        <div onClick={() => this.props.choosePrimaryImage(image.path)} className={"image-item basic " + (image.cover ? "cover" : "")} key={i}>
                            <button className="remove-image" onClick={() => this.props.removeImg(i)}></button>
                            <div className="button-centered">
                                <img 
                                    src={`${process.env.REACT_APP_MINIO_GATEWAY}products/${image.path}`} 
                                    alt='' 
                                    onError={(e) => { e.target.src = placeholder }}
                                />
                    	</div>
                	</div>
                )}
            </div>
        )
    }
}

export default ProductImages;