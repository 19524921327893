import React from "react";
import { NavLink } from "react-router-dom";
import "../../css/Parts/Navigation.css";

// IMAGES
import Logo from "../../../../assets/images/logo_light.svg"


class Navigation extends React.Component {
    componentDidMount() {
        var location = this.props.history.location.pathname;
        if (location === '/' || location === '/login' || location === '/dashboard') {
            this.props.history.push("/dashboard/general");
        }
    }

    // reselectAction(match, location) {
    //     if (match !== null) {
    //         console.log("MATCH: ", match)
    //         // return true
    //     }
    // }

    render() {
        const path_name = this.props.history.location.pathname.split("/")[1];
        return (
            <div id="sidebar">
                <div className="sidebar-container">
                    <div className="logo">
                        <div className="logo-symbol">
                            <img src={Logo} alt="Booster Cloud Light" />
                        </div>
                        <div className="logo-text">
                            <h2><span className="b-orange">Booster</span><span className="c-light">Cloud</span></h2>
                            <span className="b-desc">control panel</span>
                        </div>
                    </div>

                    <div className="sidebar-content">
                        <div className="icons">
                            <div className="single-row">
                                <NavLink
                                    activeClassName="active"
                                    to="/dashboard"
                                >
                                    <span className="sidebar-icon dashboard-sidebar-icon">invoices-icon</span>
                                    <span className="sidebar-menu-item">Dashboard</span>
                                </NavLink>  
                            </div>
                            <div className="single-row">
                                <NavLink
                                    activeClassName="active"
                                    to="/users"
                                >
                                    <span className="sidebar-icon users-sidebar-icon">users-icon</span>
                                    <span className="sidebar-menu-item">Users</span>
                                </NavLink>  
                            </div>
                            <div className="single-row">
                                <NavLink
                                    activeClassName="active"
                                    to="/e-shop"
                                >
                                    <span className="sidebar-icon eshop-sidebar-icon">eShop-icon</span>
                                    <span className="sidebar-menu-item">e-shop</span>
                                </NavLink>
                            </div>
                            {/* <div className="single-row">
                                <a href="#">
                                    <span className="sidebar-icon stats-sidebar-icon">stats-icon</span>
                                    <span className="sidebar-menu-item">stats</span>
                                </a>
                            </div> */}
                            <div className="single-row">
                                <NavLink
                                    activeClassName="active"
                                    to="/pages"
                                >
                                    <span className="sidebar-icon pages-sidebar-icon">pages-icon</span>
                                    <span className="sidebar-menu-item">pages</span>
                                </NavLink>
                            </div>
                            <div className="single-row">
                                <NavLink
                                    activeClassName="active"
                                    to="/forms"
                                >
                                    <span className="sidebar-icon pages-sidebar-icon">forms-icon</span>
                                    <span className="sidebar-menu-item">forms</span>
                                </NavLink>
                            </div>
                            {/* <div className="single-row">
                                <a href="#">
                                    <span className="sidebar-icon payments-sidebar-icon">payments-icon</span>
                                    <span className="sidebar-menu-item">payments</span>
                                </a>
                            </div>
                            <div className="single-row">
                                <a href="#">
                                    <span className="sidebar-icon invoices-sidebar-icon">invoices-icon</span>
                                    <span className="sidebar-menu-item">invoices</span>
                                </a>
                            </div> */}

                        </div>

                        {/* DASHBOARD */}
                        {path_name === "dashboard" &&
                            <div className="icon-pairings">
                                <ul>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/dashboard/general"
                                        >
                                            <span className="pairing-data">General</span>
                                            <span className="pairing-count">0</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        }

                        {/* USERS */}
                        {path_name === "users" &&
                            <div className="icon-pairings">
                                <ul>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/users/allUsers"
                                        >
                                            <span className="pairing-data">All Users</span>
                                            <span className="pairing-count">{this.props.usersCount}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        }

                        {/* E-SHOP */}
                        {path_name === "e-shop" &&
                            <div className="icon-pairings">
                                <ul>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/e-shop/products"
                                        >
                                            <span className="pairing-data">Products</span>
                                            <span className="pairing-count">{this.props.productsCount}</span>
                                        </NavLink>
                                    </li>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/e-shop/categories"
                                        >
                                            <span className="pairing-data">Categories</span>
                                            <span className="pairing-count">{this.props.categoriesCount}</span>
                                        </NavLink>
                                    </li>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/e-shop/companies"
                                        >
                                            <span className="pairing-data">Companies</span>
                                            <span className="pairing-count">{this.props.companiesCount}</span>
                                        </NavLink>
                                    </li>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/e-shop/orders"
                                        >
                                            <span className="pairing-data">Orders</span>
                                            <span className="pairing-count">{this.props.ordersCount}</span>
                                        </NavLink>
                                    </li>
                                    {/* <li className="single-icon-pairing">
                                        
                                        <a href="#">
                                            <span className="pairing-data">Favorites</span>
                                            <span className="pairing-count">598</span>
                                        </a>
                                    </li> */}
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/e-shop/carts"
                                        >
                                            <span className="pairing-data">Carts</span>
                                            <span className="pairing-count">0</span>
                                        </NavLink>
                                    </li>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/e-shop/warehouse"
                                        >
                                            <span className="pairing-data">Warehouse</span>
                                            <span className="pairing-count">{this.props.warehouseCount}</span>
                                        </NavLink>
                                    </li>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/e-shop/delivery"
                                        >
                                            <span className="pairing-data">Delivery</span>
                                            {/* <span className="pairing-count">{this.props.warehouseCount}</span> */}
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        }

                        {/* PAGES */}
                        {path_name === "pages" &&
                            <div className="icon-pairings">
                                <ul>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/pages"
                                            >
                                            <span className="pairing-data">Pages</span>
                                            <span className="pairing-count">{this.props.pagesCount}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        }

                        {/* FORMS */}
                        {path_name === "forms" &&
                            <div className="icon-pairings">
                                <ul>
                                    <li className="single-icon-pairing">
                                        <NavLink
                                            activeClassName="active"
                                            // isActive={this.reselectAction}
                                            to="/forms"
                                            >
                                            <span className="pairing-data">Message Forms</span>
                                            <span className="pairing-count">{this.props.formsCount}</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        }
                        {/* STATS TODO */}
                        {/* PAYMANTS TODO*/}
                        {/* INVOICES TODO */}
                        
                    </div>
                </div>
            </div>

        )
    }
}

export default Navigation;