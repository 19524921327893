import React from 'react';
import {
    Route,
    Redirect,
  } from 'react-router-dom';

const PrivateRoute = ({ component: Component, userState, auth, ...rest}) => (
    <Route {...rest} render={
        (props) => {
            if (auth.isAuthenticated && (auth.authUser !== null)) {
                return <Component {...props} />;
            } else {
                return <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }} />
            }

        }
    } />

)

export default PrivateRoute