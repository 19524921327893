export function setFilterData(data) {
    return {
        type: 'SET_FILTER_DATA',
        payload: data
    }
}

export function setAlertType(data) {
    return {
        type: 'SET_ALERT_TYPE',
        payload: data
    }
}

export function setNewReceivings(data) {
    return {
        type: 'SET_NEW_RECEIVINGS',
        payload: data
    }
}

export function setProductsInReceiver(data) {
    return {
        type: 'SET_PRODUCTS_IN_RECEIVER',
        payload: data
    }
}

export function setDfaultReceivers(data) {
    return {
        type: 'SET_DEFAULT_RECEIVERS',
        payload: data
    }
}