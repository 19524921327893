export function setSelectedProduct(data) {
    return {
        type: 'SET_SELECTED_PRODUCT',
        payload: data
    }
}

export function setAlertType(data) {
    return {
        type: 'SET_ALERT_TYPE',
        payload: data
    }
}

export function setFilterData(data) {
    return {
        type: 'SET_FILTER_DATA',
        payload: data
    }
}

export function setProductPrices(data) {
    return {
        type: 'SET_PRODUCT_PRICES',
        payload: data
    }
}

export function setProductPromotions(data) {
    return {
        type: 'SET_PRODUCT_PROMOTIONS',
        payload: data
    }
}

export function setProductList(data) {
    return {
        type: 'SET_PRODUCT_LIST',
        payload: data
    }
}

export function setProductsMap(data) {
    return {
        type: 'SET_PRODUCT_MAP',
        payload: data
    }
}

