import React, { Component } from "react";

class ButtonDropDown extends Component {
    constructor() {
        super();
        this.state = {
            drop: ''
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
	}


    handleDrop = () => {
		if(this.props.disabled) {
			this.setState({
				drop: this.state.drop === '' ? 'opened' : ''
			});
		}
    }

    handleSelect = (item) => {
        this.setState({
            drop: ''
        }, () => {
            if(item.id !== undefined) {
                this.props.handleSelectChange(item.id);
            } else {
                this.props.handleSelectChange(item);
            }
        })
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
           this.setState({
               drop: ''
           })
        }
	}
	
	handleDataProperty = (item, handler, mapFunction, idx) => {
        if(handler !== "" || handler !== undefined) {
            if (mapFunction !== undefined) {
                return <span key={idx} onClick={() => this.handleSelect(item)}>{mapFunction(item[`${handler}`] !== undefined ? item[`${handler}`] : item)}</span>
            } else {
                return <span key={idx} onClick={() => this.handleSelect(item)}>{item[`${handler}`] !== undefined ? item[`${handler}`] : item}</span>
            }
		} else {
            if (mapFunction !== undefined) {
                return <span key={idx} onClick={() => this.handleSelect(item)}>{mapFunction(item !== undefined ? item : item)}</span>
            } else {
                return <span key={idx} onClick={() => this.handleSelect(item)}>{item !== undefined ? item : item}</span>
            }
		}
	}

    render () {
        return (
            <p className={`button-dropdown-holder ${this.state.drop}`} ref={this.setWrapperRef}>
               	<span className={"add-new-item " + (!this.props.disabled ? "disabled-dropdown-button" : "")} onClick={this.handleDrop}>{this.props.title}</span>
                <span className="select-dropdown">
					{this.props.list.map((item, i) => 
						this.handleDataProperty(item, this.props.handler, this.props.mapFunction, i)
                    )}
                </span>
            </p>
        )
    }
}

export default ButtonDropDown;