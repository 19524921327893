const initialState = {
	auth: {
        isAuthenticated: false,
        authUser: null
      }
};

const app = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_LOGIN_DATA':
            return Object.assign({}, state, {
                ...state,
                auth: {
                    isAuthenticated: true,
                    authUser: action.payload,       
                }
            });
		default:
			return state;
	}
}

export default app;