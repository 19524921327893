import React, { Component } from 'react';
import {
    Input
} from "antd";

class ProductTags extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputVisible: false,
            inputValue: '',
        }

        this.showInput = this.showInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleInputConfirm = this.handleInputConfirm.bind(this);
    }

    // TAGS 
    //==========================
    handleClose(removedTag) {
        const tags = this.props.tags.filter(tag => tag !== removedTag);
        this.setState({
            tags
        }, () => {
            this.props.editTags(tags);
        });
    }

    showInput() {
        this.setState({ inputVisible: true }, () => this.input.focus());
    }

    handleInputChange(e) {
        this.setState({ inputValue: e.target.value });
    }

    handleInputConfirm() {
        const inputValue = this.state.inputValue;
        let tags = this.props.tags;
        if (inputValue && tags.indexOf(inputValue) === -1) {
            tags = [...tags, inputValue];
        }
        this.setState({
            inputVisible: false,
            inputValue: '',
        }, () => {
            this.props.editTags(tags);
        });
    }

    saveInputRef = input => this.input = input;
    //==========================

    render() {
        const {
            inputVisible,
            inputValue
        } = this.state;
        return (
            <span className="data-line">
                {this.props.tags.map((item, i) =>
                    <span className="single-data-item" key={item}>{item}<button className="remove-button" onClick={() => this.handleClose(item)}></button></span>
                )}
                {inputVisible && (
                    <Input
                        ref={this.saveInputRef}
                        type="text"
                        size="small"
                        style={{ width: 78 }}
                        value={inputValue}
                        onChange={this.handleInputChange}
                        onBlur={this.handleInputConfirm}
                        onPressEnter={this.handleInputConfirm}
                        disabled={this.props.edit}
                    />
                )}
                {(!inputVisible) && (
                    <span className="add-new-item" onClick={this.showInput}>{this.props.title}</span>
                )}
            </span>
        )
    }
}

ProductTags.defaultProps = {
    title: 'Add new tag'
};

export default ProductTags;