import React, { Component } from 'react';
import oblax from '../../../../assets/oblax';

import { DropDown } from "../../../../parts";
import { ProductTags } from "../Parts";
import { mapUnits, mapPackaging, mapTimeMeasurements } from "../../../../assets/helper";

class ProductCleaningMeta extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cleaning_ingredients: [],
            cleaning_expiration: {},
            cleaning_measurement: {},
            cleaning_gross_weight_measurement: {},
            cleaning_packaging: '',

            cleaning_unit: [],
            cleaning_packaging_list: [],
            cleaning_time_list: [],

            deleteAlert: false,
            removeType: ''
        }

        // Get data from api 
        this.getMeasurements = this.getMeasurements.bind(this);

        // Cleaning Expiration
        this.setExpiration = this.setExpiration.bind(this);
        this.setExpirationTime = this.setExpirationTime.bind(this);

        // Cleaning Ingredients
        this.editIngredients = this.editIngredients.bind(this);

        // Cleaning Measurement
        this.setMeasurementUnit = this.setMeasurementUnit.bind(this);
        this.setMeasurementValue = this.setMeasurementValue.bind(this);
        this.setGrossWeightMeasurementUnit = this.setGrossWeightMeasurementUnit.bind(this);
		this.setGrossWeightMeasurementValue = this.setGrossWeightMeasurementValue.bind(this);

        // Cleaning Packaging
        this.setPackaging = this.setPackaging.bind(this);
    }

    componentDidMount() {
        this.getMeasurements();
        this.getProductPackaging();
        this.getTimeMeasurements();

        if (!this.isEmpty(this.props.meta)) {
            this.setState({
                cleaning_expiration: this.props.meta.cleaning_expiration !== undefined ? this.props.meta.cleaning_expiration : {},
                cleaning_ingredients: this.props.meta.cleaning_ingredients !== undefined ? this.props.meta.cleaning_ingredients : [],
                cleaning_measurement: this.props.meta.cleaning_measurement !== undefined ? this.props.meta.cleaning_measurement : {},
                cleaning_gross_weight_measurement: this.props.meta.cleaning_gross_weight_measurement !== undefined ? this.props.meta.cleaning_gross_weight_measurement : {},
                cleaning_packaging: this.props.meta.cleaning_packaging,
            })
        } else {
            this.props.setMetaData('cleaning_expiration', {});
            this.props.setMetaData('cleaning_ingredients', []);
            this.props.setMetaData('cleaning_measurement', {});
            this.props.setMetaData('cleaning_gross_weight_measurement', {});
            this.props.setMetaData('cleaning_packaging', "");
        }
    }


    isEmpty(obj) {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }

    // Get data from api 
    // ==========================
    getMeasurements() {
        return oblax.lists.getMeasurements()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        cleaning_unit: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("MEASUREMENTS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("MEASUREMENTS ERROR", err);
                }
            });
    }

    getProductPackaging() {
        return oblax.lists.getProductPackaging()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        cleaning_packaging_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("PACKAGING ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("PACKAGING ERROR", err);
                }
            });
    }

    getTimeMeasurements() {
        return oblax.lists.getTimeMeasurements()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        cleaning_time_list: result.data
                    });
                } else {
                    if (process.env.REACT_APP_ENV !== 'prod') {
                        console.log("TIME MEASUREMENTS ERROR", result.status);
                    }
                }
            })
            .catch(err => {
                if (process.env.REACT_APP_ENV !== 'prod') {
                    console.log("TIME MEASUREMENTS ERROR", err);
                }
            });
    }


    // Cleaning Expiration
    // ==========================
    setExpiration(value) {
        this.setState(prevState => ({
            cleaning_expiration: {
                ...prevState.cleaning_expiration,
                expiration_measurement: value
            }
        }), () => {
            this.props.setMetaData('cleaning_expiration', this.state.cleaning_expiration);
        })
    }

    setExpirationTime({ target }) {
        this.setState(prevState => ({
            cleaning_expiration: {
                ...prevState.cleaning_expiration,
                expiration_time: parseInt(target.value)
            }
        }), () => {
            this.props.setMetaData('cleaning_expiration', this.state.cleaning_expiration);
        })
    }
    // ==========================

    // Cleaning Ingredients
    // ==========================
    editIngredients(cleaning_ingredients) {
        this.setState({
            cleaning_ingredients
        }, () => {
            this.props.setMetaData('cleaning_ingredients', this.state.cleaning_ingredients);
        });
    }
    // ==========================

    // Cleaning Measurement
    // ==========================
    setMeasurementUnit(value) {
        this.setState(prevState => ({
            cleaning_measurement: {
                ...prevState.cleaning_measurement,
                unit: value
            }
        }), () => {
            this.props.setMetaData('cleaning_measurement', this.state.cleaning_measurement);
        })
    }

    setGrossWeightMeasurementUnit(value) {
		this.setState(prevState => ({
			cleaning_gross_weight_measurement: {
				...prevState.cleaning_gross_weight_measurement,
				unit: value
			}
		}), () => {
			this.props.setMetaData('cleaning_gross_weight_measurement', this.state.cleaning_gross_weight_measurement);
		})
	}

    setMeasurementValue({ target }) {
        this.setState(prevState => ({
            cleaning_measurement: {
                ...prevState.cleaning_measurement,
                value: parseFloat(target.value)
            }
        }), () => {
            this.props.setMetaData('cleaning_measurement', this.state.cleaning_measurement);
        })
    }

    setGrossWeightMeasurementValue({ target }) {
		this.setState(prevState => ({
			cleaning_gross_weight_measurement: {
				...prevState.cleaning_gross_weight_measurement,
				value: target.value === "" ? "" : parseInt(target.value)
			}
		}), () => {
			this.props.setMetaData('cleaning_gross_weight_measurement', this.state.cleaning_gross_weight_measurement);
		})
	}
    // ==========================

    // Cleaning Packaging
    // ==========================
    setPackaging(value) {
        this.setState({
            cleaning_packaging: value
        }, () => {
            this.props.setMetaData('cleaning_packaging', this.state.cleaning_packaging);
        })
    }
    // ==========================

    render() {
        const {
            cleaning_ingredients,
            cleaning_packaging_list,
            cleaning_unit,
            cleaning_time_list
        } = this.state;

        return (
            <div className="content-block-main" id="product-food-metadata">
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cleaning Expiration</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Time Measurment"
                            mapFunction={mapTimeMeasurements}
                            list={cleaning_time_list}
                            handleSelectChange={this.setExpiration}
                            value={mapTimeMeasurements(this.props.meta !== null ? !this.isEmpty(this.props.meta.cleaning_expiration) ? this.props.meta.cleaning_expiration.expiration_measurement : "" : "")}
                            errorMessage="Time measurment is required"
                            error={this.props.errors.includes("cleaning_expiration")}
                            className=""
                        />
                        <p className={"input-holder half " + (this.props.errors.includes("cleaning_expiration") ? "error-active" : "")}>
                            <input
                                className="input-field--labeled"
                                id="food-expiration-time"
                                min="1"
                                type="number"
                                name="expiration_time"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.cleaning_expiration.expiration_time : 0}
                                onChange={this.setExpirationTime}
                                required
                            />
                            <label className="field-label" htmlFor="food-expiration-time">Time Value</label>
                            <span className="input-error">Time value is required</span>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cleaning Measurment</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Unit"
                            mapFunction={mapUnits}
                            list={cleaning_unit}
                            handleSelectChange={this.setMeasurementUnit}
                            value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.cleaning_measurement) ? this.props.meta.cleaning_measurement.unit : "" : "")}
                            className=""
                        />
                        <p className="input-holder half">
                            <input
                                className="input-field--labeled"
                                id="food-measurement"
                                min="1"
                                type="number"
                                name="food_measurement"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.cleaning_measurement.value : 0}
                                onChange={this.setMeasurementValue}
                                required
                            />
                            <label className="field-label" htmlFor="food-measurement">Value</label>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cleaning Gross Weight Measurment</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Unit"
                            mapFunction={mapUnits}
                            list={cleaning_unit}
                            handleSelectChange={this.setGrossWeightMeasurementUnit}
                            value={mapUnits(this.props.meta !== null ? !this.isEmpty(this.props.meta.cleaning_gross_weight_measurement) ? this.props.meta.cleaning_gross_weight_measurement.unit : "" : "")}
                            className=""
                        />
                        <p className="input-holder half">
                            <input
                                className="input-field--labeled"
                                id="cleaning_gross_weight_measurement"
                                min="1"
                                type="number"
                                name="cleaning_gross_weight_measurement"
                                value={!this.isEmpty(this.props.meta) ? this.props.meta.cleaning_gross_weight_measurement.value : 0}
                                onChange={this.setGrossWeightMeasurementValue}
                                required
                            />
                            <label className="field-label" htmlFor="cleaning_gross_weight_measurement">Value</label>
                            <span className="input-border"></span>
                        </p>
                    </div>
                </div>
                <div className="input-block-titled one select">
                    <h2 className="input-block-title">Cleaning Ingredients</h2>

                    <div className="input-grid">
                        <p className="rounded-data-holder tags color orange">
                            <span className="data-title">Ingredients</span>
                            <ProductTags
                                tags={cleaning_ingredients}
                                editTags={this.editIngredients}
                                title="Add new ingredient"
                            />
                        </p>
                    </div>
                </div>
                <div className="input-block-titled two">
                    <h2 className="input-block-title">Cleaning Packaging</h2>

                    <div className="input-grid">
                        <DropDown
                            disabled={false}
                            title="Packaging"
                            mapFunction={mapPackaging}
                            list={cleaning_packaging_list}
                            handleSelectChange={this.setPackaging}
                            value={mapPackaging(!this.isEmpty(this.props.meta) ? this.props.meta.cleaning_packaging : "select packaging")}
                            className=""
                        />
                    </div>
                </div>

            </div>
        );
    }
}

export default ProductCleaningMeta;