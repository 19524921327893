const initialState = {
	newReceivings: [],
	filter: {
		vendor: '',
		vendor_name: '',
		supervised_by_id: '',
		supervised_by: '',
		approved_by_id: '',
		approved_by: '',
		global: 'active',
		active: false
	},
	alert: {
        type: '',
        text: ''
    },
};

const warehouse = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_FILTER_DATA':
			return Object.assign({}, state, {
				...state,
				filter: action.payload
			});
		case 'SET_NEW_RECEIVINGS':
			return Object.assign({}, state, {
				...state,
				newReceivings: [...state.newReceivings, action.payload]
			});
		case 'SET_ALERT_TYPE':
			return Object.assign({}, state, {
				...state,
				alert: {
					type: action.payload.type,
					text: action.payload.text
				}
			});
		case 'SET_PRODUCTS_IN_RECEIVER':
			return Object.assign({}, state, {
				...state,
				newReceivings: state.newReceivings.map(item => {
					if (item.vendor_id === action.payload.vendor_id) {
						return action.payload
					}
					return item;
				})
			});
		case 'SET_DEFAULT_RECEIVERS':
			return Object.assign({}, state, {
				...state,
				newReceivings: []
			});
		default:
			return state;
	}
}

export default warehouse;