import React, { Component } from 'react';
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, Input } from 'antd';
import oblax from "../../../assets/oblax";
import { setPages } from "../actions";
import { setPagesCount } from "../../Home/actions";
import "../css/Pages.css";
import placeholder from "../../../assets/images/person-placeholder.png";

class Pages extends Component {
    constructor() {
        super()
        this.state = {
            filter: '',
            loader: true,
            pages: []
        }

        this.filterChange = this.filterChange.bind(this);
        this.getPages = this.getPages.bind(this);
        this.addNewPage = this.addNewPage.bind(this);
    }

    componentWillMount() {
        this.getPages();
    }

    getPages() {
        return oblax.pages.getAll()
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        pages: result.data,
                        loader: false
                    }, () => {
                        this.props.setPages(result.data);
                        this.props.setPagesCount(this.state.pages.length);
                    })
                } else {
                    console.log("PAGES ERROR", result.status);
                }
            })
            .catch(err => {
                console.log("PAGES ERROR: ", err);
            });
    }

    filterChange({ target }) {
        this.setState({
            filter: target.value
        })
    }

    getPageDetails(data) {
        //GET PAGES DETAILS
        this.props.history.push(`/pages/${data.id}`);
    }

    addNewPage() {
        this.props.history.push("/pages/new");
    }

    render() {
        return (
            //     <div className="products">
            //     <div className="products-search">
            //         <h2>Pages</h2>
            //         <Icon onClick={this.addNewPage} className="add-new-product" type="plus-circle" theme="outlined" />
            //         {/* <Input onChange={this.filterChange} name="global" value={this.state.filter} placeholder="Product Search" /> */}
            //     </div>
            //     <table className="products-table">
            //         <thead>
            //             <tr className="product-table-head">
            //                 <td>Title</td>
            //                 <td>Image</td>
            //                 <td>Tags</td>
            //                 <td>Content</td>
            //             </tr>
            //         </thead>
            //         <tbody className="products-table-body">
            //             {this.state.loader ?
            //                 <tr>
            //                     <td>
            //                         <Icon type="loading" theme="outlined" />
            //                     </td>
            //                 </tr>
            //                 :
            //                 this.state.pages
            //                 // .filter(items => (
            //                 //     items.short_name.toLowerCase().includes(this.state.filter.toLowerCase()) ||
            //                 //     items.name.toLowerCase().includes(this.state.filter.toLowerCase())
            //                 // ))
            //                 .map((item, i) =>
            //                     <tr onClick={() => this.getPageDetails(item)} className="user-table-row" key={item.id}>
            //                         <td className="user-name">{item.title}</td>
            //                         <td className="user-img">{item.image}</td>
            //                         <td className="user-email">{item.tags}</td>
            //                         <td className="page-content">{item.content}</td>
            //                     </tr>
            //                 )
            //             }
            //         </tbody>
            //     </table>	
            // </div>
            <div id="pages-table" className="pages">
                <div className="users-table-container">
                    <div className="table-filter users-title-search">
                        <div className="filter-row main">
                             <div className="filter-item main">
								<button className="circle-button light icon filter-icon" name="active"></button>
							</div>
                            <div className="filter-item">
                                <button
                                    className="rounded-button primary primary-icon gradient orange new-product-button"
                                    onClick={this.addNewPage}
                                >
                                    <span className="button-text">Create Page</span>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="bc-table-wrap">
                        <table className="bc-table">
                            <thead className="bc-table-head">
                                <tr>
                                    <th>Title</th>
                                    <th>Permalink</th>
                                    <th>Content</th>
                                    <th className="pusher">pusher</th>
                                </tr>
                            </thead>

                            <tbody className="bc-table-body">
                                {this.state.pages.map((item, i) =>
                                    <tr onClick={() => this.getPageDetails(item)} className="bc-table-row" key={i}>
                                        <td className="bc-table-data user-name">{item.title}</td>
                                        <td className="products-table-data table-classic-data user-email">{item.permalink}</td>
                                        <td className="bc-table-data table-classic-data user-role content"><p>{item.content}</p></td>
                                        <td className="products-table-data bc-button-table-data">
                                            <button className="square-button light icon settings-icon"></button>
                                            <div id="table-settings-dropdown">
                                                <ul className="dropdown-list">
                                                    <li>
                                                        <a href="#">
                                                            <span className="dropdown-icon edit"></span>
                                                            <h2 className="dropdown-title">Edit</h2>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="dropdown-icon set-inactive"></span>
                                                            <h2 className="dropdown-title">Set Inactive</h2>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="#">
                                                            <span className="dropdown-icon delete"></span>
                                                            <h2 className="dropdown-title">Delete</h2>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        );
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        setPages: (data) => {
            dispatch(setPages(data))
        },
        setPagesCount: (data) => {
            dispatch(setPagesCount(data))
        }
    };
}



// export default Main;
export default withRouter(connect(null, mapDispatchToProps)(Pages));