import React, { Component } from 'react';
import { Route, withRouter } from "react-router-dom";
import PrivateRoute from '../../../assets/private-route';
import { connect } from "react-redux";
import oblax from "../../../assets/oblax";
import { setLoginData } from "../actions";

 

/* VIEWS */
import {Home} from '../../Home';
import {Login} from '../../Login';


class App extends Component {
	constructor(props) {
		super(props);

		this.setLoginVerification = this.setLoginVerification.bind(this);
	}	

	componentDidMount() {
		oblax.auth.onAuthStateChanged(this.setLoginVerification);
	}

	setLoginVerification(data) {
		this.props.setLoginData(data);
	}

	render() {
		if (!this.props.auth.isAuthenticated) {
			return null;
		} 
		return (
			<div className="main-wrap">
				<PrivateRoute path="/" component={Home} auth={this.props.auth}/>
				<Route path="/login" component={Login} />
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		auth : state.app.auth
	};
}

// Connecting functions from the store as props
const mapDispatchToProps = (dispatch) => {
	return {
		setLoginData: (data) => {
			dispatch(setLoginData(data));
		},
	};
};




// export default Main;
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

