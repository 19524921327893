import React, { Component } from 'react';
import { Route, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Icon, Input, Button } from 'antd';
import oblax from "../../../assets/oblax";

import { setForms } from "../actions";
import { setFormsCount } from "../../Home/actions";
import { Loader, Toast, ConfirmAlert } from "../../../parts";
import { UTILITIES } from "../../../assets/constants";

import "../css/Forms.css";

class Forms extends Component {
	constructor() {
		super()
		this.state = {
			filter: '',
			loader: true,
			forms: [],
			formSettings: false,
			deleteAlert: false,
			selectedFormId: "",

			alert: {
				type: '',
				text: '',
				triger: false
			}
		}

		this.filterChange = this.filterChange.bind(this);
		this.getForms = this.getForms.bind(this);
		this.addNewForm = this.addNewForm.bind(this);
		this.getFormDetails = this.getFormDetails.bind(this);
		this.DeleteForm = this.DeleteForm.bind(this);
		this.deleteAlert = this.deleteAlert.bind(this);
		this.cancelDeleteAlert = this.cancelDeleteAlert.bind(this);
		this.activateAlert = this.activateAlert.bind(this);
	}

	componentWillMount() {
		this.getForms();
	}

	getForms() {
		return oblax.forms.getAllMessageForms()
			.then((result) => {
				console.log("FORMS: ", result)
				if (result.ok) {
					this.setState({
						forms: result.data,
						loader: false
					}, () => {
						this.props.setForms(this.state.forms)
						this.props.setFormsCount(this.state.forms.length)
					})
				} else {
					console.log("ERROR on FORMS")
				}
			})
			.catch(err => {
				console.log("FORMS ERROR: ", err)
			});
	}

	filterChange({ target }) {
		this.setState({
			filter: target.value
		})
	}

	getFormDetails(data) {
		//GET FORMS DETAILS
		this.props.history.push(`/forms/${data.id}`);
	}

	selectedSettings = (e, id) => {
		e.stopPropagation();
		e.preventDefault();

		if (this.state.productSettings !== id) {
			this.setState({
				formSettings: id
			})
		} else {
			this.setState({
				formSettings: ''
			})
		}
	}

	closeSelectSettings = () => {
		this.setState({
			formSettings: ''
		})
	}

	DeleteForm() {
		oblax.forms.deleteMessageForm(this.state.selectedFormId)
			.then((result) => {
				if (result.ok) {
					this.setState({
						deleteAlert: false,
						selectedFormId: ''
					}, () => {
						this.getForms();
						this.activateAlert("success", "You have successfully deleted a Form.");
					});
				}
			})
			.catch(err => {
				console.log("DELETE ERROR: ", err);
			});
	}

	addNewForm() {
		this.props.history.push("/forms/new");
	}

	getFormDetails(data) {
		this.props.history.push(`/forms/${data.id}`);
	}

	deleteAlert(e, id) {
		e.stopPropagation();
		e.preventDefault();
		this.setState({
			deleteAlert: true,
			selectedFormId: id
		});
	}

	cancelDeleteAlert() {
		this.setState({
			deleteAlert: false,
			selectedFormId: ''
		})
	}

	// Activation of alert box on user action
	activateAlert(type, text) {
		this.setState({
			alert: {
				type: type,
				text: text,
				triger: true
			}
		}, () => {
			setTimeout(() => {
				this.setState({
					alert: {
						type: "",
						text: "",
						triger: false
					}
				})
			}, UTILITIES.alertTime);
		});
	}

	render() {
		return (
			<div id="forms-table" className="forms">
				{this.state.alert.triger &&
					<Toast type={this.state.alert.type} description={this.state.alert.text} />
				}

				{this.state.deleteAlert &&
					<ConfirmAlert
						title="Delete Form"
						message="You are attempting to delete a Form from the database. Are you sure you want to proceed?"
						handleMainFunction={this.DeleteForm}
						handleSeconderyFunction={this.cancelDeleteAlert}
					/>
				}
				<div className="forms-table-container">
					<div className="table-filter forms-title-search">
						<div className="filter-row main">
							<div className="filter-item main">
								<button className="circle-button light icon filter-icon" name="active"></button>
							</div>
							<div className="filter-item">
								<button
									className="rounded-button primary primary-icon gradient orange new-product-button"
									onClick={this.addNewForm}
								>
									<span className="button-text">Create Form</span>
								</button>
							</div>
						</div>
					</div>

					<div className="bc-table-wrap">
						<table className="bc-table">
							<thead className="bc-table-head">
								<tr>
									<th>#</th>
									<th>Name</th>
									<th>Permalink</th>
									<th>Notification Email</th>
									<th>Email Response</th>
									<th className="pusher"></th>
								</tr>
							</thead>

							<tbody className="bc-table-body">
								{this.state.loader ?
									<tr>
										<td>
											<Loader />
										</td>
									</tr>
									:
									this.state.forms
										.map((item, i) =>
											<tr className="bc-table-row" onClick={() => this.getFormDetails(item)} key={item.id}>
												<td className="bc-table-data table-classic-data user-email">{i}</td>
												<td className="bc-table-data table-classic-data user-name">{item.name}</td>
												<td className="bc-table-data table-classic-data user-img">{item.permalink}</td>
												<td className="bc-table-data table-classic-data user-email">{item.notification_email ? "ON" : "OFF"}</td>
												<td className="bc-table-data table-classic-data user-geneder">{item.email_response ? "ON" : "OFF"}</td>
												<td className="bc-table-data bc-button-table-data" onBlur={this.closeSelectSettings}>
													<button className="square-button light icon settings-icon" onClick={(event) => this.selectedSettings(event, item.id)}></button>
													<div tabIndex="0" id="table-settings-dropdown" className={this.state.formSettings === item.id ? "opened" : ""} >
														<ul className="dropdown-list">
															<li>
																<a onClick={() => this.getFormDetails(item)}>
																	<span className="dropdown-icon edit"></span>
																	<h2 className="dropdown-title">Edit</h2>
																</a>
															</li>
															<li>
																<a onClick={(event) => this.deleteAlert(event, item.id)}>
																	<span className="dropdown-icon delete"></span>
																	<h2 className="dropdown-title">Delete</h2>
																</a>
															</li>
														</ul>
													</div>
												</td>
											</tr>
										)
								}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		);
	}
}


const mapDispatchToProps = (dispatch) => {
	return {
		setForms: (data) => {
			dispatch(setForms(data))
		},
		setFormsCount: (data) => {
			dispatch(setFormsCount(data))
		}
	};
}



// export default Main;
export default withRouter(connect(null, mapDispatchToProps)(Forms));