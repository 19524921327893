import React from "react";
import { Link } from "react-router-dom";
import SelectDropdown from "./SelectDropdown";

import "../css/BoosterChart.css";

const BoosterChart = (props) => {
	return (
		<div className={"single-chart " + (props.type)}>
			<div className="chart-header">
				<h2 className="chart-title">{props.title}</h2>
				<Link className="chart-link" to="/dashboard">View details</Link>
			</div>
			<div className="chart-values">
				<div className="chart-values-divide">
					<h2 className="chart-value">{Math.trunc(props.totalValue)} <span>{props.description}</span></h2>
					<SelectDropdown
						disabled={false}
						options={[{ id: "day", name: "Today" }, { id: "month", name: "This Month" }, { id: "year", name: "This Year" }]}
						labelField="name"
						valueField="id"
						handleSelectChange={props.handleSelectChange}
						model={props.selectModel}
					/>
				</div>
				<div className={`chart-percentage ${props.growthRate < 0 ? 'down' : 'up'} ${props.growthRate === 0 ? 'neutral' : ''}`} >
					<span className="percentage"></span>
					<h2 className="value-src">{Math.abs(props.growthRate)}&#37;</h2>
				</div>
			</div>
			<div className="chart-graphic">{props.children}</div>
		</div>
	)
}

export default BoosterChart;